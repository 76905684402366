import React, { useContext, useEffect, useState } from "react";
import { ReloadDataContext } from "../../../../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../../../../components/Deprecated/DialogWrapper";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FormButtons } from "../../../../../../../components/Buttons/FormButtons";
import { RadioButton } from "@progress/kendo-react-inputs";
import {
    FormDateInput,
    FormDropDown,
    FormNumericInput,
} from "../../../../../../../components/Deprecated/FormComponents";
import {
    requiredDateValidator,
    requiredValidator,
} from "../../../../../../../resources/Deprecated/formValidators";
import PayrollService from "../../../../../../../services/Deprecated/humanResources/PayrollService";
import dayjs from "dayjs";
import { daysOfTheWeek } from "../../../../../../../resources/Deprecated/dateHelper";
import { PayrollTypeEnum } from "../../../../../../../resources/Enums/PayrollTypeEnum.js";
import { PayChexInformation } from "./Components/PayChexInformation.jsx";

const PayrollIntervalModal = (props) => {
    const { record, close, visible } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [radioValue, setRadioValue] = useState(
        record?.interval ?? "BIWEEKLY"
    );
    const [selectedProvider, setSelectedProvider] = useState(undefined);

    useEffect(() => {
        if (!record) return;

        const key = record?.type ?? PayrollTypeEnum.Enum.ADP;
        const value = record?.type ?? PayrollTypeEnum.Enum.ADP;
        setSelectedProvider({
            key,
            value,
        });
    }, [record]);

    const onSubmit = (props) => {
        if (!props.isValid) return;

        setLoaderVisible(true);

        PayrollService.setPayrollInterval({
            interval: radioValue,
            startDayOfWeek: props.values.startDayOfWeek.id,
            startingDate: dayjs(props.values.startingDate).format("YYYY-MM-DD"),
            minimumBreakDuration: props.values.minimumBreakDuration,
            type: selectedProvider.value,
            companyId: props.values.companyId,
        }).then(() => {
            triggerReload();
            close();
            setLoaderVisible(false);
        });
    };

    const handleRadioButtons = (props) => setRadioValue(props.value);

    /**
     * @function fetchAdditionalPayrollInformation
     * @param type {PayrollTypeEnum.Enum}
     * @return {Element}
     */
    const fetchAdditionalPayrollInformation = (type) => {
        switch (type) {
            case PayrollTypeEnum.Enum.PayChex:
                return <PayChexInformation />;
        }
    };

    return (
        visible &&
        selectedProvider && (
            <ResponsiveDialog
                title={"Set Payroll Interval"}
                onClose={() => close()}
                size={"small"}
            >
                <Form
                    initialValues={{
                        ...record,
                        type: {
                            key: PayrollTypeEnum.ParseToString(
                                selectedProvider.value
                            ),
                            value: record.type,
                        },
                    }}
                    onSubmitClick={onSubmit}
                    render={() => (
                        <FormElement>
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignContent: "center",
                                    flexDirection: "column",
                                    gap: 5,
                                    width: "100%",
                                }}
                            >
                                <p
                                    className={"ZeroMargin AlignTextLeft"}
                                    style={{ fontWeight: "500" }}
                                >
                                    Which provider would you like to use?
                                </p>
                                <Field
                                    name={"type"}
                                    component={FormDropDown}
                                    onChange={(e) =>
                                        setSelectedProvider(e.value)
                                    }
                                    data={PayrollTypeEnum.AsKeyValue().filter(
                                        (item) =>
                                            item.value !==
                                            selectedProvider.value
                                    )}
                                    textField={"key"}
                                    dataItemKey={"value"}
                                />
                                {/*  Display additional information */}
                                {fetchAdditionalPayrollInformation(
                                    selectedProvider.value
                                )}
                                <p
                                    className={"ZeroMargin AlignTextLeft"}
                                    style={{ fontWeight: "500" }}
                                >
                                    When do you pay?
                                </p>
                                <span
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignContent: "flex-start",
                                        gap: 10,
                                    }}
                                >
                                    <RadioButton
                                        name="WEEKLY"
                                        value="WEEKLY"
                                        checked={radioValue === "WEEKLY"}
                                        label="Weekly"
                                        onChange={handleRadioButtons}
                                    />
                                    <RadioButton
                                        name="BIWEEKLY"
                                        value="BIWEEKLY"
                                        checked={radioValue === "BIWEEKLY"}
                                        label="Bi-Weekly"
                                        onChange={handleRadioButtons}
                                    />
                                </span>
                                <br />
                                <p
                                    className={"ZeroMargin AlignTextLeft"}
                                    style={{ fontWeight: "500" }}
                                >
                                    What day of the week does your pay period
                                    start on?
                                </p>
                                <Field
                                    name={"startDayOfWeek"}
                                    component={FormDropDown}
                                    data={daysOfTheWeek}
                                    dataItemKey="id"
                                    textField="name"
                                    validator={requiredValidator}
                                />
                                <br />
                                <p
                                    className={"ZeroMargin AlignTextLeft"}
                                    style={{ fontWeight: "500" }}
                                >
                                    When do you want your pay period to start
                                    generating?
                                </p>
                                <Field
                                    name="startingDate"
                                    component={FormDateInput}
                                    validator={requiredDateValidator}
                                    hint="MM/DD/YYYY"
                                />
                                <p
                                    className={"ZeroMargin AlignTextLeft"}
                                    style={{ fontWeight: "500" }}
                                >
                                    What is the minimum minutes for a break?
                                </p>
                                <Field
                                    name={"minimumBreakDuration"}
                                    component={FormNumericInput}
                                    min={0}
                                    defaultValue={20}
                                    validator={requiredValidator}
                                />
                            </span>
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={() => close()}
                                allowSubmit={true}
                                text={"Submit"}
                                theme={"primary"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default PayrollIntervalModal;
