import * as React from "react";
import { useContext, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { Stepper } from "@progress/kendo-react-layout";
import { BasicInfoForm } from "./BasicInformationForm";
import RelationshipForm from "./EmployeeRelationshipForm";

import { Loader } from "@progress/kendo-react-indicators";
import ContactInformationForm from "./EmployeeContactForm";
import PreCreateEmployeeSearchModal from "./PreCreateEmployeeSearchModal";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { useModal } from "../../../../hooks/useModal";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ContactHelper from "../../../../resources/ContactHelper";
import { ContactType } from "../../../../resources/Enums/ContactType";
import { addEmployee } from "../../../../services/Deprecated/humanResources/employeeServices";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { ActionButtonContainer } from "../../../../components/Buttons/ActionButtonContainer.jsx";
import { EventModalHelper } from "../../../Customer/CalendarEvents/Components/EventModal/Helpers/EventModalHelper.js";
import dayjs from "dayjs";

// Define a component called "ToolCreateForm" that accepts "cancelAdd" and "onAdd" as props
const EmployeeCreateForm = () => {
    const { bool: createVisible, toggle: toggleCreateVisible } = useToggle();
    const { bool: loaderVisible, toggle: toggleLoaderVisible } = useToggle();
    const preCreateModal = useModal();
    // Set up state variables to hold the Employee data
    const [formState, setFormState] = React.useState({});
    const InitialStepState = {
        isLastStep: false,
        currentStep: 0,
        steps: [
            {
                label: "Basic Info",
                isValid: true,
            },
            {
                label: "Details",
                isValid: true,
            },
            {
                label: "Contact Info",
                isValid: true,
            },
        ],
        onPreviousStepSubmit: () => onPrev(),
    };
    const [stepState, setStepState] = useState(InitialStepState);
    const { triggerReload } = useContext(ReloadDataContext);

    /**
     Callback function triggered when a step is submitted. This function sets the steps and
     determines if a step is valid or not. It also will submit the vendor if the last step is valid.

     @param {Object} event - The event object containing information about the step submission.
     */

    const onStepSubmit = React.useCallback(
        (e) => {
            const { isValid, values, event } = e;

            if (!isValid) {
                return;
            }

            const buttonClickedStr = event?.nativeEvent?.submitter?.innerText;
            if (!isValid || buttonClickedStr === "Next") {
                setStepState(
                    EventModalHelper.formatStepState(stepState, isValid)
                );
                return;
            }

            if (!isValid) {
                return;
            }
            setFormState(values);
            if (stepState.isLastStep) {
                submitEmployee(values);
            }
        },
        [stepState]
    );

    const onPrev = () => {
        setStepState({
            ...stepState,
            currentStep: Math.max(stepState.currentStep - 1, 0),
        });
    };

    // Define a function to handle form submission
    const submitEmployee = async (dataItem) => {
        toggleLoaderVisible();

        const data = {
            id: dataItem.id,
            firstName: dataItem.firstName,
            middleName: dataItem.middleName,
            lastName: dataItem.lastName,
            email: dataItem.email,
            hourlyRate: {
                amount: dataItem?.hourlyRate?.amount ?? 0,
                currency: "USD",
            },
            dateOfBirth: dayjs(dataItem.dateOfBirth).format("YYYY-MM-DD"),
            allowedToDrive: dataItem.allowedToDrive,
            isThirdShift: dataItem.isThirdShift,
            payrollId: dataItem.payrollId,
            identificationNumber: dataItem.identificationNumber,
            identificationExpiration: dataItem.identificationExpiration,
            identificationTypeId: dataItem.identificationType.id,
            phoneOne: dataItem.phoneOne,
            phoneTwo: dataItem.phoneTwo,
            phoneThree: dataItem.phoneThree,
            socialSecurityNumber: dataItem.socialSecurityNumber,
            address: dataItem.address,
            defaultRoleId: dataItem.defaultRole.id,
            status: {
                ...dataItem.status,
                hiredDate: dayjs(dataItem.status.hiredDate).format(
                    "YYYY-MM-DD"
                ),
            },
            maritalStatusId: dataItem.maritalStatus.id,
            laborTypeId: dataItem.laborType.id,
            supervisorId: dataItem.supervisor?.id,
            locations: dataItem.locations.map((l) => ({
                locationId: l.id,
                isPrimaryLocation: l.id === dataItem.primaryLocation.id,
            })),
            contacts: dataItem.emergencyContact?.name1
                ? [
                      ContactHelper.build(
                          dataItem.emergencyContact,
                          ContactType.Enum.Emergency
                      ),
                  ]
                : [],
        };

        addEmployee(data)
            .then((res) => {
                if (res.success) {
                    triggerReload();
                    toggleCreateVisible();
                    setFormState({});
                    setStepState(InitialStepState);
                }
            })
            .finally(() => {
                toggleLoaderVisible();
            });
    };

    return (
        <div>
            {/* This is the confirmation toggle, this will be visible once the create button is toggled */}
            <ActionButton
                requiredPermissions={[PermissionsEnum.CreateEmployee]}
                themeColor={"primary"}
                icon={"k-icon k-i-plus"}
                onClick={() => preCreateModal.open()}
                style={{
                    width: "180px",
                    margin: "5px",
                }}
            >
                Create New Employee
            </ActionButton>
            <PreCreateEmployeeSearchModal
                {...preCreateModal}
                setFormState={setFormState}
                toggleCreateVisible={toggleCreateVisible}
            />
            {createVisible && (
                <span>
                    <Form
                        onSubmitClick={onStepSubmit}
                        initialValues={formState}
                        render={(formRenderProps) => (
                            <ResponsiveDialog
                                title={"Create Employee"}
                                onClose={toggleCreateVisible}
                                size={"large"}
                            >
                                <Stepper
                                    value={stepState.currentStep}
                                    items={stepState.steps}
                                />
                                <FormElement>
                                    {stepState.currentStep === 0 && (
                                        <BasicInfoForm
                                            formRenderProps={formRenderProps}
                                        />
                                    )}
                                    {stepState.currentStep === 1 && (
                                        <RelationshipForm
                                            formRenderProps={formRenderProps}
                                        />
                                    )}
                                    {stepState.currentStep === 2 && (
                                        <span>
                                            <ContactInformationForm
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                        </span>
                                    )}
                                    <div
                                        style={{
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                            borderTop: "1px solid #ccc",
                                        }}
                                        className={
                                            "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                                        }
                                    >
                                        <span
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            Step {stepState.currentStep + 1} of
                                            3
                                        </span>
                                        {/* If the loader is not visible, display the buttons, otherwise display the loader */}
                                        {!loaderVisible ? (
                                            <ActionButtonContainer>
                                                {stepState.currentStep !== 0 ? (
                                                    <ActionButton
                                                        onClick={onPrev}
                                                        style={{
                                                            width: "100px",
                                                            margin: "10px",
                                                        }}
                                                        icon={"k-icon k-i-undo"}
                                                    >
                                                        Previous
                                                    </ActionButton>
                                                ) : undefined}
                                                <ActionButton
                                                    themeColor={
                                                        stepState.isLastStep
                                                            ? "success"
                                                            : "primary"
                                                    }
                                                    disabled={
                                                        !formRenderProps.allowSubmit
                                                    }
                                                    onClick={
                                                        formRenderProps.onSubmit
                                                    }
                                                    style={{
                                                        width: "100px",
                                                        margin: "10px",
                                                    }}
                                                    icon={`k-icon ${
                                                        stepState.isLastStep
                                                            ? "k-i-plus"
                                                            : "k-i-redo"
                                                    }`}
                                                >
                                                    {stepState.isLastStep
                                                        ? "Submit"
                                                        : "Next"}
                                                </ActionButton>
                                            </ActionButtonContainer>
                                        ) : (
                                            <Loader type="infinite-spinner" />
                                        )}
                                    </div>
                                </FormElement>
                            </ResponsiveDialog>
                        )}
                    />
                </span>
            )}
        </div>
    );
};
export default EmployeeCreateForm;
