import * as React from "react";
import "../../../styles/CommonPositionalStyles.scss";
import { GridColumn } from "@progress/kendo-react-grid";
import { DataGrid } from "../../../components/Grids/DataGrid.jsx";
import rowRenderHighlighter from "../../../components/RowRenders/RowRenderHighlighter.jsx";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell.jsx";
import { DateCell } from "../../../components/GridColumnComponents/DateCell.jsx";

const EventDatesQueue = (props) => {
    const { data } = props;

    return (
        data && (
            <DataGrid
                data={data}
                showColumnsMultiSelect={false}
                initialSort={[{ field: "hasBeenReturned", dir: "asc" }]}
                resizable={true}
                rowRender={(row, props) =>
                    rowRenderHighlighter(row, {
                        isWarning: props.dataItem.hasBeenReturned,
                    })
                }
            >
                <GridColumn
                    field="customer.name"
                    title="Customer:"
                    className={"text-overflow"}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Customer/${props.dataItem.customer.id}`}
                            manualId={props.dataItem.customer.id}
                            manualText={props.dataItem.customer.name}
                        />
                    )}
                />
                <GridColumn
                    field="job.name"
                    title="Job:"
                    className={"text-overflow"}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Customer/${props.dataItem.customer.id}/Job/${props.dataItem.job.id}/`}
                            manualId={props.dataItem.job.id}
                            manualText={props.dataItem.job.name}
                            additionalBreadcrumbInfo={[
                                {
                                    id: props.dataItem.customer.id,
                                    text: props.dataItem.customer.name,
                                },
                            ]}
                        />
                    )}
                />
                <GridColumn
                    field="createdBy.enactingEmployee.name"
                    title="Created By:"
                    className={"text-overflow"}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/HumanResources/Employee/${props.dataItem?.createdBy?.enactingEmployee?.id}`}
                            manualId={
                                props.dataItem?.createdBy?.enactingEmployee?.id
                            }
                            manualText={
                                props.dataItem?.createdBy?.enactingEmployee
                                    ?.name
                            }
                        />
                    )}
                />
                <GridColumn
                    field="createdBy.dateOccured"
                    title="Created:"
                    className={"text-overflow"}
                    width={100}
                    cell={(props) => (
                        <DateCell {...props} format={"MM/DD/YYYY"} />
                    )}
                />
                <GridColumn
                    field="returnedBy.enactingEmployee.name"
                    title="Returned By:"
                    className={"text-overflow"}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Employee/${props.dataItem?.returnedBy?.enactingEmployee?.id}`}
                            manualId={
                                props.dataItem?.returnedBy?.enactingEmployee?.id
                            }
                            manualText={
                                props.dataItem?.returnedBy?.enactingEmployee
                                    ?.name
                            }
                        />
                    )}
                />
                <GridColumn
                    field="returnedBy.dateOccured"
                    title="Returned:"
                    className={"text-overflow"}
                    width={100}
                    cell={(props) => (
                        <DateCell {...props} format={"MM/DD/YYYY"} />
                    )}
                />
            </DataGrid>
        )
    );
};

export default EventDatesQueue;
