import React from "react";
import { FileManager } from "../../../../components/FileManager/FileManager";

const fileRestrictions = {
    allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png", ".mov", ".mp4", ".xlsx", ".xls"],
};

const JobFiles = (props) => {
    const { jobId } = props;

    return (
        <div>
            <div
                style={{
                    textAlign: 'left',
                    marginBottom: 10,
                    fontWeight: 'bold'
                }}
            >
                Allowed file types are: {fileRestrictions.allowedExtensions.join(", ")}
            </div>
            <FileManager
                disabled={false}
                allowedExtensions={fileRestrictions}
                saveUri={"/General/File/UploadFile"}
                removeUri={"/General/File/DeleteFile"}
                readUri={"/General/File/GetDirectoryContents"}
                location={`JobFiles-${jobId}`}
            />
        </div>
    );
};

export default JobFiles;
