export class ItemAllocationHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @param {Boolean} isDeAllocation - Is deallocation
     * @return {Object}
     */
    static formatDataForNetwork(dataItem, isDeAllocation) {
        if (isDeAllocation) {
            return this.formatDeAllocationData(dataItem);
        } else {
            return this.formatAllocationData(dataItem);
        }
    }

    static formatDeAllocationData(dataItem) {
        const item = Object.values(dataItem.items).filter(
            (i) => i !== undefined
        )[0];

        return {
            itemId: dataItem.itemId,
            quantity: item.quantity,
            storageLocationId: item.storageLocation.id,
            jobId: dataItem.jobId,
            identifier: item.identifier,
        };
    }

    static formatAllocationData(dataItem) {
        const allocationItems = Object.values(dataItem.items).filter(
            (i) => i !== undefined
        );

        return {
            allocationItems: allocationItems.map((item) => {
                return {
                    itemId: dataItem.itemId,
                    quantity: item.quantity,
                    storageLocationId: item.storageLocation.id,
                    jobId: dataItem.jobId,
                    identifier: item.identifier,
                };
            }),
        };
    }

    /**
     * @function getItemDefaultSqft
     * @description Get item default Sqft if exists, otherwise returns -1 and logs a warning
     * @param item
     * @returns {number}
     */
    static getItemDefaultSqft(item) {
        if (item.retailUom.name === "Sqft") {
            const length = item.defaultLength ?? 0;
            const width = item.defaultWidth ?? 0;
            if (length === 0 || width === 0) {
                console.warn("Item does not have default length or width");
            }
            const sqft = (length * width) / 144;
            // round sqft to 2 decimal places immediately before other math happens
            return Math.round(sqft * 100) / 100;
        } else {
            console.warn("Item does not have Sqft as UOM");
            return -1;
        }
    }
}
