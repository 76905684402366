import React, { useContext, useEffect } from "react";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { UserContext } from "../../../../providers/Deprecated/Authentication/User/UserProvider";
import styles from "./UserDetails.module.scss";
import { Card, TabStripTab } from "@progress/kendo-react-layout";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import UserDetailsCard from "./Components/UserDetailsCard";
import UserEmployeeDetails from "./Components/UserEmployeeDetails";
import { HighlightedTabStrip } from "../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import ChangeUserRoleModal from "../../../HumanResources/Employee/Components/ChangeUserRoleModal.jsx";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import { useModal } from "../../../../hooks/useModal.js";

const AdminUserDetailsPage = (props) => {
    const userId = props?.match?.params?.id;
    const userContext = useContext(UserContext);
    const employeeContext = useContext(EmployeeContext);
    const changeUserRoleModal = useModal();

    /** @type {User} */
    const user = userContext.user;

    useEffect(() => {
        userContext.setUserId(userId);
    }, [userId]);

    useEffect(() => {
        if (user?.activeEmployeeId) {
            employeeContext.setEmployeeId(user.activeEmployeeId);
        }
    }, [user]);

    // JSX code for rendering the page
    //
    return (
        <div className={styles.UserDetailsContainer}>
            {!userContext.relationshipsLoading && user ? (
                <Card className="card-container" style={{ border: "none" }}>
                    <HighlightedTabStrip>
                        <TabStripTab title="Details">
                            <span
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10,
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    width: "100%",
                                }}
                            >
                                <ChangeUserRoleModal {...changeUserRoleModal} />
                                <ActionButton
                                    requiredPermissions={[
                                        PermissionsEnum.TimeClockAdmin,
                                    ]}
                                    onClick={() => changeUserRoleModal.open()}
                                    icon={"k-icon k-i-gear"}
                                    disabled={!user?.role}
                                    toolTipMessage={
                                        "User has not yet logged on to be assigned default Role."
                                    }
                                >
                                    Change User Role
                                </ActionButton>
                                <span
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <UserDetailsCard />
                                </span>
                            </span>
                        </TabStripTab>
                        {user?.activeEmployeeId && (
                            <TabStripTab title="Employee Details">
                                <UserEmployeeDetails />
                            </TabStripTab>
                        )}
                    </HighlightedTabStrip>
                </Card>
            ) : (
                <CenterLoader />
            )}
        </div>
    );
};

export default AdminUserDetailsPage;
