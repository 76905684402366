import React from "react";
import { DataGrid } from "../../components/Grids/DataGrid.jsx";
import { GridColumn } from "@progress/kendo-react-grid";
import { EnumCell } from "../../components/GridColumnComponents/EnumCell.jsx";
import { NewJobDocumentType } from "../../resources/Enums/JobDocumentTypeEnum.js";
import { DateCell } from "../../components/GridColumnComponents/DateCell.jsx";
import { LinkCell } from "../../components/GridColumnComponents/LinkCell.jsx";
import { BaseCell } from "../../components/GridColumnComponents/BaseCell.jsx";
import { VendorDocumentType } from "../../resources/Enums/VendorDocumentType.js";
import { ContentHeader } from "../../components/ContentHeader.jsx";

export const FastFindPage = () => {
    return (
        <span
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "flex-start",
                gap: "10px",
            }}
        >
            <span style={{ width: "100%", textAlign: "left" }}>
                <ContentHeader
                    title={"Fast Find"}
                    icon={"k-i-search k-icon-md"}
                />
            </span>
            <small>Hint: Search by Document Number</small>
            <DataGrid
                uri={"/General/FastFind/GetAll?"}
                searchFields={["documentNumber"]}
                initialSort={[
                    { field: "type", dir: "asc" },
                    { field: "documentNumber", dir: "desc" },
                ]}
            >
                <GridColumn
                    field="type"
                    title="Type"
                    width={125}
                    cell={(props) => (
                        <EnumCell
                            {...props}
                            enumerable={
                                props.dataItem.vendor
                                    ? VendorDocumentType
                                    : NewJobDocumentType
                            }
                        />
                    )}
                />
                <GridColumn
                    field="documentNumber"
                    title="Doc #"
                    width={100}
                    cell={(props) => (
                        <BaseCell {...props}>
                            <strong>#{props.dataItem.documentNumber}</strong>
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field={"documentDate"}
                    title={"Date"}
                    width={100}
                    cell={(props) => (
                        <DateCell {...props} format={"MM/DD/YYYY"} />
                    )}
                />
                <GridColumn
                    field={"salesPerson.name"}
                    title={"Sales Person"}
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/HumanResources/Employee/${props?.dataItem?.salesPerson?.id}`}
                            manualId={props?.dataItem?.salesPerson?.id}
                            manualText={props?.dataItem?.salesPerson?.name}
                        />
                    )}
                />
                <GridColumn
                    field={"customer.name"}
                    title={"Customer"}
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Customer/${props?.dataItem?.customer?.id}`}
                            manualId={props?.dataItem?.customer?.id}
                            manualText={props?.dataItem?.customer?.name}
                        />
                    )}
                />
                <GridColumn
                    field={"job.name"}
                    title={"Job"}
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Customer/${props?.dataItem?.customer?.id}/Job/${props?.dataItem?.job?.id}`}
                            manualId={props?.dataItem?.job?.id}
                            manualText={props?.dataItem?.job?.name}
                            additionalBreadcrumbInfo={[
                                {
                                    id: props?.dataItem?.customer?.id,
                                    text: props?.dataItem?.customer?.name,
                                },
                            ]}
                        />
                    )}
                />
                <GridColumn
                    field={"vendor.name"}
                    title={"Vendor"}
                    width={300}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Inventory/Vendor/${props?.dataItem?.vendor?.id}`}
                            manualId={props?.dataItem?.vendor?.id}
                            manualText={props.dataItem?.vendor?.name}
                        />
                    )}
                />
                <GridColumn
                    field="total.amount"
                    title="Total"
                    width={150}
                    format={"{0:c}"}
                />
            </DataGrid>
        </span>
    );
};
