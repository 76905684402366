import React, { useContext } from "react";
import dayjs from "dayjs";
import { reduceString } from "../../../../resources/Deprecated/stringFormatter";
import { formatUSDCurrency } from "../../../../resources/Deprecated/currencyHelper";
import { JobDocumentTypeEnum } from "../../../../resources/Enums/JobDocumentTypeEnum";
import { useModal } from "../../../../hooks/useModal.js";
import { VendorDocumentModalWrapper } from "../../../Inventory/VendorDocuments/Components/VendorDocumentModal/VendorDocumentModal.jsx";
import { JobDocumentModalWrapper } from "./JobDocumentModal/JobDocumentModal.jsx";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider.jsx";

const JobDocumentSummaries = ({ jobDocuments }) => {
    const { job } = useContext(JobContext);
    const vendorDocumentModal = useModal();
    const jobDocumentModal = useModal();

    const onDocumentClick = (document) => {
        if (!document.vendor) {
            jobDocumentModal.open(document.id, {
                businessInformation: job.businessInformation,
            });
        } else {
            vendorDocumentModal.open(document.id);
        }
    };

    return (
        <div
            style={{
                overflowY: jobDocuments.length > 6 ? "scroll" : undefined,
                height: "300px",
                width: "100%",
            }}
        >
            <VendorDocumentModalWrapper {...vendorDocumentModal} />
            <JobDocumentModalWrapper {...jobDocumentModal} />
            {jobDocuments.map((jobDocument, i) => (
                <div
                    key={i}
                    className={"DisplayFlex JustifySpaceBetween"}
                    style={{
                        borderBottom: "1px solid #ccc",
                        padding: "5px",
                    }}
                >
                    <DocumentNumberAndDate
                        jobDocument={jobDocument}
                        onDocumentClick={onDocumentClick}
                    />
                    {/** Render VendorDocument specific code **/}
                    {jobDocument.vendor?.id !== undefined ? (
                        <VendorDocumentInformation
                            vendorDocument={jobDocument}
                        />
                    ) : (
                        <JobDocumentInformation jobDocument={jobDocument} />
                    )}
                </div>
            ))}
        </div>
    );
};

export default JobDocumentSummaries;

const DocumentNumberAndDate = (props) => {
    const { jobDocument, onDocumentClick } = props;
    const formattedDocumentDate = dayjs(
        jobDocument?.documentDate ?? jobDocument.created
    ).format("MM/DD/YYYY");

    return (
        <div className={"AlignTextLeft"}>
            <strong
                className={"link"}
                style={{ color: "#1274AC", cursor: "pointer" }}
                onClick={() => onDocumentClick(jobDocument)}
            >
                #{jobDocument.documentNumber}
            </strong>
            <br />
            <span>{formattedDocumentDate}</span>
        </div>
    );
};

const VendorDocumentInformation = (props) => {
    const { vendorDocument } = props;
    const isFullyReceived = vendorDocument.isFullyReceived;
    const hasExpectedDeliveryDate = !!vendorDocument.expectedDeliveryDate;
    const isExpectedDeliveryDateInFuture =
        new Date() < new Date(vendorDocument.expectedDeliveryDate);
    const formattedFullyReceivedDate = dayjs(
        vendorDocument.fullyReceivedDate
    ).format("MM/DD/YYYY");
    const formattedDeliveryDate = dayjs(
        vendorDocument.expectedDeliveryDate
    ).format("MM/DD/YYYY");

    return (
        <div
            className={"AlignTextRight"}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
            }}
        >
            <strong>{reduceString(vendorDocument.vendor.name, 40)}</strong>
            <span>
                <strong>Total: </strong>
                <span>{formatUSDCurrency(vendorDocument.total.amount)}</span>
            </span>
            {isFullyReceived && (
                <span
                    style={{
                        color: "green",
                    }}
                >
                    Received: {formattedFullyReceivedDate}
                </span>
            )}
            {!isFullyReceived && hasExpectedDeliveryDate && (
                <span>
                    {isExpectedDeliveryDateInFuture ? (
                        <span>Delivery: {formattedDeliveryDate}</span>
                    ) : (
                        <span>Delivered: {formattedDeliveryDate}</span>
                    )}
                </span>
            )}
            {!vendorDocument.isConfirmed && (
                <strong
                    style={{
                        color: "red",
                    }}
                >
                    Unconfirmed
                </strong>
            )}
        </div>
    );
};

const JobDocumentInformation = (props) => {
    const { jobDocument } = props;
    const isInvoiceOrSalesOrder =
        jobDocument.type === JobDocumentTypeEnum.Invoice ||
        jobDocument.type === JobDocumentTypeEnum.SalesOrder;
    const hasPayments = jobDocument.totalPaid.amount !== 0;
    const hasSalesOrderPayments = jobDocument.paidFromSalesOrder.amount !== 0;
    const hasDue = jobDocument.totalDue !== 0;
    const hasChildren = jobDocument.hasChildren;

    return (
        <div className={"AlignTextRight"}>
            <strong>{reduceString(jobDocument.name, 40)}</strong>
            <br />
            <strong>Total: </strong>
            <span>{formatUSDCurrency(jobDocument.total.amount)}</span>
            <br />
            {isInvoiceOrSalesOrder && (
                <>
                    {hasPayments && (
                        <span
                            style={{
                                color: "green",
                            }}
                        >
                            <strong>Paid: </strong>
                            <span>
                                {formatUSDCurrency(
                                    jobDocument.totalPaid.amount
                                )}
                            </span>
                            <br />
                        </span>
                    )}
                    {hasSalesOrderPayments && (
                        <span
                            style={{
                                color: "green",
                            }}
                        >
                            <strong>Credited From SO: </strong>
                            <span>
                                {formatUSDCurrency(
                                    jobDocument.paidFromSalesOrder.amount
                                )}
                            </span>
                            <br />
                        </span>
                    )}
                    {hasDue && !hasChildren && (
                        <span
                            style={{
                                color: "red",
                            }}
                        >
                            <strong>Due: </strong>
                            <span>
                                {formatUSDCurrency(jobDocument.totalDue)}
                            </span>
                        </span>
                    )}
                </>
            )}
        </div>
    );
};
