import * as React from "react";
import { useContext } from "react";
import { VendorContext } from "../../../../providers/Deprecated/Inventory/Vendor/VendorProvider";
import ItemRelationshipsProvider from "../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { useModal } from "../../../../hooks/useModal";
import { Form, FormElement } from "@progress/kendo-react-form";
import ItemCategoryModal from "../../Item/Components/ItemCategoryModal";
import ItemCreateForm from "../../Item/Components/ItemCreateForm";

const VendorItemModal = (props) => {
    const { close, visible } = props;
    const { vendor } = useContext(VendorContext);
    const createItemModal = useModal();

    const onClose = () => {
        close();
        createItemModal.open(vendor.id, vendor);
    };

    return (
        <Form
            render={(formRenderProps) => (
                <FormElement>
                    <ItemRelationshipsProvider>
                        <ItemCategoryModal
                            title={"Select Category for new Vendor Item"}
                            formRenderProps={formRenderProps}
                            close={onClose}
                            visible={visible}
                        />
                        {formRenderProps.valueGetter("category") && (
                            <ItemCreateForm
                                selectedItem={formRenderProps.valueGetter(
                                    "category"
                                )}
                                {...createItemModal}
                                close={() => {
                                    createItemModal.close();
                                    formRenderProps.onChange("category", {
                                        value: undefined,
                                    });
                                }}
                            />
                        )}
                    </ItemRelationshipsProvider>
                </FormElement>
            )}
        />
    );
};

export default VendorItemModal;
