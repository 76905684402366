import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import BaseService from "../../../../services/BaseService";
import { ReminderModalHelper } from "../Helpers/ReminderModalHelper";
import { ContentHeader } from "../../../../components/ContentHeader";
import {
    requiredDateValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { TextAreaInput } from "../../../../components/FormInputs/TextAreaInput";
import { FormDateInput } from "../../../../components/Deprecated/FormComponents";
import dayjs from "dayjs";

/**
 * @function ReminderModal
 * @description Handles the modal for creating and editing reminders
 * @param props.id {String} - The id of the reminder
 * @param props.close {Function} - Function to close the modal
 * @param props.visible {Boolean} - Whether the modal is visible
 * @param props.record {Object} - The Lead
 * @return {Element}
 * @constructor
 */
export const ReminderModal = (props) => {
    const { id, close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const baseService = useRef(new BaseService("Customer/Reminder"));

    useEffect(() => {
        if (!visible) return;

        if (id) {
            baseService.current.get(id).then((res) => {
                const data = ReminderModalHelper.formatDataForModal(res.data);
                setInitialFormData(data);
            });
        } else {
            setInitialFormData({
                date: dayjs().format("MM/DD/YYYY"),
                ...record,
            });
        }
    }, [visible, id]);

    const onClose = () => {
        setInitialFormData(undefined);
        setLoaderVisible(false);
        close();
    };

    const onSubmit = (dataItem) => {
        setLoaderVisible(true);

        const data = ReminderModalHelper.formatDataForNetwork(dataItem);
        const service = id
            ? baseService.current.update(data)
            : baseService.current.create(data);

        service.then(() => {
            triggerReload();
            onClose();
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={id ? "Edit Reminder" : "Create Reminder"}
                onClose={onClose}
                size={"small"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <ContentHeader
                                title={"Message"}
                                style={{
                                    fontSize: 14,
                                    textAlign: "left",
                                    fontWeight: 400,
                                }}
                            />
                            <Field
                                name={"message"}
                                component={TextAreaInput}
                                autoSize={true}
                                rows={4}
                                validator={requiredValidator}
                            />
                            <Field
                                name={"date"}
                                component={FormDateInput}
                                label={"Due Date"}
                                validator={requiredDateValidator}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={close}
                                allowSubmit={formRenderProps.allowSubmit}
                                isCreate={!id}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
