import * as React from "react";
import { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import TransactionService from "../../../../services/Deprecated/accounting/TransactionService";
import { ColumnMenu } from "../../../../components/Deprecated/ColumnMenu";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import RowRenderHighlighter from "../../../../components/RowRenders/RowRenderHighlighter";
import TransactionModal from "./TransactionModal";
import { useModal } from "../../../../hooks/useModal";
import { YesNoCell } from "../../../../components/GridColumnComponents/YesNoCell";
import { BaseCell } from "../../../../components/GridColumnComponents/BaseCell";
import { DateCell } from "../../../../components/GridColumnComponents/DateCell";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { DataGrid } from "../../../../components/Grids/DataGrid";

const mapEnum = (e) => {
    switch (e) {
        case 0:
            return "Payment";
        case 1:
            return "Invoice";
        case 2:
            return "Bill";
        case 3:
            return "Bill Payment";
        case 4:
            return "Payroll";
        case 5:
            return "TimeClockEntry";
        case 6:
            return "Transaction";
    }
};

const Journal = () => {
    const { triggerReload } = useContext(ReloadDataContext);
    const transactionModal = useModal();

    /**
     * Formats the account name to either just the name, or name with code.
     *
     * Ex: "Account"
     * Ex: "Account (Code)"
     * @param account
     * @return {*|string}
     */
    const formatAccountName = (account) => {
        const name = account.name;

        if (account.code) {
            return `${name} (${account.code})`;
        }

        return name;
    };

    /**
     * @function renderActionButtons
     * @description Renders the action buttons for the grid based on dataItem conditions
     * @param props
     * @return {Object[]}
     */
    const renderActionButtons = (props) => {
        const { dataItem } = props;
        const isVoid = dataItem.isVoid;
        const isReconciled = dataItem.isReconciled;

        if (isVoid) return;

        return [
            {
                text: "Mark Void",
                onClick: () =>
                    TransactionService.deleteTransaction(dataItem.id).then(
                        (_) => {
                            triggerReload();
                        }
                    ),
                confirmationState: {
                    needsConfirmation: true,
                    confirmationText:
                        "Are you sure you wish to void this entity?",
                },
                requiredPermissions: [PermissionsEnum.DeleteTransaction],
            },
            {
                text: `Mark ${isReconciled ? "UnReconciled" : "Reconciled"}`,
                onClick: () =>
                    TransactionService.toggleReconcileTransaction(
                        dataItem.id
                    ).then((_) => triggerReload()),
                requiredPermissions: [PermissionsEnum.ReconcileTransaction],
                confirmationState: {
                    needsConfirmation: true,
                    confirmationText: `Are you sure you wish to mark this entity as ${
                        isReconciled ? "UnReconciled" : "Reconciled"
                    }?`,
                },
            },
        ];
    };

    return (
        <div>
            {/* TRANSACTION MODAL */}
            <TransactionModal {...transactionModal} />
            {/* HEADER */}
            <div className={"TransactionHeader"}>
                <p className={"TransactionHeaderTitle"}>Accounting Journal</p>
                <ActionButton
                    text={"Add"}
                    icon={"k-icon k-i-plus"}
                    theme={"info"}
                    className={"TransactionHeaderBtn"}
                    rounded={"large"}
                    onClick={() => transactionModal.open()}
                    requiredPermissions={[PermissionsEnum.CreateTransaction]}
                />
            </div>
            <span className={"AccountingJournalGrid"}>
                <DataGrid
                    uri={`/Accounting/Transaction/GetAll?`}
                    searchFields={["description"]}
                    style={{ width: "99.99%" }}
                    rowRender={(row, props) =>
                        RowRenderHighlighter(row, {
                            isDanger: props.dataItem.isVoid,
                            isSuccess: props.dataItem.isReconciled,
                        })
                    }
                >
                    <GridColumn
                        field="description"
                        title="Description:"
                        width={400}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        field="manualCreatedAt"
                        title="Date Created:"
                        width={125}
                        cell={(props) => (
                            <DateCell {...props} format={"MM/DD/YYYY"} />
                        )}
                    />
                    <GridColumn
                        field="documentType"
                        title="Type:"
                        width={200}
                        className={"text-overflow"}
                        cell={(props) => (
                            <BaseCell {...props}>
                                {mapEnum(props.dataItem.documentType)}
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        field="debitAmount.amount"
                        title="Debit Amount:"
                        format="{0:c}"
                        width={150}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        title="Debit Account:"
                        className={"text-overflow"}
                        width={300}
                        cell={(props) => (
                            <BaseCell {...props}>
                                {formatAccountName(props.dataItem.debitAccount)}
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        field="creditAmount.amount"
                        title="Credit Amount:"
                        format="{0:c}"
                        width={150}
                        className={"text-overflow"}
                    />
                    <GridColumn
                        title="Credit Account:"
                        format="{0:c}"
                        className={"text-overflow"}
                        width={300}
                        cell={(props) => (
                            <BaseCell {...props}>
                                {formatAccountName(
                                    props.dataItem.creditAccount
                                )}
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        title={"Is Reconciled"}
                        field={"isReconciled"}
                        filter={"boolean"}
                        columnMenu={ColumnMenu}
                        width={150}
                        cells={{
                            data: YesNoCell,
                        }}
                    />
                    <GridColumn
                        title={"Is Void"}
                        field={"isVoid"}
                        filter={"boolean"}
                        columnMenu={ColumnMenu}
                        width={150}
                        cells={{
                            data: YesNoCell,
                        }}
                    />
                    <GridColumn
                        filterable={false}
                        sortable={false}
                        width={300}
                        cell={(props) => (
                            <ActionCell
                                onEdit={() =>
                                    transactionModal.open(props.dataItem.id)
                                }
                                permissions={{
                                    edit: [PermissionsEnum.UpdateTransaction],
                                }}
                                additionalActions={renderActionButtons(props)}
                            />
                        )}
                    />
                </DataGrid>
            </span>
        </div>
    );
};

export default Journal;
