import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { EmployeeContext } from "../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import EmployeeCreateForm from "./Components/EmployeeCreateForm";
import { DataGrid } from "../../../components/Grids/DataGrid";
import rowRenderHighlighter from "../../../components/RowRenders/RowRenderHighlighter";
import { LinkCell } from "../../../components/GridColumnComponents/LinkCell";
import { deleteEmployee } from "../../../services/Deprecated/humanResources/employeeServices";
import ActionButtonsCommandCell from "../../../components/Deprecated/ActionButtonsCommandCell";
import EmployeeEditComponent from "./Components/EmployeeEditForm";
import { PermissionsEnum } from "../../../resources/Enums/PermissionsEnum";
import { CenterLoader } from "../../../components/Deprecated/CenterLoader";

const EmployeeDashboardPage = () => {
    const employeeContext = useContext(EmployeeContext);
    const initialSort = [
        {
            field: "FirstName",
            dir: "asc",
        },
    ];

    /**
     * @function handleViewUriTransform
     * @description Transforms the uri based on the timeClockError
     * @param {string} id - The id of the employee
     * @param {boolean} hasTimeClockError - If the employee has a time clock error
     * @return {string}
     */
    const handleViewUriTransform = (id, hasTimeClockError) => {
        if (hasTimeClockError) {
            return `/HumanResources/Employee/${id}?tabPosition=1`;
        }

        return `/HumanResources/Employee/${id}`;
    };

    return (
        <div>
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                }}
            >
                <EmployeeCreateForm />
            </span>
            {!employeeContext.relationshipsLoading ? (
                <DataGrid
                    uri={"/HumanResources/Employee/GetAll?"}
                    initialSort={initialSort}
                    initialFilter={{
                        logic: "and",
                        filters: [
                            {
                                field: "IsActive",
                                operator: "eq",
                                value: true,
                            },
                        ],
                    }}
                    searchFields={[
                        "firstName",
                        "lastName",
                        "email",
                        "phoneOne.phoneNumber",
                        "laborType.name",
                        "payrollId",
                    ]}
                    rowRender={(row, props) =>
                        rowRenderHighlighter(row, {
                            isWarning: props.dataItem.hasTimeClockError,
                        })
                    }
                >
                    <GridColumn
                        field="fullName"
                        title="Name:"
                        filter={"text"}
                        width={300}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={handleViewUriTransform(
                                    props.dataItem.id,
                                    props.dataItem.hasTimeClockError
                                )}
                            />
                        )}
                    />
                    <GridColumn
                        field="address.fullAddress"
                        title="Address:"
                        filter={"text"}
                        width={"200px"}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                    props.dataItem.address.fullAddress
                                )}`}
                                text={props.dataItem.address.fullAddress}
                                target="_blank"
                            />
                        )}
                    />
                    <GridColumn
                        field="email"
                        title="Email:"
                        filter={"text"}
                        width={"150px"}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={`mailto:${props.dataItem.email}`}
                                text={props.dataItem.email}
                            />
                        )}
                    />
                    <GridColumn
                        field="phoneOne.phoneNumber"
                        title="Primary Phone:"
                        filter={"text"}
                        width={"150px"}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={`tel:${props.dataItem?.phoneOne?.phoneNumber}`}
                                text={props.dataItem?.phoneOne?.phoneNumber}
                            />
                        )}
                    />
                    <GridColumn
                        field="laborType.name"
                        title="Labor Type:"
                        filter={"text"}
                        width={"150px"}
                    />
                    <GridColumn
                        field="payrollId"
                        title="Payroll ID:"
                        filter={"text"}
                        width={"150px"}
                    />
                    <GridColumn
                        width={125}
                        cell={(props) => (
                            <ActionButtonsCommandCell
                                record={props.dataItem}
                                dataId={props.dataItem.id}
                                action={deleteEmployee}
                                EditComponent={EmployeeEditComponent}
                                requiredPermissionsEdit={[
                                    PermissionsEnum.UpdateEmployee,
                                ]}
                                requiredPermissionsAction={[
                                    PermissionsEnum.DeleteEmployee,
                                ]}
                            />
                        )}
                    />
                </DataGrid>
            ) : (
                <CenterLoader />
            )}
        </div>
    );
};

export default EmployeeDashboardPage;
