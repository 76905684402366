// Import axios library
import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getAllSupervisors = async () => {
    // We are fetching all employees for supervisors at the moment
    try {
        const response = await axios.get(
            `/HumanResources/Employee/GetAll?filter=(isActive~eq~'true')&`
        );

        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
