import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance";
import { formatServiceResponse } from "../utilities/serviceUtility";

/**
 * @class InventoryService
 * @extends BaseService
 * @description Service class that handles all job related requests
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class InventoryService extends BaseService {
    constructor() {
        super("Inventory/Inventory");
    }

    /**
     * @method delete
     * @description Deletes an inventory item
     * @param data {Object} - The request data containing Id and ExpenseAccountId
     * @return {Promise<ServiceResponse>}
     */
    async delete(data) {
        const response = await axios.delete(
            `${this.uri}/Delete?id=${data.id}&expenseAccountId=${data.expenseAccountId}`
        );
        return formatServiceResponse(response);
    }

    /**
     * @method use
     * @description Uses an inventory item
     * @param data {Object}
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async use(data) {
        const response = await axios.put(`${this.uri}/Use`, data);
        return formatServiceResponse(response);
    }

    /**
     * @method unUse
     * @description Un-uses an inventory item
     * @param data {Object}
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async unUse(data) {
        const response = await axios.put(`${this.uri}/UnUse`, data);
        return formatServiceResponse(response);
    }
}
