import React, { useContext, useEffect, useState } from "react";
import { Field } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import ItemCategoryModal from "./ItemCategoryModal";
import { ItemRelationshipsContext } from "../../../../providers/Deprecated/Inventory/Item/ItemRelationshipsProvider";
import { useModal } from "../../../../hooks/useModal";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
    FormTextArea,
} from "../../../../components/Deprecated/FormComponents";
import {
    nameValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import ItemVendorsGrid from "./ItemVendorsGrid";
import { FileManager } from "../../../../components/FileManager/FileManager";
import { AccountDropdownItemRenderer } from "../../../../components/DropDownRenders/AccountDropDownRender.jsx";
import { useAccounts } from "../../../../hooks/Deprecated/useAccounts.js";

const ItemInformationForm = ({ itemCount, item, formRenderProps }) => {
    const { relationships } = useContext(ItemRelationshipsContext);
    const [itemType, setItemType] = useState("");
    const categoryModal = useModal();
    const { expenseAccounts, incomeAccounts } = useAccounts();

    useEffect(() => {
        if (item) {
            setItemType(item.itemType.name);
        }
    }, [item]);

    const fileRestrictions = {
        allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png"],
    };

    const handleItemTypeChange = (e) => {
        const selectedItemType = e.target.value;
        const selectedItemTypeName = selectedItemType.name;
        setItemType(selectedItemTypeName);
    };

    return (
        <div
            style={{
                display: "flex",
                gap: 20,
                width: "100%",
                flexWrap: "wrap",
            }}
        >
            <span
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    flex: `1 1 ${item?.id ? "45" : "100"}%`,
                    minWidth: 600,
                }}
            >
                <Field
                    name={"name"}
                    component={FormInput}
                    label={"Item Name:"}
                    hint={
                        itemCount === "multi"
                            ? "Hint: Will be used for all items in this form."
                            : ""
                    }
                />
                <Field
                    name={"manufacturerNumber"}
                    component={FormInput}
                    label={"MFR #:"}
                />
                <Field
                    name={"yield"}
                    component={FormNumericInput}
                    format={"p"}
                    label={"Yield:"}
                    defaultValue={1}
                />
                <Field
                    name={"retailPrice"}
                    component={FormNumericInput}
                    format={"c2"}
                    label={"Retail Price:"}
                />
                <span
                    style={{ marginTop: 10 }}
                    className={"JustifyLeftAlignCenter FlexColumn LargeGap"}
                >
                    <Field
                        name={"isActive"}
                        component={Checkbox}
                        label={"Is Active"}
                        rounded={"large"}
                        size={"medium"}
                    />
                    <Field
                        name={"isStockOrSupplyItem"}
                        component={Checkbox}
                        label={"Is Stock or Supply Item"}
                        rounded={"large"}
                        size={"medium"}
                    />
                    <Field
                        name={"isDescriptionEditAllowed"}
                        component={Checkbox}
                        label={"Is Description Edit Allowed"}
                        rounded={"large"}
                        size={"medium"}
                    />
                </span>
                {/*
                 vendor can only be set initially, updating vendor later
                 must be done through vendor items list (or support something
                 other than a simple dropdown for vendor)
                 */}
                {itemCount === "multi" ? (
                    <Field
                        name={"vendor"}
                        component={FormDropDown}
                        validator={requiredValidator}
                        data={relationships.vendors}
                        dataItemKey="id"
                        textField="name"
                        label={"Vendor:"}
                    />
                ) : null}
                <Field
                    name={"itemType"}
                    component={FormDropDown}
                    validator={requiredValidator}
                    data={relationships.itemTypes}
                    dataItemKey="id"
                    textField="name"
                    label={"Vendor Item Type:"}
                    onChange={handleItemTypeChange}
                />
                {["Slab", "Sheet"].includes(itemType) && (
                    <>
                        <Field
                            name={"defaultWidth"}
                            component={FormNumericInput}
                            label={`Default Width (${itemType === 'Slab' ? 'Left – right in inches' : 'Inch'}):`}
                            min={0}
                            format={"0"}
                            validator={requiredValidator}
                        />
                        <Field
                            name={"defaultLength"}
                            component={FormNumericInput}
                            min={0}
                            format={"0"}
                            label={`Default Length (${itemType === 'Slab' ? 'Top – bottom in inches' : 'Inch'}):`}
                            validator={requiredValidator}
                        />
                    </>
                )}
                <Field
                    name={"orderingUom"}
                    component={FormDropDown}
                    validator={requiredValidator}
                    data={relationships.unitOfMeasures}
                    dataItemKey="id"
                    textField="name"
                    label={"Ordering UOM (How the vendor ships):"}
                />
                <Field
                    name={"retailUom"}
                    component={FormDropDown}
                    validator={requiredValidator}
                    data={relationships.unitOfMeasures}
                    dataItemKey="id"
                    textField="name"
                    label={"Retail UOM (How it is sold):"}
                />
                <Field
                    name={"expenseAccount"}
                    component={FormDropDown}
                    data={expenseAccounts}
                    dataItemKey="id"
                    textField="name"
                    validator={requiredValidator}
                    label={"Expense Account (CoA where cost of POs are logged):"}
                    itemRender={AccountDropdownItemRenderer}
                />
                <Field
                    name={"incomeAccount"}
                    component={FormDropDown}
                    data={incomeAccounts}
                    dataItemKey="id"
                    textField="name"
                    validator={requiredValidator}
                    label={"Income Account (CoA where amounts on invoices are logged):"}
                    itemRender={AccountDropdownItemRenderer}
                />
                <span
                    style={{
                        display: "flex",
                        alignItems: "end",
                        gap: 5,
                    }}
                >
                    <Field
                        style={{
                            width: 400,
                        }}
                        name={"category.name"}
                        component={FormInput}
                        label={"Category (Where it is listed in Item Categories):"}
                        readOnly={true}
                    />
                    <ItemCategoryModal
                        {...categoryModal}
                        item={item}
                        formRenderProps={formRenderProps}
                    />
                    <ActionButton
                        onClick={() => categoryModal.open()}
                        type={"button"}
                        text={"Change"}
                    />
                </span>

                {formRenderProps.valueGetter('name') && (
                    <>
                        <h4
                            style={{
                                margin: "0",
                                marginTop: "8px",
                                marginBottom: "8px",
                                borderBottom: "1px solid #ccc",
                                paddingBottom: "5px",
                                width: "55%",
                                textAlign: "left",
                            }}
                        >
                            Estimate/Sales Order Description:
                        </h4>
                        <Field
                            name={"baseDescription"}
                            component={FormTextArea}
                            autoSize={true}
                            rows={4}
                            validator={requiredValidator}
                            label={"Estimate/Sales Order Description:"}
                        />
                        <h4
                            style={{
                                margin: "0",
                                marginTop: "8px",
                                marginBottom: "8px",
                                borderBottom: "1px solid #ccc",
                                paddingBottom: "5px",
                                width: "55%",
                                textAlign: "left",
                            }}
                        >
                            Purchase Order Description:
                        </h4>
                        <Field
                            name={"purchaseOrderDescription"}
                            component={FormTextArea}
                            autoSize={true}
                            rows={4}
                            validator={requiredValidator}
                            label={"Purchase Order Description:"}
                        />
                    </>
                )}
            </span>
            <span
                style={{
                    flex: "1 1 50%",
                    minWidth: 600,
                }}
            >
                {item?.id && (
                    <span>
                        <br />
                        <ItemVendorsGrid item={item} />
                        <br />
                        <FileManager
                            disabled={false}
                            allowedExtensions={fileRestrictions}
                            saveUri={"/General/File/UploadFile"}
                            removeUri={"/General/File/DeleteFile"}
                            readUri={"/General/File/GetDirectoryContents"}
                            location={`ItemFiles-${item.id}`}
                        />
                    </span>
                )}
            </span>
        </div>
    );
};

export default ItemInformationForm;
