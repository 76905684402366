import { axiosInstance as axios } from "../../axiosInstance";
import { JobDocumentTypeEnum } from "../../../resources/Enums/JobDocumentTypeEnum";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import { getInitialDataQueryState } from "../../../utilities/kendoHelper";
import { toDataSourceRequestString } from "@progress/kendo-data-query";

/**
 * @function createJobCommunication
 * @description Function to create a job communication
 * @param {Object} communicationCreationObject
 * @returns {Promise<ServiceResponse>}
 */
export const createJobCommunication = async (communicationCreationObject) => {
    try {
        const response = await axios.post(
            `/Customer/Job/Communication/Create`,
            communicationCreationObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateJobCommunication
 * @description Function to update a job communication
 * @param {Object} communicationEditObject
 * @returns {Promise<ServiceResponse>}
 */
export const updateJobCommunication = async (communicationEditObject) => {
    try {
        const response = await axios.put(
            `/Customer/Job/Communication/Update`,
            communicationEditObject
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteJobCommunication
 * @description Function to delete a job communication
 * @param {String} communicationId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteJobCommunication = async (communicationId) => {
    try {
        const response = await axios.delete(
            `/Customer/Job/Communication/Delete?id=${communicationId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getJob
 * @description Function to get a job
 * @param {String} jobId
 * @returns {Promise<ServiceResponse>}
 */
export const getJob = async (jobId) => {
    try {
        const response = await axios.get(`/Customer/Job/Get?id=${jobId}&`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteJob
 * @description Function to delete a job
 * @param {String} jobId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteJob = async (jobId) => {
    try {
        const response = await axios.delete(`/Customer/Job/Delete?id=${jobId}`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function markJobDead
 * @description Function to mark a job as dead
 * @param {String} jobId
 * @returns {Promise<ServiceResponse>}
 */
export const markJobDead = async (jobId) => {
    try {
        const response = await axios.put(`/Customer/Job/UpdateStatus`, {
            id: jobId,
            status: 1,
        });
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function markJobArchived
 * @description Function to mark a job as archived
 * @param {String} jobId
 * @returns {Promise<ServiceResponse>}
 */
export const markJobArchived = async (jobId) => {
    try {
        const response = await axios.put(`/Customer/Job/UpdateStatus`, {
            id: jobId,
            status: 2,
        });
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function markJobActive
 * @description Function to mark a job as active
 * @param {String} jobId
 * @returns {Promise<ServiceResponse>}
 */
export const markJobActive = async (jobId) => {
    try {
        const response = await axios.put(`/Customer/Job/UpdateStatus`, {
            id: jobId,
            status: 0,
        });
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllDocuments
 * @description Function return all job documents by filter
 * @param {String} filter
 * @returns {Promise<ServiceResponse>}
 */
export const getAllDocuments = async (filter) => {
    try {
        const filterStringAdd = filter ? `&filter=${filter}` : "";
        const response = await axios.get(
            `/Customer/Job/Document/GetAll?${filterStringAdd}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getJobDocument
 * @description Function to get a job document
 * @param {String} jobDocumentId
 * @returns  {Promise<ServiceResponse>}
 */
export const getJobDocument = async (jobDocumentId) => {
    try {
        const response = await axios.get(
            `/Customer/Job/Document/Get?id=${jobDocumentId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllJobDocumentsForJob
 * @description Function to get a job document
 * @param {String} jobId
 * @returns  {Promise<ServiceResponse>}
 */
export const getAllJobDocumentsForJob = async (jobId) => {
    let kendoRequest = getInitialDataQueryState();
    kendoRequest.filter = {
        logic: "and",
        filters: [
            {
                field: "jobId",
                operator: "eq",
                value: jobId,
            },
        ],
    };
    const queryParams = toDataSourceRequestString(kendoRequest);
    try {
        const response = await axios.get(
            `Customer/Job/Document/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllItemsForJob
 * @description Function to get items of a job
 * @param {String} jobId
 * @returns  {Promise<ServiceResponse>}
 */
export const getAllJobItemsForJob = async (jobId) => {
    try {
        const response = await axios.get(
            `Customer/Job/Vendor/Item/GetAll?JobId=${jobId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
/**
 * @function addJob
 * @description Function to add a job
 * @param {Object} jobData
 * @returns  {Promise<ServiceResponse>}
 */
export const addJob = async (jobData) => {
    try {
        const response = await axios.post("/Customer/Job/Create", jobData);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateJob
 * @description Function to update a job
 * @param {Object} jobEditObject
 * @returns  {Promise<ServiceResponse>}
 */
export const updateJob = async (jobEditObject) => {
    try {
        const response = await axios.put("/Customer/Job/Update", jobEditObject);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function GetAllJobs
 * @description Function return all jobs
 * @returns {Promise<ServiceResponse>}
 */
export const getAllJobs = async (filter) => {
    const filterStringAdd = filter ? `&filter=${filter}` : "";
    try {
        const response = await axios.get(
            `/Customer/Job/GetAll?${filterStringAdd}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function updateJobDocument
 * @description Function to update job documents
 * @returns {Promise<ServiceResponse>}
 * @param jobDocument
 */

export const updateJobDocument = async (jobDocument) => {
    try {
        const response = await axios.put(
            `/Customer/Job/Document/Update`,
            jobDocument
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function createJobDocument
 * @description Function to create job documents
 * @returns {Promise<ServiceResponse>}
 * @param jobDocument
 */
export const createJobDocument = async (jobDocument) => {
    // delete jobDocument.salesPerson;

    try {
        const response = await axios.post(
            `/Customer/Job/Document/Create`,
            jobDocument
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllJobDocumentLineItemsByJobDocument
 * @description Function return all job document line items by document
 * @param {String} jobDocumentId
 * @returns {Promise<ServiceResponse>}
 */
export const getAllJobDocumentLineItemsByJobDocument = async (
    jobDocumentId
) => {
    try {
        const response = await axios.get(
            `/Customer/Job/GetAllJobDocumentLineItemsByJobDocument?id=${jobDocumentId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function deleteJobDocument
 * @description Function to delete a job document
 * @param {String} jobDocumentId
 * @returns {Promise<ServiceResponse>}
 */
export const deleteJobDocument = async (jobDocumentId) => {
    try {
        const response = await axios.delete(
            `/Customer/Job/Document/Delete?id=${jobDocumentId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateJobDocumentType = async (jobDocumentId, type) => {
    try {
        const result = await axios.post("/Customer/Job/Document/ChangeType", {
            documentId: jobDocumentId,
            type,
        });
        return formatServiceResponse(result, "Document not found.");
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllInvoicesForJob
 * @description Function return all job invoices
 * @param {String} jobId
 * @returns  {Promise<ServiceResponse>}
 */
export const getAllInvoicesForJob = async (jobId) => {
    try {
        const response = await axios.get(
            `/Customer/Job/Document/GetAll?filter=(type~eq~'${JobDocumentTypeEnum.Invoice}'~and~jobId~eq~'${jobId}')`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * @function getAllActiveJobs
 * @description Function return all active jobs.
 * @returns  {Promise<ServiceResponse>}
 */
export const getAllActiveJobs = async () => {
    try {
        const response = await axios.get(
            "/Customer/Job/GetAll?filter=(isArchived~eq~false~and~isMarkedDead~eq~false)"
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const hasAllocationsNeeded = async (jobId) => {
    const response = await axios.get(
        `/Customer/Job/Item/HasAllocationsNeeded?id=${jobId}`
    );
    return formatServiceResponse(response);
};
