import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { getAllRoles } from "../../../../services/Deprecated/authentication/roleService";
import { changeUserRole } from "../../../../services/Deprecated/authentication/userService";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormDropDown } from "../../../../components/Deprecated/FormComponents";
import { requiredValidator } from "../../../../resources/Deprecated/formValidators";
import { UserContext } from "../../../../providers/Deprecated/Authentication/User/UserProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";
import ClaimService from "../../../../services/ClaimService.js";

const ChangeUserRoleModal = (props) => {
    const { visible, close, record } = props;
    const { user } = useContext(UserContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const [roles, setRoles] = useState([]);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialValues, setInitialValues] = useState(undefined);

    useEffect(() => {
        // Only load in the roles if the modal is open
        if (!visible) {
            return;
        }

        getAllRoles().then((res) => {
            setRoles(res.data);
            setInitialValues(record ?? user);
        });
    }, [visible]);

    const onSubmit = (props) => {
        setLoaderVisible(true);

        const userId = record?.userId ?? user.id;
        const data = { userId: userId, roleId: props.role?.id };
        const claimService = new ClaimService();

        // If we are changing the Users incoming Claim, use the claim service, else we will update the User directly.
        const request = record?.isChangingClaimRole
            ? claimService.changeRole(data.roleId, props.email)
            : changeUserRole(data);

        request.then((_) => {
            triggerReload();
            onClose();
        });
    };

    const onClose = () => {
        setLoaderVisible(false);
        setInitialValues(undefined);
        setRoles([]);
        close();
    };

    return (
        visible &&
        initialValues && (
            <ResponsiveDialog
                title={"Change User Role"}
                onClose={() => onClose()}
                size={"small"}
            >
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            <Field
                                name={"role"}
                                component={FormDropDown}
                                data={roles}
                                dataItemKey="id"
                                textField="name"
                                label={"Role:"}
                                validator={requiredValidator}
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                isCreate={false}
                                actionOnCancel={() => onClose()}
                                allowSubmit={formRenderProps.allowSubmit}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default ChangeUserRoleModal;
