import React from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import RowRenderHighlighter from "../../../../components/RowRenders/RowRenderHighlighter";
import { CustomCell } from "../../../../components/Deprecated/CustomGridCells";
import { utcToLocalFormatter } from "../../../../resources/Deprecated/stringFormatter";
import { decimalToTime } from "../../../../resources/Deprecated/dateHelper";

const LaborDetailComponent = (props) => {
    const history = useHistory();

    const gotoEvent = (eventId) => history.push(`/Event/${eventId}/`);

    return (
        <Grid
            data={props.dataItem.timeClockRecords}
            rowRender={(row, props) =>
                RowRenderHighlighter(row, {
                    isWarning: !props.dataItem.calendarEvent?.id,
                })
            }
        >
            <GridNoRecords> </GridNoRecords>
            <GridColumn
                title={"Event"}
                field={"eventName"}
                className={"text-overflow"}
                cell={(props) =>
                    props.dataItem.calendarEvent?.id ? (
                        <td
                            className={"link"}
                            onClick={() =>
                                gotoEvent(props.dataItem.calendarEvent?.id)
                            }
                        >
                            {props.dataItem.calendarEvent?.name ||
                                "No Event Title"}
                        </td>
                    ) : (
                        <td>Logged To Job</td>
                    )
                }
            />
            <GridColumn
                title={"Task"}
                field={"task.name"}
                className={"text-overflow"}
            />
            <GridColumn
                title={"Start Time"}
                field="startTime"
                cell={(props) => (
                    <CustomCell {...props}>
                        {utcToLocalFormatter(
                            props.dataItem.startTime,
                            "(dddd) MM/DD/YY h:mm A"
                        )}
                    </CustomCell>
                )}
            />
            <GridColumn
                title={"End Time"}
                field="endTime"
                cell={(props) => (
                    <CustomCell {...props}>
                        {props.dataItem.endTime
                            ? utcToLocalFormatter(
                                  props.dataItem.endTime,
                                  "(dddd) MM/DD/YY h:mm A"
                              )
                            : "Currently Clocked-In"}
                    </CustomCell>
                )}
            />
            <GridColumn
                title={"Time Worked"}
                field={"timeWorked"}
                cell={(props) => (
                    <td>{decimalToTime(props.dataItem.timeWorked)}</td>
                )}
            />
        </Grid>
    );
};

export default LaborDetailComponent;
