import React, { useEffect } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import BaseInformationDisplay from "./BaseInformationDisplay";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Field } from "@progress/kendo-react-form";
import ItemCustomizationService from "../../../../services/Deprecated/inventory/ItemCustomizationService";
import {
    checkForDuplicatesInArr,
    filterArrayByValue,
} from "../../../../resources/Deprecated/helpers";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import {
    FormDropDown,
    FormInput,
    FormNumericInput,
} from "../../../../components/Deprecated/FormComponents";
import {
    nameAndDuplicateValidator,
    requiredValidator,
} from "../../../../resources/Deprecated/formValidators";
import { ActionButtonContainer } from "../../../../components/Buttons/ActionButtonContainer.jsx";
import AutoGenerationSettingsForm from "./AutoGenerationSettingsForm.jsx";

const MultiItemForm = ({ formRenderProps, categoryId }) => {
    const defaultCustomizationList = { id: null, name: "Select customization..." };
    const [selectedCustomizationList, setSelectedCustomizationList] =
        React.useState(defaultCustomizationList);
    const [itemCustomizationLists, setItemCustomizationLists] = React.useState(
        []
    );
    const [customizationLists, setCustomizationLists] = React.useState([]);

    const [autoGenerationFields, setAutoGenerationFields] = React.useState({});

    // Trick form to correctly render the fields with values
    React.useEffect(() => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        if (returnedTarget[0] === undefined) {
            setFormBaseValues(0, returnedTarget);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (categoryId === undefined) {
                    return;
                }
                const data =
                    await ItemCustomizationService.getAllCustomizationListsByCategory(
                        categoryId
                    );
                setCustomizationLists(data.data.data);
            } catch (error) {
                console.log("Error");
            }
        };

        fetchData();
    }, []);

    // Add the selected customization list to the customization lists arrayx
    const addCustomizationList = () => {
        ItemCustomizationService.getAllCustomizationsByCustomizationList(
            selectedCustomizationList.id
        ).then((res) => {
            selectedCustomizationList.customizations = res.data;
            if (JSON.stringify(selectedCustomizationList) !== "{}") {
                if (selectedCustomizationList.id !== null) {
                    const newCustomizationsList = [
                        ...itemCustomizationLists,
                        selectedCustomizationList,
                    ];
                    setItemCustomizationLists(newCustomizationsList);
                    setSelectedCustomizationList(defaultCustomizationList);
                }
            }
        });
    };

    // Remove the selected customization list from the customization lists arrayx
    const removeCustomizationList = (customizationIndex) => {
        itemCustomizationLists.splice(customizationIndex, 1);
        setItemCustomizationLists(itemCustomizationLists);

        const items = formRenderProps.valueGetter("items");
        let returnedTarget = Object.assign([], items);

        returnedTarget = returnedTarget.map((item) => {
            item.customizations.splice(customizationIndex, 1);
            return item;
        });

        formRenderProps.onChange("items", {
            value: returnedTarget,
        });
    };

    // Clear the names and descriptions of all items
    const clearNamesAndDescriptions = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        for (let i = 0; i < Object.keys(returnedTarget).length; i++) {
            returnedTarget[i].name = "";
            returnedTarget[i].purchaseOrderDescription = "";
            returnedTarget[i].baseDescription = "";
        }

        formRenderProps.onChange("items", {
            value: Object.values(returnedTarget),
        });
    };

    // Add the selected item rowx
    const addItemRow = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        setFormBaseValues(items.length, returnedTarget);
    };

    // Remove the selected item row
    const removeItemRow = (index) => {
        const items = formRenderProps.valueGetter("items");

        if (items.length !== 1) {
            const items = formRenderProps.valueGetter("items");
            const returnedTarget = Object.assign({}, items);

            delete returnedTarget[index];
            resetIndexes();

            formRenderProps.onChange("items", {
                value: Object.values(returnedTarget),
            });
        }
    };

    /**
     * Auto-generates the available fields for each item
     */
    const autoGenerateFields = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        for (let i = 0; i < Object.keys(returnedTarget).length; i++) {
            ["name", "purchaseOrderDescription", "baseDescription"].forEach(field => {
                if (!autoGenerationFields[field]) {
                    returnedTarget[i][field] = "";
                    return;
                }

                if (!autoGenerationFields[field].length) {
                    returnedTarget[i][field] = "";
                    return;
                }

                const values = [];
                const itemCustomizations = formRenderProps.valueGetter(`items[${i}].customizations`) || [];

                for (let ic = 0; ic < itemCustomizations.length; ic++) {
                    const itemCustomization = itemCustomizations[ic];
                    if (itemCustomization.customization) {
                        const index = autoGenerationFields[field].findIndex(c => c.id === itemCustomization.customization.parentId);
                        if (index !== -1)
                            values.push(itemCustomization.customization.name);
                    }
                }

                returnedTarget[i][field] = values.join(" ");
            });
        }

        formRenderProps.onChange("items", {
            value: Object.values(returnedTarget),
        });
    }

    // Function to DRY up code for setting form base values
    const setFormBaseValues = (index, target) => {
        resetIndexes();
        target[index] = {
            index: index,
            name: formRenderProps.valueGetter("name"),
            purchaseOrderDescription: formRenderProps.valueGetter(
                "purchaseOrderDescription"
            ),
            baseDescription: formRenderProps.valueGetter("baseDescription"),
            retailPrice: formRenderProps.valueGetter("retailPrice"),
            vendorCost: 0,
            manufacturerNumber:
                formRenderProps.valueGetter("manufacturerNumber"),
            customizations: [],
        };

        formRenderProps.onChange("items", {
            value: Object.values(target),
        });
    };

    // Loop over each item record, reset the index
    const resetIndexes = () => {
        const items = formRenderProps.valueGetter("items");
        const returnedTarget = Object.assign({}, items);

        for (let i = 0; i < Object.keys(returnedTarget).length; i++) {
            returnedTarget[i].index = i;
        }

        formRenderProps.onChange("items", {
            value: Object.values(returnedTarget),
        });
    };

    // Check for duplicate names in Grid
    function checkForDuplicateNames(name) {
        const items = formRenderProps.valueGetter("items");

        if (items !== undefined) {
            const itemNames = items.map((item) => item.name);

            return checkForDuplicatesInArr(itemNames, name);
        }

        return false;
    }

    /**
     * Updates the auto generation fields state value
     * @param {string} field 
     * @param {array} items 
     */
    const updateAutoGenerationFields = (field, items) => {
        setAutoGenerationFields({
            ...autoGenerationFields,
            [field]: items
        })
    }

    return (
        <GridLayout
            rows={[{ height: "auto" }, { height: "auto" }]}
            cols={[{ width: "60%" }, { width: "40%" }]}
        >
            <GridLayoutItem col={1} row={1} colSpan={1}>
                {/* Base Information Display */}
                <BaseInformationDisplay formRenderProps={formRenderProps} />
            </GridLayoutItem>
            <GridLayoutItem
                col={2}
                row={1}
                colSpan={1}
            >
                <GridLayout
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: "8px",
                        marginTop: "10px",
                    }}
                >
                    {/* Customizations Dropdown */}
                    <ComboBox
                        data={filterArrayByValue(
                            customizationLists,
                            itemCustomizationLists
                        )}
                        dataItemKey="id"
                        textField="name"
                        style={{ width: '100%' }}
                        value={selectedCustomizationList}
                        clearButton={false}
                        onChange={(e) => {
                            if (e.target.value) {
                                setSelectedCustomizationList(e.target.value);
                            }
                        }}
                    />
                    <ActionButton
                        icon={"k-icon k-i-plus"}
                        onClick={addCustomizationList}
                        disabled={!selectedCustomizationList.id}
                        text={"Add Customization List"}
                        theme={"success"}
                        disabledToolTipMessage={
                            "Please select a customization list"
                        }
                    />
                </GridLayout>
                <GridLayout
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: "25px",
                        marginTop: "10px",
                    }}
                >
                    <ActionButtonContainer
                        style={{
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        {/* Customization Buttons */}
                        <ActionButton
                            themeColor={"success"}
                            style={{ width: "auto" }}
                            icon={"k-icon k-i-plus"}
                            onClick={addItemRow}
                        >
                            Add Item Row
                        </ActionButton>
                        {itemCustomizationLists.length >= 1 && (
                            <>
                                <ActionButton
                                    themeColor={"info"}
                                    style={{ width: "auto" }}
                                    icon={"k-icon k-i-refresh"}
                                    onClick={autoGenerateFields}
                                >
                                    Auto Generate Fields
                                </ActionButton>
                                <ActionButton
                                    style={{ width: "auto" }}
                                    icon={"k-icon k-i-undo"}
                                    onClick={clearNamesAndDescriptions}
                                >
                                    Clear Names/Descriptions
                                </ActionButton>
                            </>
                        )}
                    </ActionButtonContainer>
                </GridLayout>
            </GridLayoutItem >


            <GridLayoutItem
                col={1}
                colSpan={2}
            >
                {itemCustomizationLists.length >= 1 && (
                    <div
                        style={{
                            marginBottom: 25,
                            gap: 25,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <AutoGenerationSettingsForm
                            label={"Name"}
                            items={itemCustomizationLists}
                            field="name"
                            onChange={updateAutoGenerationFields}
                        />

                        <AutoGenerationSettingsForm
                            label={"SE/SO"}
                            items={itemCustomizationLists}
                            field="baseDescription"
                            onChange={updateAutoGenerationFields}
                        />

                        <AutoGenerationSettingsForm
                            label={"PO"}
                            items={itemCustomizationLists}
                            field="purchaseOrderDescription"
                            onChange={updateAutoGenerationFields}
                        />
                    </div>
                )}
            </GridLayoutItem>

            {/* Item Rows */}
            < GridLayoutItem
                col={1}
                colSpan={2}
                style={{
                    padding: "0 0 15px 0",
                }}
            >
                <GridLayout
                    style={{
                        border: "2px solid #ccc",
                        padding: "15px",
                        borderRadius: "5px",
                        overflow: "scroll",
                        maxHeight: "30vh",
                    }}
                >
                    {(formRenderProps.valueGetter("items") || []).map(
                        (item, index) => (
                            <GridLayoutItem
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    gap: "25px",
                                    marginTop: "25px",
                                }}
                            >
                                <ActionButton
                                    themeColor={"error"}
                                    style={{ width: "auto", marginTop: 20 }}
                                    icon={"k-icon k-i-delete"}
                                    onClick={() => removeItemRow(index)}
                                    disabled={
                                        formRenderProps.valueGetter("items")
                                            .length === 1
                                    }
                                >
                                    Delete
                                </ActionButton>
                                <Field
                                    name={"items[" + index + "].name"}
                                    component={FormInput}
                                    validator={(value) =>
                                        nameAndDuplicateValidator(
                                            value,
                                            checkForDuplicateNames(value)
                                        )
                                    }
                                    style={{ width: 200 }}
                                    label={"Name"}
                                />
                                <Field
                                    name={
                                        "items[" + index + "].baseDescription"
                                    }
                                    component={FormInput}
                                    validator={requiredValidator}
                                    style={{ width: 200 }}
                                    label={"SE/SO Description"}
                                />
                                <Field
                                    name={
                                        "items[" +
                                        index +
                                        "].purchaseOrderDescription"
                                    }
                                    component={FormInput}
                                    style={{ width: 200 }}
                                    label={"PO Description"}
                                />
                                <Field
                                    name={"items[" + index + "].retailPrice"}
                                    component={FormNumericInput}
                                    format={"c2"}
                                    style={{ width: 200 }}
                                    label={"Retail Price"}
                                />
                                <Field
                                    name={"items[" + index + "].vendorCost"}
                                    component={FormNumericInput}
                                    format={"c2"}
                                    style={{ width: 200 }}
                                    label={"Vendor Cost"}
                                />
                                <Field
                                    name={"items[" + index + "].freightCost"}
                                    component={FormNumericInput}
                                    format={"c2"}
                                    style={{ width: 200 }}
                                    label={"Freight Cost"}
                                />
                                {itemCustomizationLists.map(
                                    (customizationList, index) => (
                                        <span
                                            style={{
                                                marginTop:
                                                    item.index === 0
                                                        ? -30
                                                        : "initial",
                                            }}
                                            key={index}
                                        >
                                            {item.index === 0 && (
                                                <ActionButton
                                                    themeColor={"error"}
                                                    style={{ width: "auto" }}
                                                    icon={"k-icon k-i-delete"}
                                                    onClick={() =>
                                                        removeCustomizationList(
                                                            index
                                                        )
                                                    }
                                                >
                                                    Remove List
                                                </ActionButton>
                                            )}
                                            <Field
                                                name={
                                                    "items[" +
                                                    item.index +
                                                    "].customizations[" +
                                                    index +
                                                    "].customization"
                                                }
                                                component={FormDropDown}
                                                data={
                                                    customizationList.customizations.map(c => {
                                                        return {
                                                            ...c,
                                                            parentId: customizationList.id
                                                        }
                                                    })
                                                }
                                                dataItemKey="id"
                                                textField="name"
                                                style={{ width: 200 }}
                                                label={customizationList.name}
                                            />
                                        </span>
                                    )
                                )}
                            </GridLayoutItem>
                        )
                    )}
                </GridLayout>
            </GridLayoutItem>
        </GridLayout>
    );
};

export default MultiItemForm;
