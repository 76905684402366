import React, { useContext, useEffect, useState } from "react";
import { ContentHeader } from "../../../components/ContentHeader";
import dayjs from "dayjs";
import { ActionButton } from "../../../components/Buttons/ActionButton";
import PayrollService from "../../../services/Deprecated/humanResources/PayrollService";
import { PayrollContainer } from "./PayrollContainer";
import { ToastContext } from "../../../providers/ToastProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Link } from "react-router-dom";
import { PayrollTypeEnum } from "../../../resources/Enums/PayrollTypeEnum.js";

export const Payroll = () => {
    const { showToast } = useContext(ToastContext);
    const [payrollInterval404, setPayrollInterval404] = useState(false);
    const [payrollType, setPayrollType] = useState(undefined);
    const [payrollData, setPayrollData] = useState(undefined);
    const [payrollCopyText, setPayrollCopyText] = useState("");
    const [payPeriods, setPayPeriods] = useState([]);
    const [selectedPayPeriod, setSelectedPayPeriod] = useState(null);
    const [dateRange, setDateRange] = useState({
        start: dayjs().subtract(2, "week").toDate(),
        end: new Date(),
    });

    useEffect(() => {
        PayrollService.getPayrollInterval().then((res) => {
            if (!res.data) {
                setPayrollInterval404(true);
                return;
            }

            setPayrollType(res.data.type);
            PayrollService.getIntervalDates().then((res) => {
                if (!res.data) return;

                const payPeriodsData = res.data.map((period) => ({
                    ...period,
                    rangeText: `${dayjs(period.start).format(
                        "MM/DD/YYYY"
                    )} - ${dayjs(period.end).format("MM/DD/YYYY")}`,
                }));
                setPayPeriods(payPeriodsData);
                setSelectedPayPeriod(payPeriodsData[0]);
                setDateRange({
                    start: dayjs(payPeriodsData[0].start).toDate(),
                    end: dayjs(payPeriodsData[0].end).toDate(),
                });
            });
        });
    }, []);

    const generateReport = (data) => {
        const start = dayjs(data.start).format("YYYY-MM-DD");
        const end = dayjs(data.end).format("YYYY-MM-DD");
        PayrollService.generatePayroll(payrollType, start, end).then((r) => {
            setPayrollData(r.data.serializedRows);
            setPayrollCopyText(r.data.clientCopyString);
        });
    };

    const exportPayroll = async (data) => {
        const start = dayjs(data.start).format("YYYY-MM-DD");
        const end = dayjs(data.end).format("YYYY-MM-DD");
        await PayrollService.exportPayroll(payrollType, start, end);
        showToast("Report downloaded", "success");
    };

    // Handle pay period change
    const onPayPeriodChange = (e) => {
        setSelectedPayPeriod(e.value);
        setDateRange({
            start: dayjs(e.value.start).toDate(),
            end: dayjs(e.value.end).toDate(),
        });
    };

    const onCopyReport = async () => {
        await navigator.clipboard.writeText(payrollCopyText);
        showToast("Report copied to clipboard", "success");
    };

    return (
        <div>
            {!isNaN(payrollType) && (
                <div style={{ margin: 20 }}>
                    <ContentHeader
                        title={`${PayrollTypeEnum.ParseToString(
                            payrollType
                        )} Payroll`}
                        className={"AlignTextLeft"}
                    />
                    <span className={"JustifyLeftAlignLeft"}>
                        <DropDownList
                            data={payPeriods}
                            textField="rangeText"
                            value={selectedPayPeriod}
                            onChange={onPayPeriodChange}
                            style={{ width: "250px", marginBottom: "10px" }}
                        />
                    </span>
                    <br />
                    <span className={"JustifyLeftAlignCenter MediumGap"}>
                        <ActionButton
                            text={"Generate Report"}
                            onClick={() => generateReport(dateRange)}
                            theme={"success"}
                        />
                        <ActionButton
                            text={"Download Report"}
                            icon={"k-icon k-i-download"}
                            onClick={() => exportPayroll(dateRange)}
                        />
                        <ActionButton
                            text={"Copy Report"}
                            icon={"k-icon k-i-copy"}
                            disabled={!payrollData || payrollData?.length === 0}
                            onClick={onCopyReport}
                        />
                    </span>
                    <br />
                    <PayrollContainer
                        data={payrollData}
                        gridType={payrollType}
                    />
                </div>
            )}
            {payrollInterval404 && (
                <div>
                    <h2>Payroll interval has not yet been configured.</h2>
                    <p>
                        Please go{" "}
                        <Link to="/Admin/Configuration/Employee">
                            Payroll Configuration
                        </Link>
                        . If you believe this is an error, please contact your
                        supervisor or support.
                    </p>
                </div>
            )}
        </div>
    );
};
