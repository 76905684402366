import React, { useContext, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import dayjs from "dayjs";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import CalendarService from "../../../../services/Deprecated/calendar/CalendarService";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { FormDateInput } from "../../../../components/Deprecated/FormComponents";
import { FormButtons } from "../../../../components/Buttons/FormButtons";
import { dateFormatValidator } from "../../../../resources/Deprecated/formValidators.js";

const CalendarEventCopyModal = (props) => {
    const { id, close, visible } = props;
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { triggerReload } = useContext(ReloadDataContext);
    const initValues = { eventDate: dayjs().format("MM/DD/YYYY") };

    const onSubmit = (dataItem) => {
        if (!dataItem.isValid) {
            return;
        }

        setLoaderVisible(true);

        const data = {
            copiedEventId: id,
            eventDate: dayjs(dataItem.values.eventDate).format("YYYY-MM-DD"),
        };

        CalendarService.copy(data).then((_) => {
            triggerReload();
            close();
            setLoaderVisible(false);
        });
    };

    return (
        visible && (
            <ResponsiveDialog
                title={"Enter Date of new Event"}
                onClose={() => close()}
                size={"small"}
            >
                <Form
                    initialValues={initValues}
                    onSubmitClick={onSubmit}
                    render={() => (
                        <FormElement>
                            <Field
                                name={"eventDate"}
                                component={FormDateInput}
                                validator={dateFormatValidator}
                                label={"Event Date:"}
                                hint="MM/DD/YYYY"
                            />
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={() => close()}
                                allowSubmit={true}
                                isCreate={true}
                                text={"Copy"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};

export default CalendarEventCopyModal;
