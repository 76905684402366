import React from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import { HighlightedTabStrip } from "../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";
import EmployeeDashboardPage from "./EmployeeDashboardPage.jsx";
import InActiveEmployeeDashboardPage from "./InActiveEmployeeDashboardPage.jsx";

const EmployeeDashboardTabs = () => {
    return (
        <HighlightedTabStrip>
            <TabStripTab title="Active">
                <EmployeeDashboardPage />
            </TabStripTab>
            <TabStripTab title="Inactive">
                <InActiveEmployeeDashboardPage />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default EmployeeDashboardTabs;
