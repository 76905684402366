import { Field } from "@progress/kendo-react-form";
import * as React from "react";
import { useEffect, useState } from "react";
import {
    FormDropDown,
    FormNumericInput,
    FormRemoteDropDown,
} from "../../../../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../../../../resources/Deprecated/formValidators.js";
import StorageLocationService from "../../../../../../../../../services/StorageLocationService.js";
import { CenterLoader } from "../../../../../../../../../components/Deprecated/CenterLoader.jsx";

/**
 * @function EachUse
 * @param props.formRenderProps {object}
 * @param props.record {object}
 * @return {Element}
 * @constructor
 */
export const EachUse = (props) => {
    const { formRenderProps, record } = props;
    const [storageLocations, setStorageLocations] = useState(undefined);
    const [currentMaxQty, setCurrentMaxQty] = useState(undefined);

    useEffect(() => {
        if (!record) return;

        if (record.isUnUsed) {
            setCurrentMaxQty(record.qtyUsed);
            setStorageLocations([]);
            formRenderProps.onChange("quantity", {
                value: record.qtyUsed,
            });
        } else {
            const service = new StorageLocationService(
                "Inventory/StorageLocation"
            );
            service
                .getAllContainingItem(record.itemId, record.jobId)
                .then((res) => {
                    let locations = res.data.filter(
                        (l) => l.allocatedInventories.length > 0
                    );

                    setStorageLocations(locations);
                    if (locations.length !== 0) {
                        const storageLocation = locations[0];
                        const maxQuantity = calculateMaxQty(
                            storageLocation,
                            record.isUnUsed
                        );
                        setCurrentMaxQty(maxQuantity);
                        formRenderProps.onChange("storageLocation", {
                            value: storageLocation,
                        });
                        formRenderProps.onChange("quantity", {
                            value: maxQuantity,
                        });
                    }
                });
        }
    }, [record]);

    const calculateMaxQty = (storageLocation, isUnUsed) => {
        return isUnUsed
            ? record.qtyUsed
            : storageLocation.allocatedInventories.length;
    };

    return storageLocations ? (
        <span style={{ width: "100%" }}>
            {record.isUnUsed ? (
                <Field
                    url={"/Inventory/StorageLocation/GetAll?"}
                    name={"returningLocation"}
                    component={FormRemoteDropDown}
                    label={"Location To Return Item(s):"}
                    validator={requiredValidator}
                />
            ) : (
                <Field
                    name={"storageLocation"}
                    data={storageLocations}
                    component={FormDropDown}
                    label={"Select a Storage Location..."}
                    validator={requiredValidator}
                    style={{ marginBottom: "15px" }}
                    onChange={(e) => {
                        const storageLocation = e.value;
                        const maxQuantity = record.isUnUsed
                            ? record.qtyUsed
                            : storageLocation.allocatedInventories.length;
                        setCurrentMaxQty(maxQuantity);
                        formRenderProps.onChange("quantity", {
                            value: maxQuantity,
                        });
                    }}
                />
            )}
            <Field
                name={"quantity"}
                component={FormNumericInput}
                label={`Amount to ${
                    record.isUnUsed ? "Un-Use" : "Use"
                } from Allocations:`}
                validator={requiredValidator}
                min={1}
                max={currentMaxQty}
            />
        </span>
    ) : (
        <CenterLoader />
    );
};
