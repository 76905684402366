import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance.js";
import { formatServiceResponse } from "../utilities/serviceUtility.js";

/**
 * @class ClaimService
 * @extends BaseService
 * @description Service class that handles all requests to the claim service
 * @constructor
 */
export default class ClaimService extends BaseService {
    constructor() {
        super("Authentication/UserClaim");
    }

    /**
     * @method changeRole
     * @description Changes the Role of a incoming claim
     * @param roleId {String} - The ID of the role to set
     * @param email {String} - The Email of the incoming claim
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async changeRole(roleId, email) {
        const res = await axios.post(`${this.uri}/ChangeRole?`, {
            roleId,
            email,
        });
        return formatServiceResponse(res);
    }
}
