import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export const getBillingAdjustment = async (billingAdjustmentId) => {
    try {
        const response = await axios.get(
            `/Inventory/BillingAdjustment/Get?id=${billingAdjustmentId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const getAllBillingAdjustments = async () => {
    try {
        const response = await axios.get(`/Inventory/BillingAdjustment/GetAll`);
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const updateBillingAdjustment = async (billingAdjustmentData) => {
    try {
        // Send a PUT request to the server with the marital data
        const response = await axios.put(
            "/Inventory/BillingAdjustment/Update",
            billingAdjustmentData
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        // Log any errors and throw them
        return formatServiceResponse(error);
    }
};

export const addBillingAdjustment = async (billingAdjustmentData) => {
    try {
        const response = await axios.post(
            "/Inventory/BillingAdjustment/Create",
            billingAdjustmentData
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

export const deleteBillingAdjustment = async (billingAdjustmentId) => {
    try {
        const response = await axios.delete(
            `/Inventory/BillingAdjustment/Delete?id=${billingAdjustmentId}`
        );
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};
