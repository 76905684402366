import React, { useContext } from "react";
import dayjs from "dayjs";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import FormField from "../../../../components/Deprecated/StylingField";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";

const EmployeeRelations = () => {
    const employeeContext = useContext(EmployeeContext);
    const formatDateOfBirth = (date) => {
        return dayjs(date).format("MM-DD-YYYY");
    };

    return employeeContext.employee.id ? (
        <div style={{ display: "flex", gap: "5px" }}>
            {/* Column 1 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "40%",
                }}
            >
                <FormField
                    label="Labor Type"
                    value={employeeContext.employee.laborType.name}
                />
                <FormField
                    label="Date of Birth"
                    value={formatDateOfBirth(
                        employeeContext.employee.dateOfBirth
                    )}
                />
                <FormField
                    label="Marital Status"
                    value={employeeContext.employee?.maritalStatus.name}
                />
                <FormField
                    label="ID Type"
                    value={employeeContext.employee?.identificationType.name}
                />
            </div>
            {/* Column 2 */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    width: "60%",
                }}
            >
                <div style={{ display: "flex", gap: "5px" }}>
                    <FormField
                        label="Payroll ID"
                        value={employeeContext.employee.payrollId}
                    />
                    <FormField
                        label="ID#"
                        value={employeeContext.employee?.identificationNumber}
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, paddingRight: 6 }}>
                        <FormField
                            label="Employee Locations"
                            value={employeeContext.employee.locations.map(
                                (location) => (
                                    <div key={location.id}>
                                        {location.locationName}
                                    </div>
                                )
                            )}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <FormField
                            label="Primary Location"
                            value={
                                employeeContext.employee.locations.find(
                                    (loc) => loc.isPrimaryLocation
                                )?.locationName
                            }
                        />
                    </div>
                </div>
                <div>
                    <FormField
                        label="Employee Supervisor"
                        value={employeeContext.employee?.supervisor?.name}
                    />
                </div>
            </div>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default EmployeeRelations;
