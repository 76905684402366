import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ReloadDataContext } from "../../../../../../../../providers/ReloadDataProvider.jsx";
import InventoryService from "../../../../../../../../services/InventoryService.js";
import { FormButtons } from "../../../../../../../../components/Buttons/FormButtons.jsx";
import ResponsiveDialog from "../../../../../../../../components/Deprecated/DialogWrapper.jsx";
import ItemService from "../../../../../../../../services/ItemService.js";
import { SqftUse } from "./Components/SqftUse.jsx";
import { EachUse } from "./Components/EachUse.jsx";
import { UseModalHelper } from "../../Helpers/UseModalHelper.js";

/**
 * @function UseModal
 * @param props.close {function}
 * @param props.visible {boolean}
 * @param props.record {object}
 * @return {JSX.Element}
 * @constructor
 */
export const UseModal = (props) => {
    const { close, visible, record } = props;
    const { triggerReload } = useContext(ReloadDataContext);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialFormData, setInitialFormData] = useState(undefined);

    useEffect(() => {
        if (!record) return;

        const service = new ItemService();
        service.get(record.itemId).then((res) => {
            setInitialFormData({
                selectedItem: res.data,
                retailUom: res.data.retailUom,
                ...record,
            });
        });
    }, [record]);

    const onClose = () => {
        setInitialFormData(undefined);
        close();
    };

    const onSubmit = (e) => {
        if (!e.isValid) return;
        if (initialFormData.retailUom.name === "Sqft" && !e.values.sqftItems)
            return;

        setLoaderVisible(true);

        const data = UseModalHelper.formatDataForNetwork(
            e.values,
            record.isUnUsed
        );

        const inventoryService = new InventoryService();
        const request = record.isUnUsed
            ? inventoryService.unUse(data)
            : inventoryService.use(data);

        request.then(() => {
            triggerReload();
            setLoaderVisible(false);
            onClose();
        });
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={!record.isUnUsed ? "Use" : "Un-Use"}
                onClose={onClose}
                size={"medium"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement
                            className={
                                "JustifyCenterAndAlignCenter FlexColumn MediumGap"
                            }
                        >
                            {initialFormData.retailUom.name === "Sqft" ? (
                                <SqftUse
                                    record={initialFormData}
                                    formRenderProps={formRenderProps}
                                />
                            ) : (
                                <EachUse
                                    record={initialFormData}
                                    formRenderProps={formRenderProps}
                                />
                            )}
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                text={record.isUnUsed ? "Un-Use" : "Use"}
                                isCreate={true}
                                theme={"primary"}
                                icon={record.isUnUsed ? "minus" : "plus"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
