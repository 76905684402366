import React, { useContext, useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useMsal } from "@azure/msal-react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Loader } from "@progress/kendo-react-indicators";
import { AuthContext } from "../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import {
    reloadMsal,
    swapTenant,
    wait,
} from "../../services/Deprecated/authentication/userService.js";
import { useToggle } from "../../hooks/Deprecated/useToggle.js";
import { useLocalStorage } from "../../hooks/useLocalStorage.js";
import { useHistory } from "react-router-dom";

const SwitchingTenantsDialogue = ({ visible }) => {
    return visible ? (
        <Dialog width={"auto"} height={"auto"}>
            <p>We are switching your tenant</p>
            <p>This process may take up to 30 seconds to complete...</p>
            <Loader type={"infinite-spinner"} />
        </Dialog>
    ) : null;
};

export const SwitchTenant = () => {
    const authContext = useContext(AuthContext);
    const localStorage = useLocalStorage();
    const history = useHistory();
    const { instance } = useMsal();
    const [userTenants, setUserTenants] = useState([]);
    const [currentTenant, setCurrentTenant] = useState(null);
    const {
        bool: switchingTenantsDialogueVisible,
        toggle: toggleSwitchingTenantsDialogueVisible,
    } = useToggle();

    useEffect(() => {
        if (authContext.user) {
            const { tenants, activeTenant } = authContext.user;

            if (tenants) {
                setUserTenants(tenants.filter((e) => e.id !== activeTenant.id));
                setCurrentTenant(activeTenant);
            }
        }
    }, [authContext.user]);

    const onSubmit = async (e) => {
        const { id } = e.target.value;
        const result = await swapTenant(id, authContext.user.id);
        if (result.success) {
            toggleSwitchingTenantsDialogueVisible();
            localStorage.clear();
            history.push("/");
            await wait(20);
            await reloadMsal(instance);
        }
    };

    return (
        <>
            <SwitchingTenantsDialogue
                visible={switchingTenantsDialogueVisible}
            />
            <form className="k-form" onSubmit={(e) => e.preventDefault()}>
                <fieldset
                    style={{
                        border: "none",
                        padding: "0px",
                    }}
                >
                    <DropDownList
                        style={{
                            width: "auto",
                        }}
                        data={userTenants}
                        dataItemKey={"id"}
                        textField={"name"}
                        defaultValue={currentTenant}
                        disabled={userTenants.length === 0}
                        onChange={(e) => onSubmit(e)}
                    />
                </fieldset>
            </form>
        </>
    );
};
