import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { BusinessInformationDisplay } from "../../../../../components/Pages/Documents/BusinessInformationDisplay.jsx";
import { DocumentInformationDisplay } from "../../../../../components/Pages/Documents/DocumentInformationDisplay.jsx";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import styles from "./Styles/JobDocumentModal.module.scss";
import { DetailsHamburger } from "../../../../../components/Buttons/DetailsHamburger.jsx";
import JobDocumentModalProvider, {
    JobDocumentModalContext,
} from "./Providers/JobDocumentModalProvider.jsx";
import { NewJobDocumentType } from "../../../../../resources/Enums/JobDocumentTypeEnum.js";
import { JobDocumentModalHelper } from "./Helpers/JobDocumentModalHelper.js";
import { JobDocumentModalCustomerAndJobDisplay } from "./Components/JobDocumentModalCustomerAndJobDisplay.jsx";
import { JobDocumentSalesPerson } from "./Components/JobDocumentSalesPerson.jsx";
import { JobDocumentDescription } from "./Components/JobDocumentDescription.jsx";
import { JobDocumentLineItems } from "./Components/JobDocumentLineItems.jsx";
import { PDFExport } from "@progress/kendo-react-pdf";
import { exportPDF } from "../../../../../resources/Deprecated/helpers.js";
import { JobDocumentTotals } from "./Components/JobDocumentTotals.jsx";
import { JobDocumentAuditInfo } from "./Components/JobDocumentAuditInfo.jsx";
import DocumentLineItemProvider from "../../../../../providers/DocumentLineItemProvider.jsx";

// Wraps the JobDocumentModal component with the JobDocumentProvider
export const JobDocumentModalWrapper = (props) => {
    return (
        <DocumentLineItemProvider>
            <JobDocumentModalProvider>
                <JobDocumentModal {...props} />
            </JobDocumentModalProvider>
        </DocumentLineItemProvider>
    );
};

/**
 * @function JobDocumentModal
 * @param props.id {string} - id of the record
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {JSX.Element}
 * @constructor
 */
const JobDocumentModal = (props) => {
    const {
        formData,
        reloadDocumentKey,
        setModalState,
        onModalClose,
        modalState,
        onModalSubmit,
        onDelete,
        canDelete,
        canEdit,
        onChangeStatus,
        pdfContainerRef,
        hasPendingChangeOrder,
    } = useContext(JobDocumentModalContext);
    const [options, setOptions] = useState(undefined);

    useEffect(() => {
        setModalState(props);
    }, [props.visible]);

    useEffect(() => {
        const optionsArray = [
            {
                icon: "print",
                actionName: "Export PDF",
                action: () => {
                    exportPDF(
                        `${
                            formData.name ?? "unnamed"
                        }-${new Date().toLocaleDateString()}`,
                        pdfContainerRef
                    );
                },
            },
        ];

        if (!modalState.id) {
            setOptions(optionsArray);
            return;
        }

        if (
            canEdit &&
            formData?.type === NewJobDocumentType.Enum.PendingChangeOrder
        ) {
            optionsArray.push({
                icon: "redo",
                actionName: "Approve",
                action: () => {
                    onChangeStatus(
                        NewJobDocumentType.Enum.ApprovedChangeOrder,
                        false
                    );
                    onModalClose();
                },
            });
        }

        if (canEdit && formData?.type === NewJobDocumentType.Enum.Estimate) {
            optionsArray.push({
                icon: "redo",
                actionName: "Convert to Sales Order",
                action: () => {
                    onChangeStatus(NewJobDocumentType.Enum.SalesOrder);
                },
            });
        }

        if (
            canEdit &&
            formData?.type === NewJobDocumentType.Enum.SalesOrder &&
            !hasPendingChangeOrder
        ) {
            optionsArray.push({
                icon: "redo",
                actionName: "Convert to Invoice",
                action: () => {
                    onChangeStatus(NewJobDocumentType.Enum.Invoice);
                },
            });
        }

        if (canDelete) {
            optionsArray.push({
                icon: "delete",
                actionName: "Delete",
                action: () => onDelete(),
            });
        }

        setOptions(optionsArray);
    }, [formData, canDelete, canEdit]);

    return (
        modalState?.visible &&
        formData &&
        options && (
            <Form
                key={reloadDocumentKey}
                onSubmitClick={onModalSubmit}
                initialValues={formData}
                render={() => (
                    <PDFExport>
                        <ResponsiveDialog
                            title={JobDocumentModalHelper.generateModalTitle(
                                formData
                            )}
                            onClose={onModalClose}
                            size={"extraLarge"}
                        >
                            <FormElement>
                                <div
                                    ref={pdfContainerRef}
                                    className={styles.JobDocumentModalContainer}
                                >
                                    <Card style={{ width: "100%" }}>
                                        <CardHeader
                                            style={{
                                                display: "flex",
                                                justifyContent: "left",
                                                alignItems: "center",
                                            }}
                                        >
                                            <JobDocumentDescription />
                                        </CardHeader>
                                        <CardBody
                                            className={styles.InfoCardBody}
                                        >
                                            <span>
                                                <BusinessInformationDisplay
                                                    formData={formData}
                                                />
                                                <JobDocumentModalCustomerAndJobDisplay />
                                            </span>
                                            <span
                                                className={
                                                    styles.InfoDocumentDisplay
                                                }
                                            >
                                                <DocumentInformationDisplay
                                                    formData={formData}
                                                    type={NewJobDocumentType.ParseToString(
                                                        formData.type
                                                    )}
                                                    canDateBeEdited={canEdit}
                                                />
                                                <JobDocumentSalesPerson />
                                                <JobDocumentAuditInfo />
                                            </span>
                                            {options.length > 0 && (
                                                <span className={"export-hide"}>
                                                    <DetailsHamburger
                                                        options={options}
                                                        text={"Details"}
                                                    />
                                                </span>
                                            )}
                                        </CardBody>
                                    </Card>
                                    {hasPendingChangeOrder && (
                                        <h3
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: 10,
                                                margin: 0,
                                            }}
                                        >
                                            <i
                                                className={
                                                    "k-icon k-icon-md k-i-warning"
                                                }
                                                style={{
                                                    color: "orange",
                                                }}
                                            />{" "}
                                            Sales Order has a pending Change
                                            Order.
                                        </h3>
                                    )}
                                    <JobDocumentLineItems />
                                    <JobDocumentTotals
                                        style={{
                                            alignSelf: "flex-end",
                                            padding: 10,
                                        }}
                                    />
                                    <div
                                        className="export-show-only"
                                        style={{
                                            padding: 10,
                                            width: "100%",
                                        }}
                                    >
                                        <div className={"AlignTextLeft"}>
                                            Signature:
                                        </div>
                                        <hr className={"Separator"} />
                                    </div>
                                </div>
                            </FormElement>
                        </ResponsiveDialog>
                    </PDFExport>
                )}
            />
        )
    );
};
