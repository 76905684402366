import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "@progress/kendo-react-layout";
import styles from "./AllocationSummary.module.scss";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { JobContext } from "../../../../../providers/Deprecated/Customer/JobProvider";
import JobService from "../../../../../services/JobService";
import { LinkCell } from "../../../../../components/GridColumnComponents/LinkCell";
import { BaseCell } from "../../../../../components/GridColumnComponents/BaseCell";

const AllocationSummary = () => {
    const [data, setData] = useState([]);
    const { job } = useContext(JobContext);

    useEffect(() => {
        if (!job?.id) return;

        const service = new JobService("/Customer/Job");
        service.getAllocationSummary(job.id).then((res) => {
            setData(res.data);
        });
    }, [job]);

    return (
        <Card className={styles.AllocationCard}>
            <CardHeader
                style={{
                    backgroundColor: "#67809F",
                    color: "#fff",
                }}
            >
                <CardTitle>Allocated Items Summary</CardTitle>
            </CardHeader>
            <CardBody>
                <Grid className={styles.AllocationGrid} data={data}>
                    <GridNoRecords> </GridNoRecords>
                    <GridColumn
                        className={"text-overflow"}
                        field={"item.name"}
                        title={"Item:"}
                        cell={(props) => (
                            <LinkCell
                                {...props}
                                uri={`/Inventory/Item/${props.dataItem.item.id}`}
                                dataItem={{
                                    id: props.dataItem.item.id,
                                    item: {
                                        name: props.dataItem.item.name,
                                    },
                                }}
                            />
                        )}
                    />
                    <GridColumn
                        field="quantity"
                        title="Allocated From Inv:"
                        width={200}
                        cell={(props) => (
                            <BaseCell {...props}>
                                {props.dataItem.quantity}{" "}
                                {props.dataItem.retailUom.name}
                            </BaseCell>
                        )}
                    />
                    <GridColumn
                        title={"Total Of Allocations:"}
                        width={200}
                        field={"total.amount"}
                        format={"{0:c}"}
                    />
                </Grid>
            </CardBody>
        </Card>
    );
};

export default AllocationSummary;
