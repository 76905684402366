import React from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import styles from "./UserDetails.module.scss";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell.jsx";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum.js";
import ChangeUserRoleModal from "../../../HumanResources/Employee/Components/ChangeUserRoleModal.jsx";
import { useModal } from "../../../../hooks/useModal.js";

const UserDashboardPage = () => {
    const changeUserRoleModal = useModal();

    return (
        <div className={styles.UserDetailsContainer}>
            <ChangeUserRoleModal {...changeUserRoleModal} />
            <DataGrid
                uri={"/Authentication/User/GetAll?"}
                initialSort={[
                    {
                        field: "firstName",
                        dir: "asc",
                    },
                ]}
            >
                <GridColumn
                    width={250}
                    field="name"
                    title="Name:"
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`/Admin/Security/Users/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn
                    width={350}
                    field="primaryEmail"
                    title="Email:"
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            uri={`mailto:${props.dataItem.email}`}
                            text={props.dataItem.email}
                        />
                    )}
                />
                <GridColumn width={250} field="role.name" title="Role:" />
                <GridColumn
                    filterable={false}
                    sortable={false}
                    width={65}
                    cell={(props) => (
                        <ActionCell
                            onEdit={() =>
                                changeUserRoleModal.open(undefined, {
                                    userId: props.dataItem.id,
                                    ...props.dataItem,
                                })
                            }
                            permissions={{
                                edit: [PermissionsEnum.UpdateRole],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </div>
    );
};

export default UserDashboardPage;
