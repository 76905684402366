import React from "react";
import { Field } from "@progress/kendo-react-form";
import {
    FormDateInput,
    FormInput,
    FormRemoteDropDown,
} from "../../../../../../components/Deprecated/FormComponents";
import {
    dateFormatValidator,
    requiredValidator,
} from "../../../../../../resources/Deprecated/formValidators";

/**
 * @function EventModalStepOne
 * @description The first step of the event modal
 * @param {string} props.id - The id of the event
 * @param {object} props.formRenderProps - The FormRenderProps
 * @param {object} props.record - The record of the event
 * @param {function} props.setInitialStepState - The function to set the initial step state
 * @return {Element}
 * @constructor
 */
export const EventModalStepOne = (props) => {
    const { id, formRenderProps, record, setInitialStepState } = props;

    return (
        <>
            {!id && !record.eventType && (
                <Field
                    url={"/Customer/EventType/GetAll?"}
                    name={"eventType"}
                    component={FormRemoteDropDown}
                    label={"Event Type:"}
                    validator={requiredValidator}
                    onChange={(e) => {
                        setInitialStepState(e.value);

                        formRenderProps.onChange("eventDateTitle", {
                            value: e?.value?.name,
                        });
                    }}
                />
            )}
            <Field
                name={"eventDate"}
                component={FormDateInput}
                label={"Date:"}
                hint="MM/DD/YYYY"
                validator={dateFormatValidator}
            />
            <Field
                name={"eventDateTitle"}
                component={FormInput}
                label={"Title:"}
                validator={requiredValidator}
            />
            <Field
                name={"specification"}
                component={FormInput}
                label={"Specification:"}
            />
        </>
    );
};
