import React, { createContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { axiosInstance as axios } from "../../../../services/axiosInstance";
import { loginApiRequest } from "../../../../services/authconfig.js";
import { wait } from "../../../../services/Deprecated/authentication/userService.js";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const { accounts, instance } = useMsal();
    const [userLoaded, setUserLoaded] = useState(false);
    const [user, setUser] = useState(null);

    const getUserInfo = () => {
        if (accounts.length > 0) {
            setUserLoaded(false);
            axios
                .get("/Authentication/User/Authenticate")
                .then(async (response) => {
                    // Since axios automatically parses JSON, the data is available directly in the response
                    const data = response.data;
                    if (
                        response.headers["content-type"] &&
                        response.headers["content-type"].includes(
                            "application/json"
                        )
                    ) {
                        return data.data;
                    } else {
                        throw new Error("Not JSON response");
                    }
                })
                .then(async (data) => {
                    setUser(data);

                    if (data.mustReloadMsalToken) {
                        localStorage.removeItem("msal.account.keys");

                        await wait(10);
                        await instance.acquireTokenRedirect({
                            ...loginApiRequest,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user info:", error);
                    setUser(null);
                })
                .finally(() => {
                    setUserLoaded(true);
                });
        }
    };

    useEffect(() => {
        getUserInfo();
    }, [accounts]); // Trigger the effect when accounts change

    const values = {
        user,
        userLoaded,
    };

    return (
        <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;
