import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import RelationshipForm from "./EmployeeRelationshipForm";
import ContactInformationForm from "./EmployeeContactForm";
import BasicInfoForm from "./BasicInformationForm";
import dayjs from "dayjs";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { EmployeeContext } from "../../../../providers/Deprecated/HumanResources/Employee/EmployeeProvider";
import {
    getEmployee,
    updateEmployee,
} from "../../../../services/Deprecated/humanResources/employeeServices";
import ContactHelper from "../../../../resources/ContactHelper";
import { ContactType } from "../../../../resources/Enums/ContactType";
import ResponsiveDialog from "../../../../components/Deprecated/DialogWrapper";
import { ActionButton } from "../../../../components/Buttons/ActionButton.jsx";
import { ActionButtonContainer } from "../../../../components/Buttons/ActionButtonContainer.jsx";
import { LayoutOrientation } from "../../../../resources/Enums/LayoutOrientation.js";
import { FormButtons } from "../../../../components/Buttons/FormButtons.jsx";

const EmployeeEditComponent = ({
    visible,
    onCancel,
    record,
    loaderVisible,
    toggleLoaderVisible,
}) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const employeeContext = useContext(EmployeeContext);
    const relationships = employeeContext.relationships;
    const [employeeData, setEmployeeData] = useState(null);

    useEffect(() => {
        if (!visible || !record || !record.id) return;

        getEmployee(record.id).then((res) => {
            const employeeDataFromApi = res.data;
            employeeDataFromApi.dateOfBirth = employeeDataFromApi.dateOfBirth
                ? dayjs(employeeDataFromApi.dateOfBirth).format("MM/DD/YYYY")
                : null;

            if (employeeDataFromApi.supervisor) {
                employeeDataFromApi.supervisor.fullName =
                    employeeDataFromApi.supervisor.name;
            }

            if (employeeDataFromApi.locations) {
                employeeDataFromApi.locations =
                    employeeDataFromApi.locations.map((l) => ({
                        ...l,
                        id: l.locationId,
                    }));
                employeeDataFromApi.primaryLocation =
                    employeeDataFromApi.locations.find(
                        (l) => l.isPrimaryLocation
                    );
            }

            if (employeeDataFromApi.status && relationships.status) {
                employeeDataFromApi.status.selection =
                    relationships.status.find(
                        (s) => s.id === employeeDataFromApi.status.status
                    );
                employeeDataFromApi.status.hiredDate = dayjs(
                    employeeDataFromApi.status.hiredDate
                ).format("MM/DD/YYYY");
            }

            setEmployeeData(employeeDataFromApi);
        });
    }, [visible]);

    const [currentContext, setCurrentContext] = React.useState("Basic Info");

    const onContextSwitch = (context) => {
        setCurrentContext(context);
    };

    const submitEmployee = async (dataItem) => {
        toggleLoaderVisible(true);

        const data = {
            id: dataItem.id,
            firstName: dataItem.firstName,
            middleName: dataItem.middleName,
            lastName: dataItem.lastName,
            email: dataItem.email,
            hourlyRate: {
                amount: dataItem?.hourlyRate?.amount ?? 0,
                currency: "USD",
            },
            dateOfBirth: dayjs(dataItem.dateOfBirth).format("YYYY-MM-DD"),
            allowedToDrive: dataItem.allowedToDrive,
            isThirdShift: dataItem.isThirdShift,
            payrollId: dataItem.payrollId,
            identificationNumber: dataItem.identificationNumber,
            identificationExpiration: dataItem.identificationExpiration,
            identificationTypeId: dataItem.identificationType.id,
            phoneOne: dataItem.phoneOne,
            phoneTwo: dataItem.phoneTwo,
            phoneThree: dataItem.phoneThree,
            socialSecurityNumber: dataItem.socialSecurityNumber,
            address: dataItem.address,
            status: {
                ...dataItem.status,
                hiredDate: dayjs(dataItem.status.hiredDate).format(
                    "YYYY-MM-DD"
                ),
            },
            maritalStatusId: dataItem.maritalStatus.id,
            laborTypeId: dataItem.laborType.id,
            supervisorId: dataItem.supervisor?.id,
            locations: dataItem.locations.map((l) => ({
                locationId: l.id,
                isPrimaryLocation: l.id === dataItem.primaryLocation.id,
            })),
            contacts: dataItem.emergencyContact?.name1
                ? [
                      ContactHelper.build(
                          dataItem.emergencyContact,
                          ContactType.Enum.Emergency
                      ),
                  ]
                : [],
        };

        updateEmployee(data).then((res) => {
            if (res.success) {
                setEmployeeData(null);
                reloadDataContext.triggerReload();
                onCancel();
            }
            toggleLoaderVisible(false);
        });
    };

    return (
        <div>
            {visible && employeeData && (
                <Form
                    initialValues={employeeData}
                    onSubmit={submitEmployee}
                    render={(formRenderProps) => (
                        <ResponsiveDialog
                            title={"Edit Employee: " + record.firstName}
                            onClose={onCancel}
                            size={"large"}
                        >
                            <ActionButtonContainer
                                orientation={LayoutOrientation.Enum.Horizontal}
                            >
                                <ActionButton
                                    themeColor={
                                        currentContext === "Basic Info"
                                            ? "info"
                                            : "primary"
                                    }
                                    onClick={() =>
                                        onContextSwitch("Basic Info")
                                    }
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Basic Info
                                </ActionButton>
                                <ActionButton
                                    themeColor={
                                        currentContext === "Details"
                                            ? "info"
                                            : "primary"
                                    }
                                    onClick={() => onContextSwitch("Details")}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Details
                                </ActionButton>
                                <ActionButton
                                    themeColor={
                                        currentContext === "Contact"
                                            ? "info"
                                            : "primary"
                                    }
                                    onClick={() => onContextSwitch("Contact")}
                                    style={{ width: "100px", margin: "5px" }}
                                >
                                    Contact
                                </ActionButton>
                            </ActionButtonContainer>
                            <FormElement>
                                {currentContext === "Basic Info" && (
                                    <BasicInfoForm
                                        formRenderProps={formRenderProps}
                                        record={record}
                                    />
                                )}
                                {currentContext === "Details" && (
                                    <RelationshipForm
                                        formRenderProps={formRenderProps}
                                        editMode={true}
                                    />
                                )}
                                {currentContext === "Contact" && (
                                    <ContactInformationForm
                                        formRenderProps={formRenderProps}
                                    />
                                )}
                                <FormButtons
                                    loaderVisible={loaderVisible}
                                    actionOnCancel={onCancel}
                                    allowSubmit={formRenderProps.allowSubmit}
                                    isCreate={false}
                                />
                            </FormElement>
                        </ResponsiveDialog>
                    )}
                />
            )}
        </div>
    );
};
export default EmployeeEditComponent;
