import React from "react";
import { ADPPayrollGrid } from "./PayrollGrids/ADPPayrollGrid";
import { PayrollTypeEnum } from "../../../resources/Enums/PayrollTypeEnum.js";
import { PayChexPayrollGrid } from "./PayrollGrids/PayChexPayrollGrid.jsx";

const Grids = {
    ADP: (data) => <ADPPayrollGrid data={data} />,
    PayChex: (data) => <PayChexPayrollGrid data={data} />,
};

export const PayrollContainer = (props) => {
    const { data, gridType } = props;

    return <div>{Grids[PayrollTypeEnum.ParseToString(gridType)](data)}</div>;
};
