import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";

export const PayChexPayrollGrid = (props) => {
    const { data } = props;

    return (
        <Grid data={data} style={{ maxWidth: 970 }}>
            <GridColumn
                field={"fullName"}
                title={"Employee"}
                width={200}
                cell={(props) => (
                    <LinkCell
                        {...props}
                        uri={`/HumanResources/Employee/${props.dataItem.id}`}
                    />
                )}
            />
            <GridColumn field="companyId" title="Company ID" width={125} />
            <GridColumn field="workerId" title="Worker ID" width={125} />
            <GridColumn
                field="payComponent"
                title="Pay Component"
                width={125}
            />
            <GridColumn field="hours" title="Hours" width={125} />
            <GridColumn field="rate" title="Rate" width={125} />
            <GridColumn field="rateNumber" title="Rate#" width={125} />
        </Grid>
    );
};
