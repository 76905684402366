import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { VendorDocumentType } from "../../../../../../resources/Enums/VendorDocumentType.js";
import VendorDocumentService from "../../../../../../services/VendorDocumentService.js";
import { VendorDocumentModalHelper } from "../Helpers/VendorDocumentModalHelper.js";
import { AuthContext } from "../../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { ReloadDataContext } from "../../../../../../providers/ReloadDataProvider.jsx";
import { ConfirmationDialog } from "../../../../../../components/Dialogs/ConfirmationDialog.jsx";

export const VendorDocumentModalContext = createContext(null);

const CONFIRMATION_INITIAL_STATE = {
    confirmationButtonText: "Yes",
    confirmationButtonThemeColor: "error",
    cancelButtonText: "No",
    visible: false,
    msg: "",
    action: () => {},
};

const VendorDocumentModalProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const [reloadItemGridKey, setReloadItemGridKey] = useState(Math.random());
    const [reloadDocumentKey, setReloadDocumentKey] = useState(Math.random());
    const [formData, setFormData] = useState(undefined);
    const lineItems = useRef([]);
    const [canEdit, setCanEdit] = useState(false);
    const [canReceive, setCanReceive] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [submitLoaderVisible, setSubmitLoaderVisible] = useState(false);
    const closeAfterSubmit = useRef(false);
    const service = useRef(new VendorDocumentService());
    const pdfContainerRef = useRef(null);
    const [modalState, setModalState] = useState({});
    const hasPendingChanges = useRef(false);
    const dataHasInitialized = useRef(false);
    const skipFirstSaveChangesCheckRef = useRef(false);
    const [confirmationDialog, setConfirmationDialog] = useState(
        CONFIRMATION_INITIAL_STATE
    );

    const loadVendorDocument = () => {
        if (modalState.id) {
            service.current.get(modalState.id).then((res) => {
                const data = VendorDocumentModalHelper.formatDataForModal(
                    res.data
                );
                lineItems.current = data.lineItems;
                delete data.lineItems;

                setFormData(data);
                dataHasInitialized.current = true;
                setEditableStatuses(data);

                reloadItemGrid();

                // If the ID has changed, we have switched documents by changing the type
                if (formData?.id && formData.id !== data.id) {
                    skipFirstSaveChangesCheckRef.current = true;
                }
            });
        } else {
            const modalData = VendorDocumentModalHelper.formatDataForModal(
                modalState.record
            );
            lineItems.current = modalData.lineItems;
            delete modalData.lineItems;

            modalData.orderedBy = user.activeEmployee;
            setFormData(modalData);
            dataHasInitialized.current = true;
            reloadItemGrid();
            setEditableStatuses(modalData);
        }
    };

    const reloadVendorDocumentFromApi = () => {
        loadVendorDocument();
    };

    // Initialize FormData and LineItems
    useEffect(() => {
        if (!modalState.visible) return;
        loadVendorDocument();
    }, [modalState.visible, modalState.id]);

    useEffect(() => {
        if (!dataHasInitialized.current) return;
        setEditableStatuses(formData);
        if (!canEdit) return;
        if (skipFirstSaveChangesCheckRef.current) {
            skipFirstSaveChangesCheckRef.current = false;
            return;
        }

        // Handles the determination if there are pending changes to the Document
        hasPendingChanges.current = true;
    }, [reloadDocumentKey, reloadItemGridKey]);

    const setEditableStatuses = (data) => {
        const hasChildren = data.childVendorDocuments?.length > 0;
        const hasReceivedItems = lineItems?.current?.some(
            (i) => i.quantityReceived > 0
        );
        const hasPayments = data?.totalPaid?.amount > 0;

        setCanEdit(
            (data.type === VendorDocumentType.Enum.PurchaseOrder &&
                !hasChildren &&
                !data.confirmedBy) ||
                data.type === VendorDocumentType.Enum.VendorBill
        );
        setCanReceive(
            data.type === VendorDocumentType.Enum.ItemReceipt && !hasChildren
        );

        switch (data.type) {
            case VendorDocumentType.Enum.PurchaseOrder:
                setCanDelete(
                    !hasChildren && modalState?.id && !data?.confirmedBy
                );
                break;
            case VendorDocumentType.Enum.ItemReceipt:
                setCanDelete(
                    !hasChildren && modalState?.id && !hasReceivedItems
                );
                break;
            case VendorDocumentType.Enum.VendorBill:
                setCanDelete(modalState?.id && !hasPayments);
                break;
        }
    };

    const onModalClose = () => {
        if (hasPendingChanges.current && !closeAfterSubmit.current) {
            setConfirmationDialog({
                visible: true,
                confirmationButtonText: "Yes",
                confirmationButtonThemeColor: "error",
                cancelButtonText: "No",
                msg: "You have unsaved changes, do you wish to close?",
                action: () => {
                    resetModalState();
                    modalState.close();
                },
            });
            return;
        }

        hasPendingChanges.current = false;
        dataHasInitialized.current = false;
        setFormData(undefined);
        setSubmitLoaderVisible(false);
        lineItems.current = [];
        closeAfterSubmit.current = false;
        modalState.close();
    };

    const resetModalState = () => {
        setConfirmationDialog(CONFIRMATION_INITIAL_STATE);
        hasPendingChanges.current = false;
        dataHasInitialized.current = false;
        setFormData(undefined);
        setSubmitLoaderVisible(false);
        lineItems.current = [];
        closeAfterSubmit.current = false;
    };

    const onModalSubmit = (e) => {
        setSubmitLoaderVisible(true);

        const values = VendorDocumentModalHelper.formatDataForNetwork({
            ...e.values,
            id: modalState.id,
            lineItems: lineItems.current,
        });

        const request = modalState.id
            ? service.current.update(values)
            : service.current.create(values);
        request.then((res) => {
            triggerReload();
            setSubmitLoaderVisible(false);

            if (closeAfterSubmit.current) {
                onModalClose();
            } else {
                if (!modalState.id) {
                    setModalState({
                        ...modalState,
                        id: res.data.id,
                    });
                }
            }
        });
    };

    const onDelete = () => {
        hasPendingChanges.current = false;
        setConfirmationDialog({
            visible: true,
            confirmationButtonText: "Yes",
            confirmationButtonThemeColor: "error",
            cancelButtonText: "No",
            msg: "Are you sure you want to delete? Has the Vendor been informed of cancellation?",
            action: () => {
                service.current.delete(modalState.id).then((res) => {
                    triggerReload();

                    if (res.success) {
                        onModalClose();
                    }
                });
            },
        });
    };

    const onChangeStatus = (status, switchDocument = true) => {
        closeAfterSubmit.current = false;
        onModalSubmit({
            values: formData,
        });

        service.current.changeStatus(modalState.id, status).then((res) => {
            if (res.success) {
                triggerReload();

                if (switchDocument) {
                    setModalState({
                        ...modalState,
                        visible: true,
                        id: res.data.id,
                    });
                }
                setSubmitLoaderVisible(false);
            }
        });
    };

    // Reloads the item grid
    const reloadItemGrid = () => {
        setReloadItemGridKey(Math.random());
    };

    // Reloads the document information
    const reloadDocumentInformation = () => {
        setReloadDocumentKey(Math.random());
    };

    return (
        <VendorDocumentModalContext.Provider
            value={{
                reloadItemGridKey,
                reloadItemGrid,
                reloadDocumentKey,
                reloadDocumentInformation,
                reloadVendorDocumentFromApi,
                formData,
                setFormData,
                canEdit,
                canReceive,
                canDelete,
                lineItems,
                setModalState,
                modalState,
                onModalClose,
                onModalSubmit,
                onDelete,
                submitLoaderVisible,
                closeAfterSubmit,
                onChangeStatus,
                pdfContainerRef,
                hasPendingChanges,
                setConfirmationDialog,
            }}
        >
            <ConfirmationDialog
                visible={confirmationDialog.visible}
                actionOnConfirm={() => {
                    confirmationDialog.action();
                    setConfirmationDialog({
                        ...confirmationDialog,
                        visible: false,
                    });
                    setSubmitLoaderVisible(false);
                }}
                actionOnCancel={() => {
                    setConfirmationDialog({
                        ...confirmationDialog,
                        visible: false,
                    });
                    if (confirmationDialog.actionOnCancel instanceof Function) {
                        confirmationDialog.actionOnCancel();
                    }
                    setSubmitLoaderVisible(false);
                }}
                confirmationText={confirmationDialog.msg}
                confirmationButtonText={
                    confirmationDialog.confirmationButtonText
                }
                confirmationButtonThemeColor={
                    confirmationDialog.confirmationButtonThemeColor
                }
                cancelButtonText={confirmationDialog.cancelButtonText}
                hideCancelButton={confirmationDialog.hideCancelButton}
                cancelButtonThemeColor={"primary"}
            />
            {children}
        </VendorDocumentModalContext.Provider>
    );
};

export default VendorDocumentModalProvider;
