import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";
import dayjs from "dayjs";

export default class PayrollService {
    /**
     * @function setPayrollInterval
     * @description Set the payroll interval
     * @async
     * @param data {object} - Payroll interval data
     * @param data.interval {string} - Payroll interval
     * @param data.startDayOfWeek {string} - Start day of week
     * @param data.startingDate {string} - Start pay period
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async setPayrollInterval(data) {
        const response = await axios.post(
            "/HumanResources/Payroll/SetInterval",
            data
        );
        return formatServiceResponse(response);
    }

    /**
     * @function getPayrollInterval
     * @description Get the payroll interval
     * @async
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getPayrollInterval() {
        const response = await axios.get(
            "/HumanResources/Payroll/GetInterval",
            {
                validateStatus: (status) =>
                    (status >= 200 && status < 300) || status === 404,
            }
        );
        return formatServiceResponse(response);
    }

    /**
     * @function getIntervalDates
     * @description Get the Interval Dates for Payroll
     * @async
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getIntervalDates() {
        const response = await axios.get(
            "/HumanResources/Payroll/GetIntervalDates",
            {
                validateStatus: (status) =>
                    (status >= 200 && status < 300) || status === 404,
            }
        );
        return formatServiceResponse(response);
    }

    /**
     * Generate payroll report
     * @param type {PayrollTypeEnum} - Payroll type
     * @param start {Date} - Start date
     * @param end {Date} - End date
     * @return {Promise<{success: boolean, data: {object}, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async generatePayroll(type, start, end) {
        const data = {
            type: type,
            timePeriod: {
                start: start,
                end: end,
            },
        };

        try {
            const response = await axios.post(
                "/Reporting/Payroll/GeneratePayroll",
                data
            );
            response.data.data.serializedRows =
                response.data.data.serializedRows.map((item) =>
                    JSON.parse(item)
                );

            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Generate payroll CSV
     * @param type {string} - Payroll type
     * @param start {Date} - Start date
     * @param end {Date} - End date
     * @return {Promise<{success: boolean, data: {object}, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async exportPayroll(type, start, end) {
        const data = {
            type: type,
            timePeriod: {
                start: start,
                end: end,
            },
        };

        const result = await axios.post(
            "/Reporting/Payroll/ExportPayrollCSV",
            data
        );

        //? Create "Phantom" link to have the user auto-download the exported CSV from server
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(
            new Blob([result.data], { type: "text/csv" })
        );
        link.download = `${type}Payroll_${dayjs()
            .utc()
            .format("MM-DD-YYYY")}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
