import { Card, CardBody } from "@progress/kendo-react-layout";
import React from "react";
import {
    fixedDecimal,
    reduceString,
} from "../../../../resources/Deprecated/stringFormatter";

const BaseInformationDisplay = ({ formRenderProps }) => {
    const stockOrSupplyItem = formRenderProps.valueGetter("isStockOrSupplyItem")
        ? "Yes"
        : "No";
    const isActive = formRenderProps.valueGetter("isActive") ? "Yes" : "No";

    return (
        <Card className="card-container">
            <CardBody
                style={{
                    display: "flex",
                    justifyContent: "left",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    padding: "0px",
                    gap: "25px",
                }}
            >
                <span
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "50%",
                    }}
                >
                    {formRenderProps.valueGetter("name") && (
                        <span
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <strong>Base Name:</strong>{" "}
                            {reduceString(formRenderProps.valueGetter("name"), 20)}
                        </span>
                    )}
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Item Type:</strong>{" "}
                        {reduceString(
                            formRenderProps.valueGetter("itemType.name"),
                            20
                        )}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Ordering UOM:</strong>{" "}
                        {reduceString(
                            formRenderProps.valueGetter("orderingUom.name"),
                            20
                        )}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Retail UOM:</strong>{" "}
                        {reduceString(
                            formRenderProps.valueGetter("retailUom.name"),
                            20
                        )}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Retail Price:</strong> $
                        {fixedDecimal(
                            formRenderProps.valueGetter("retailPrice"),
                            2
                        )}
                    </span>
                </span>
                <span
                    style={{
                        display: "flex",
                        justifyContent: "left",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "50%",
                    }}
                >
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Is Active:</strong> {isActive}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Stock/Supply Item:</strong> {stockOrSupplyItem}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <strong>Vendor:</strong>{" "}
                        {reduceString(
                            formRenderProps.valueGetter("vendor.name"),
                            20
                        )}
                    </span>
                </span>
            </CardBody>
        </Card>
    );
};

export default BaseInformationDisplay;
