import React, { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../../../../services/Deprecated/authentication/userService";
import { useToggle } from "../../../../hooks/Deprecated/useToggle";
import { ReloadDataContext } from "../../../ReloadDataProvider.jsx";

export const UserContext = createContext(null);

//? This is separate from the AuthProvider.jsx as it serves any given active user page, such as
//? the Admin user details page. The auth provider should be specifically for the _logged in_ user
const UserProvider = ({ children }) => {
    const reloadDataContext = useContext(ReloadDataContext);
    const { bool: relationshipsLoading, toggle: toggleRelationshipsLoading } =
        useToggle(true);
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState(/** @type {User} */ null);

    useEffect(() => {
        toggleRelationshipsLoading();
    }, []);

    useEffect(() => {
        if (!userId) return;

        getUser(userId).then((serviceResponse) => {
            setUser(serviceResponse.data);
        });
    }, [userId, reloadDataContext.reloadData]);

    const values = {
        user,
        userId,
        setUserId,
        relationshipsLoading,
    };

    return (
        <UserContext.Provider value={values}>{children}</UserContext.Provider>
    );
};

export default UserProvider;
