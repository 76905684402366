import React from "react";
import { TabStripTab } from "@progress/kendo-react-layout";
import UserDashboardPage from "../UserDashboardPage.jsx";
import PendingUserDashboardPage from "../PendingUserDashboardPage.jsx";
import { HighlightedTabStrip } from "../../../../../components/HighlightedTabStrip/HighlightedTabStrip.jsx";

const UserDashboardTabs = () => {
    return (
        <HighlightedTabStrip>
            <TabStripTab title="Active">
                <UserDashboardPage />
            </TabStripTab>
            <TabStripTab title="Pending">
                <PendingUserDashboardPage />
            </TabStripTab>
        </HighlightedTabStrip>
    );
};

export default UserDashboardTabs;
