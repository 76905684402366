import dayjs from "dayjs";

export class EventModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForNetwork(dataItem) {
        return {
            id: dataItem.id,
            eventTypeId: dataItem.eventType?.id,
            specification: dataItem.specification,
            eventDateTitle: dataItem.eventDateTitle,
            status: dataItem.status,
            eventDate:
                dataItem.eventDate &&
                dayjs(dataItem.eventDate).format("YYYY-MM-DD"),
            customerId: dataItem?.customer?.id,
            jobId: dataItem?.job?.id,
            jobDocumentId: dataItem?.jobDocument?.id,
        };
    }

    /**
     * @function formatDataForModal
     * @description Format data for the event modal
     * @param {Object} dataItem - The data item
     * @return {Object}
     */
    static formatDataForModal(dataItem) {
        return {
            ...dataItem,
            eventDate:
                dataItem.eventDate &&
                dayjs(dataItem.eventDate).format("MM/DD/YYYY"),
            jobDocument: dataItem.jobDocument && {
                ...dataItem.jobDocument,
                combinedName: `${dataItem.jobDocument.name}`,
            },
        };
    }

    /**
     * @function formatStepState
     * @description Format step state for the event modal
     * @param {Object} stepState - The step state object
     * @param {Boolean} isValid - The validation status
     * @return {Object}
     */
    static formatStepState = (stepState, isValid) => {
        const currentStep = stepState.currentStep + 1;

        return {
            ...stepState,
            steps: stepState.steps.map((currentStep, index) => ({
                ...currentStep,
                isValid:
                    index === stepState.currentStep
                        ? isValid
                        : currentStep.isValid,
            })),
            isLastStep: stepState.steps.length - 1 === currentStep,
            currentStep: currentStep,
        };
    };
}
