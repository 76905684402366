import { BaseEnum } from "./BaseEnum";

export class InventoryType extends BaseEnum {
    static Enum = {
        Available: 0,
        Allocated: 1,
        Partitioned: 2,
        Used: 3,
    };
}
