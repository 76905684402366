import React from "react";
import { BaseCell } from "./BaseCell";

/**
 * @function EnumCell
 * @description
 * A cell component that renders an enumerable value as a string.
 * This component cannot be used with the "lock" prop on the GridColumn component.
 * @param {Object} props
 * @param {Object} props.enumerable - The enumerable object
 * @return {React.JSX.Element}
 * @constructor
 */
export const EnumCell = (props) => {
    const { dataItem, field, tdProps, enumerable } = props;

    const splitField = field.split(".");
    let enumField = dataItem;

    for (let i = 0; i < splitField.length; i++) {
        enumField = enumField[splitField[i]];
    }

    return (
        <BaseCell {...tdProps}>
            {enumerable
                .ParseToString(enumField)
                .replace(/([A-Z])/g, " $1")
                .trim()}
        </BaseCell>
    );
};
