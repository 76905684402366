// Import axios library
import { axiosInstance as axios } from "../../axiosInstance";
import dayjs from "dayjs";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

/**
 * Add Employee.
 * @function addEmployee
 * @async
 * @param {Object} data
 * @returns {Promise<ServiceResponse>}
 */
export const addEmployee = async (data) => {
    const response = await axios.post("/HumanResources/Employee/Create", data);
    return formatServiceResponse(response);
};

/**
 * Finds the Employee by the provided query.
 * @function findEmployee
 * @async
 * @param {string} query
 * @returns {Promise<ServiceResponse>}
 */
export const findEmployee = async (query) => {
    return await axios
        .post(
            `/HumanResources/Employee/Find?SearchParam=${query}`,
            {},
            {
                validateStatus: (status) => {
                    return (status >= 200 && status < 300) || status === 404;
                },
            }
        )
        .then((response) => {
            return formatServiceResponse(response);
        });
};

/**
 * Gets the Employees available tasks. Further filtered by JobId and/or CalendarEventId
 * @function getCurrentAvailableTasks
 * @async
 * @param {string} request.employeeId - The Employee ID
 * @param {string} request.jobId - The Job ID
 * @param {string} request.calendarEventId - The CalendarEvent ID
 * @param {object} request.timePeriod - The TimePeriod object
 * @returns {Promise<ServiceResponse>}
 */
export const getCurrentAvailableTasks = async (request) => {
    return await axios
        .post("/HumanResources/Employee/Task/GetAvailableTasks?", request)
        .then((response) => {
            return formatServiceResponse(response);
        });
};

/**
 * Search for an employee based on shared query param.
 * @function searchEmployee
 * @param {string} searchQuery
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const searchEmployee = async (searchQuery) => {
    try {
        const filter = {
            logic: "or",
            filters: [
                {
                    field: "firstName",
                    operator: "contains",
                    value: searchQuery,
                },
                {
                    field: "lastName",
                    operator: "contains",
                    value: searchQuery,
                },
                {
                    field: "email",
                    operator: "contains",
                    value: searchQuery,
                },
            ],
        };

        const kendoRequest = {
            page: 1,
            pageSize: 25,
            filter: filter,
        };

        //? no idea if this works lol, i'll come back to it in testing.

        const response = await axios.post(
            `/HumanResources/Employee/Find?SearchQuery=${searchQuery}`,
            {
                params: {
                    $filter: JSON.stringify(kendoRequest),
                },
            }
        );

        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Update an employee.
 * @function updateEmployee
 * @param {Object} data
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const updateEmployee = async (data) => {
    const response = await axios.put("/HumanResources/Employee/Update", data);
    return formatServiceResponse(response);
};

/**
 * Delete an employee.
 * @function deleteEmployee
 * @param {string} employeeId
 * @param {function} onSuccess
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const deleteEmployee = async (employeeId, onSuccess) => {
    try {
        // Send a DELETE request to the server with the employee ID
        const response = await axios.delete(
            `/HumanResources/Employee/Delete?id=${employeeId}`
        );
        console.log("delete response", response.data);

        // If the request is successful, call the onSuccess callback
        if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
        }
        // Return the response data
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Retrieves all employees.
 * @function getAllEmployees
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getAllEmployees = async () => {
    try {
        const response = await axios.get(
            `/HumanResources/Employee/GetAll?filter=(isActive~eq~'true')&`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Retrieves employee based on ID.
 * @function getEmployee
 * @param {string} employeeId
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getEmployee = async (employeeId) => {
    try {
        const response = await axios.get(
            `/HumanResources/Employee/Get?id=${employeeId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Retrieves employee's time clock entries.
 * @function getTimeClockEntriesByEmployee
 * @param {string} employeeId
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getTimeClockEntriesByEmployee = async (employeeId) => {
    try {
        //? may need to do the more involved kendo querying
        const response = await axios.get(
            `/HumanResources/TimeClockEntry/GetAll?employeeId=${employeeId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Gets time clock entries for employee based on date range of UTC ISO strings.
 * @function getDateRangeOfTimeClocksForEmployee
 * @param {string} employeeId employeeId
 * @param {string} startDate UTC ISO DateTime string
 * @param {string} endDate UTC ISO DateTime string
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getDateRangeOfTimeClocksForEmployee = async (
    employeeId,
    startDate,
    endDate
) => {
    try {
        let queryParams = new URLSearchParams({
            employeeId,
            startDate,
            endDate,
        }).toString();

        const response = await axios.get(
            `/HumanResources/TimeClockEntry/GetAll?${queryParams}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

const generateDateRange = (startDate, endDate) => {
    let dates = [];
    let currentDate = dayjs(startDate);

    while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
    ) {
        dates.push(currentDate.toDate());
        currentDate = currentDate.add(1, "day");
    }

    return dates;
};

export const prepareDataForGrid = (timeClockEntries, startDate, endDate) => {
    let dataForGrid = generateDateRange(startDate, endDate).map((date) => ({
        date,
        details: [],
        earliestClockIn: null,
        latestClockOut: null,
        totalClockedTime: 0, // in milliseconds
        humanReadableTotalTimespan: "",
        humanReadableClockedTime: "",
    }));

    // Populate the details for each day
    timeClockEntries.forEach((entry) => {
        const entryDate = dayjs(entry.startTime).format("YYYY-MM-DD");
        let dayEntry = dataForGrid.find(
            (d) => dayjs(d.date).format("YYYY-MM-DD") === entryDate
        );

        if (dayEntry) {
            dayEntry.details.push(entry);
            // Update dayEntry properties based on entry
            updateDayEntry(dayEntry, entry);
        }
    });

    // Finalize calculations for each day entry
    dataForGrid.forEach((dayEntry) => {
        finalizeDayCalculations(dayEntry);
    });

    return dataForGrid;
};

const updateDayEntry = (dayEntry, entry) => {
    const entryStart = dayjs.utc(entry.startTime);
    const entryEnd = dayjs.utc(entry.endTime);

    if (
        !dayEntry.earliestClockIn ||
        entryStart.isBefore(dayEntry.earliestClockIn)
    ) {
        dayEntry.earliestClockIn = entryStart;
    }

    if (!dayEntry.latestClockOut || entryEnd.isAfter(dayEntry.latestClockOut)) {
        dayEntry.latestClockOut = entryEnd;
    }
};

const finalizeDayCalculations = (dayEntry) => {
    if (dayEntry.earliestClockIn && dayEntry.latestClockOut) {
        const timespan = dayEntry.latestClockOut.diff(dayEntry.earliestClockIn);
        dayEntry.humanReadableTotalTimespan = formatDuration(timespan);
    }
};

const formatDuration = (durationInMilliseconds) => {
    let seconds = Math.floor(durationInMilliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    minutes = minutes % 60;
    seconds = seconds % 60;

    let formattedDuration = "";
    if (hours > 0) {
        formattedDuration += `${hours}h `;
    }
    if (minutes > 0) {
        formattedDuration += `${minutes}m `;
    }
    if (seconds > 0) {
        formattedDuration += `${seconds}s`;
    }

    return formattedDuration.trim();
};

/**
 * Gets all employees that can perform a given set of task IDs.
 * @function getAllEmployeesForTasks
 * @param {string[]} taskIds
 * @async
 * @returns {Promise<ServiceResponse>}
 */
export const getAllEmployeesForTasks = async (taskIds) => {
    try {
        const response = await axios.post(
            "/HumanResources/Employee/Task/GetEmployeesForTasks",
            { taskIds: taskIds }
        );
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Assigns employees to a specific task, allowing them to perform it.
 * @function setEmployeesForTask
 * @param {string} taskId - The ID of the task to which the employees will be assigned.
 * @param {string[]} allowedEmployeeIds - An array of employee IDs who are allowed to perform the task.
 * @async
 * @returns {Promise<ServiceResponse>} - The service response containing the result of the API call.
 */
export const setEmployeesForTask = async (taskId, allowedEmployeeIds) => {
    try {
        const response = await axios.post(
            "/HumanResources/Employee/Task/SetEmployeesForTask",
            {
                taskId,
                allowedEmployeeIds,
            }
        );
        console.log(response);
        return formatServiceResponse(response);
    } catch (error) {
        console.error("Axios Request Failed", error);
        return formatServiceResponse(error);
    }
};
