import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { VendorDocumentModalContext } from "../../Providers/VendorDocumentModalProvider.jsx";
import { AuthContext } from "../../../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import VendorDocumentService from "../../../../../../../services/VendorDocumentService.js";
import ResponsiveDialog from "../../../../../../../components/Deprecated/DialogWrapper.jsx";
import { FormButtons } from "../../../../../../../components/Buttons/FormButtons.jsx";
import { EachReceival } from "./Components/EachReceival.jsx";
import { SqftReceival } from "./Components/SqftReceival.jsx";
import { DocumentLineItemContext } from "../../../../../../../providers/DocumentLineItemProvider.jsx";

export const ReceivalModal = (props) => {
    const { close, visible, record } = props;
    const { lineItems, reloadItemGrid, reloadVendorDocumentFromApi } =
        useContext(VendorDocumentModalContext);
    const { user } = useContext(AuthContext);
    const service = useRef(new VendorDocumentService());
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [initialFormData, setInitialFormData] = useState(undefined);
    const isReceiving = record?.record?.quantityReceived >= record?.prevValue;

    useEffect(() => {
        if (visible) {
            setInitialFormData({
                currentReceived:
                    record.record.quantityReceived - record.prevValue,
                previousReceived: record.prevValue,
                item: record.record.item,
                lineItemId: record.record.id,
                uom: record.record.item.orderingUom,
            });
        }
    }, [visible]);

    const onClose = () => {
        lineItems.current = lineItems.current.map((item) => {
            if (item.id === record.record.id) {
                item.quantityReceived = record.prevValue;
            }
            return item;
        });

        reloadItemGrid();
        resetState();
        close();
    };

    const resetState = () => {
        setInitialFormData(undefined);
        setLoaderVisible(false);
    };

    const onSubmit = (e) => {
        setLoaderVisible(true);

        const values = e.values;
        let data = [];
        let isValid = true;

        if (values.items) {
            data = Object.values(values.items).map((item) => {
                if (isReceiving && !item?.storageLocation?.id) isValid = false;

                return {
                    storageLocationId: item?.storageLocation?.id,
                    lineItemId: initialFormData.lineItemId,
                    quantity: 1,
                    width: item.width,
                    length: item.length,
                    identifier: item.identifier,
                };
            });
        } else {
            data.push({
                storageLocationId: values?.storageLocation?.id,
                lineItemId: initialFormData.lineItemId,
                quantity: Math.abs(initialFormData.currentReceived),
            });

            if (isReceiving && !data[0].storageLocationId) isValid = false;
        }

        if (!isValid) {
            setLoaderVisible(false);
            return;
        }

        const request = isReceiving
            ? service.current.receive({ receivingItems: data })
            : service.current.unReceive(data[0]);

        request.then((res) => {
            if (!res || !res.success) {
                lineItems.current = lineItems.current.map((item) => {
                    if (item.id === data[0].lineItemId) {
                        item.quantityReceived = record.prevValue;
                    }
                    return item;
                });
                reloadItemGrid();
                resetState();
                close();
                return;
            }

            lineItems.current = lineItems.current.map((item) => {
                if (item.id === data.lineItemId && !item.receiver) {
                    const names = user.name.split(" ");
                    item.receiver = names[0][0] + names[1][0];
                }
                return item;
            });

            resetState();
            reloadVendorDocumentFromApi();
            close();
        });
    };

    const renderFormFields = (props) => {
        const { formRenderProps } = props;

        switch (record.record.item.retailUom.name) {
            case "Sqft":
                return isReceiving ? (
                    <SqftReceival
                        record={initialFormData}
                        formRenderProps={formRenderProps}
                    />
                ) : (
                    <EachReceival record={initialFormData} />
                );
            case "Each":
                return <EachReceival record={initialFormData} />;
            default:
                return <EachReceival record={initialFormData} />;
        }
    };

    return (
        visible &&
        initialFormData && (
            <ResponsiveDialog
                title={"Receive"}
                onClose={onClose}
                size={"medium"}
            >
                <Form
                    initialValues={initialFormData}
                    onSubmitClick={onSubmit}
                    render={(formRenderProps) => (
                        <FormElement>
                            {renderFormFields({ formRenderProps })}
                            <FormButtons
                                loaderVisible={loaderVisible}
                                actionOnCancel={onClose}
                                allowSubmit={true}
                                isCreate={false}
                                text={"Yes"}
                            />
                        </FormElement>
                    )}
                />
            </ResponsiveDialog>
        )
    );
};
