import { NewItemTypeEnum } from "./Enums/ItemTypeEnum.js";
import { ItemAllocationHelper } from "../pages/Customer/Jobs/Components/Tabs/ItemAllocation/Helpers/ItemAllocationHelper.js";

export default class LineItemHelper {
    static applyDiscounts(lineItems, total) {
        const discounts = this.fetchDiscounts(lineItems);
        const items = this.fetchItems(lineItems);
        const currentTotal = total ?? this.calculateTotalOfItems(items);
        const percentages = this.recalculateDiscounts(discounts, currentTotal);

        return lineItems.flatMap((item) => {
            if (item.children && item.children.length > 0) {
                return {
                    ...item,
                    children: this.applyDiscounts(
                        item.children,
                        item.total.amount
                    ),
                };
            }

            if (item.type === NewItemTypeEnum.Enum.PercentageDiscount) {
                return percentages.find((p) => p.id === item.id);
            }

            return item;
        });
    }

    static isDiscountLineItem(item) {
        if (!item) return false;

        return (
            item.type === NewItemTypeEnum.Enum.CashDiscount ||
            item.type === NewItemTypeEnum.Enum.PercentageDiscount
        );
    }

    static isNoteLineItem(item) {
        return item.type === NewItemTypeEnum.Enum.Note;
    }

    static calculateTotalOfItems(lineItems) {
        if (!lineItems.length) return 0;

        return lineItems.reduce((acc, item) => {
            if (this.isDiscountLineItem(item)) return acc - item.total?.amount;
            return acc + item.total?.amount ?? 0;
        }, 0);
    }

    static recalculateDiscounts(discounts, total) {
        if (!discounts.length) return [];
        let runningTotal = total;
        return discounts.map((discount) => {
            if (discount.type === NewItemTypeEnum.Enum.CashDiscount) {
                runningTotal -= discount.total.amount;
                return discount;
            }

            const discountTotal = runningTotal * (discount.quantity / 100);
            runningTotal -= discountTotal;
            return {
                ...discount,
                total: {
                    amount: discountTotal,
                    currency: "USD",
                },
                price: {
                    amount: discountTotal / discount.quantity,
                    currency: "USD",
                },
            };
        });
    }

    static fetchDiscounts(lineItems) {
        return lineItems.filter((item) => this.isDiscountLineItem(item));
    }

    static fetchItems(lineItems) {
        return lineItems.filter((item) => !this.isDiscountLineItem(item));
    }

    static getLineItemTotal(lineItem) {
        if (lineItem.item?.retailUom?.name === "Sqft") {
            const defaultSqft = Math.max(
                ItemAllocationHelper.getItemDefaultSqft(lineItem.item),
                0
            );
            return lineItem.price.amount * defaultSqft * lineItem.quantity;
        } else {
            return lineItem.price.amount * lineItem.quantity;
        }
    }
}
