import * as React from "react";
import { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { BaseCell } from "./BaseCell";

/**
 * @function EditableDropDownCell
 * @description A cell that allows for editing via a drop-down list.
 * @param props.data {Array} - The data to populate the drop-down list
 * @param props.field {string} - The field to edit
 * @param props.dataItem {object} - The data item to edit
 * @param props.textField {string} - The field to display in the drop-down list
 * @param props.editField {string} - The field to watch for editing
 * @param props.onChange {function} - The function to call when the value changes
 * @return {Element}
 * @constructor
 */
export const EditableDropDownCell = (props) => {
    const {
        data,
        field = "",
        dataItem,
        textField,
        dataItemKey,
        editField,
        onChange,
        disabled,
        ...others
    } = props;
    const [dropDownValue, setDropDownValue] = useState(dataItem[field]);
    const [dropDownData, setDropDownData] = useState([]);

    useEffect(() => {
        setDropDownValue(dataItem[field]);
        setDropDownData(data.filter((item) => item.id !== dataItem[field]?.id));
    }, [dataItem[field]]);

    const handleChange = (e) => {
        if (onChange) {
            onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
            setDropDownValue(e.target.value);
        }
    };

    return (
        <BaseCell {...others}>
            {dataItem[editField] ? (
                <DropDownList
                    onChange={handleChange}
                    value={dropDownValue}
                    data={dropDownData}
                    textField={textField}
                    disabled={disabled}
                    dataItemKey={dataItemKey}
                />
            ) : (
                dropDownValue[textField]
            )}
        </BaseCell>
    );
};
