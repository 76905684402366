import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance";
import { formatServiceResponse } from "../utilities/serviceUtility";

/**
 * @class JobService
 * @extends BaseService
 * @description Service class that handles all job related requests
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class JobService extends BaseService {
    constructor() {
        super("Customer/Job");
    }

    /**
     * @method getItemAllocations
     * @description Gets the item allocations for a job
     * @param jobId {String} - The ID of the job to get allocations for
     * @return {Promise<ServiceResponse>}
     */
    async getItemAllocations(jobId) {
        const response = await axios.get(
            `${this.uri}/Item/GetAllocationList?id=${jobId}&`
        );
        return formatServiceResponse(response);
    }

    /**
     * @method getAllocationSummary
     * @description Gets the accounting allocation summary for a job
     * @param jobId {String} - The ID of the job to get the summary for
     * @return {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    async getAllocationSummary(jobId) {
        const response = await axios.get(
            `${this.uri}/Billing/GetAllocationSummary?id=${jobId}&`
        );
        return formatServiceResponse(response);
    }
}
