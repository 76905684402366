import dayjs from "dayjs";

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^(\d{3}-){2}\d{4}$/);
export const emailValidator = (value) =>
    !value
        ? "Email field is required."
        : emailRegex.test(value)
        ? ""
        : "Email is not in a valid format.";
export const nameValidator = (value) => (!value ? "Name is required" : "");
export const emailFormatValidator = (value) => {
    if (value === "" || value === undefined || value === null) {
        return "";
    }
    return emailRegex.test(value) ? "" : "Email is not in a valid format.";
};
export const nameAndDuplicateValidator = (value, duplicate) => {
    return !value ? "Name is required" : duplicate ? "Name already exists" : "";
};
export const phoneValidator = (value) =>
    !value
        ? "Phone number is required."
        : phoneRegex.test(value)
        ? ""
        : "Not a valid phone number.";
export const phoneFormatValidator = (value) => {
    if (value === "" || value === undefined || value === null) {
        return "";
    }

    return phoneRegex.test(value) ? "" : "Not a valid phone number.";
};
export const requiredValidator = (value) =>
    value ? "" : "This field is required.";

export const greaterThanZeroValidator = (value) => {
    if (!value) {
        return "";
    }

    return value > 0 ? "" : "Value must be greater than 0";
};

export const cannotBeNegativeValidator = (value) => {
    if (!value) {
        return "";
    }

    return value > 0 ? "" : "Value cannot be negative.";
};

export const filesRequired = (value) =>
    value?.length > 0 ? "" : "Please attach a file";

// Dependent validator, to be used for fields that are required if the other is filled
export const dependentFieldValidator = (
    value,
    valueGetter,
    dependentFieldName,
    dependentValidationFunction
) => {
    if (!dependentValidationFunction)
        dependentValidationFunction = requiredValidator;
    const dependentValue = valueGetter(dependentFieldName);

    // If the current field is filled, no error
    if (value) return "";

    // If the dependent field is filled, return it's validation message
    if (dependentValue) return dependentValidationFunction(dependentValue);

    // If neither field is filled, no error
    return "";
};

export const phoneTypeValidator = (
    value,
    valueGetter,
    phoneNumberFieldName
) => {
    let phoneNumberValue = valueGetter(phoneNumberFieldName);
    const phoneNumberIsValid = !phoneFormatValidator(phoneNumberValue);

    if (!phoneNumberValue && value) return "Not valid without Phone Number";
    else if (!phoneNumberValue && !value) return "";
    else if (phoneNumberIsValid && value) return "";
    else if (phoneNumberIsValid && !value) return "Required with Phone Number";

    // else is typically "phone num is half filled in"
    return "";
};

export const phoneNumberWithTypeValidator = (
    value,
    valueGetter,
    phoneTypeFieldName,
    numberIsRequired = false
) => {
    // make an error message to return
    let errorMsg = "";

    // first check if number is valid, based on if its required, or if it is optional but has a value
    if (numberIsRequired) errorMsg = phoneValidator(value);
    else if (!value) return "";
    else if (value === "") return "";
    else if (value === "___-___-____") return "";
    else if (value) errorMsg = phoneFormatValidator(value);

    // if the phone number is valid so far, then check if the phone Type field exists
    if (!errorMsg && value) {
        const phoneTypeValue = valueGetter(phoneTypeFieldName);

        // if the number is valid, but the phone type doesnt exist, display error
        if (!phoneTypeValue) errorMsg = "Phone Type is Required.";
    }

    return errorMsg;
};

export const dateFormatValidator = (value) => {
    if (!value) return "";
    if (dayjs(value).format() === "Invalid Date")
        return "Value is not a valid date.";
};

export const requiredDateValidator = (value) => {
    if (!value) return "Date is required.";
    return dateFormatValidator(value);
};

// Validator to ensure either phone or email is present for a specific contact (primary/secondary).
export const contactDetailsValidator = (
    value,
    formValueGetter,
    contactType
) => {
    const email = formValueGetter(`${contactType}.email`);
    const phone = formValueGetter(`${contactType}.phone.phoneNumber`);
    const name = formValueGetter(`${contactType}.name`);

    // Validation for primary contact (always required)
    if (contactType === "primaryContact") {
        // Require either phone or email for primary contact
        if (!email && !phone) {
            return "Either phone or email is required for the primary contact.";
        }
        return undefined; // No error if either phone or email is provided
    }

    // Validation for secondary contact (optional, but if some fields are filled, validate)
    if (contactType === "secondaryContact") {
        // If no secondary contact details are provided, skip validation
        if (!name && !email && !phone) {
            return null; // No validation required if no secondary contact info is provided
        }

        // If either phone or email is provided for secondary contact, pass validation
        if (email || phone) {
            return undefined; // No error
        }

        // If both phone and email are missing, return validation error
        return "Either phone or email is required for the secondary contact.";
    }

    return undefined; // No error for other cases
};
