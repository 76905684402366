import React from "react";
import { Field } from "@progress/kendo-react-form";
import { FormInput } from "../../../../../../../../components/Deprecated/FormComponents.jsx";
import { requiredValidator } from "../../../../../../../../resources/Deprecated/formValidators.js";

/**
 * @function PayChexInformation
 * @return {Element}
 * @constructor
 */
export const PayChexInformation = () => {
    return (
        <div
            style={{
                marginBottom: 5,
            }}
        >
            <Field
                label={"Company ID"}
                name={"companyId"}
                component={FormInput}
                validator={requiredValidator}
            />
        </div>
    );
};
