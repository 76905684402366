import { axiosInstance as axios } from "../../axiosInstance";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

export default class DashboardService {
    /**
     * Sends GET request to get TrafficReport
     * @function getTrafficReport
     * @async
     * @returns {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getTrafficReport() {
        try {
            const response = await axios.get(
                "/Reporting/Dashboard/GetTrafficReport"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get the Current Active Tasks
     * @function getCurrentActiveTasks
     * @async
     * @returns {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getCurrentActiveTasks() {
        try {
            const response = await axios.get(
                "/Reporting/Dashboard/GetCurrentActiveTasksReport"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get TrafficReportCustomers
     * @function getTrafficReportCustomers
     * @param props
     * @async
     * @returns {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getTrafficReportCustomers(props) {
        const { reportType, dateRange } = props;

        try {
            const response = await axios.get(
                `/Reporting/Dashboard/GetTrafficReportCustomers?dateRange=${dateRange}&reportType=${reportType}`
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get Jobs with Money Due Report
     * @function getJobsWithMoneyDueReport
     * @async
     * @returns {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getJobsWithMoneyDueReport() {
        try {
            const response = await axios.get(
                "/Reporting/Dashboard/GetJobsWithMoneyDueReport"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get Jobs with Money Due Report
     * @function getJobsWithNoMoneyDueReport
     * @async
     * @returns {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getJobsWithNoMoneyDueReport() {
        try {
            const response = await axios.get(
                "/Reporting/Dashboard/GetJobsWithNoMoneyDueReport"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends POST request to get Reminders Report
     * @function getRemindersReport
     * @param request
     * @async
     * @returns {Promise<{success: boolean, data: *, error: string, originalResponse: (axios.AxiosResponse<*>|axios.AxiosError<*>|*)}>}
     */
    static async getRemindersReport(request) {
        try {
            const response = await axios.post(
                "/Reporting/Dashboard/GetRemindersReport",
                request
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get limited outstanding item receipts report
     * @function getLimitedOutstandingItemReceiptsReport
     * @async
     * @returns {Promise<ServiceResponse>} A promise that resolves to a service response object.
     */
    static async getLimitedOutstandingItemReceiptsReport() {
        try {
            const response = await axios.get(
                "/Reporting/Dashboard/GetLimitedOutstandingItemReceiptsReport"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    /**
     * Sends GET request to get full outstanding item receipts report.
     * @function getFullOutstandingItemReceiptsReport
     * @async
     * @returns {Promise<ServiceResponse>} A promise that resolves to a service response object.
     */
    static async getFullOutstandingItemReceiptsReport() {
        try {
            const response = await axios.get(
                "/Reporting/Dashboard/GetFullOutstandingItemReceiptsReport"
            );
            return formatServiceResponse(response);
        } catch (error) {
            return formatServiceResponse(error);
        }
    }

    static async getEventDatesQueueReport() {
        const response = await axios.get(
            "/Reporting/Dashboard/GetEventDatesQueueReport"
        );
        return formatServiceResponse(response);
    }

    static async getServiceDatesQueueReport() {
        const response = await axios.get(
            "/Reporting/Dashboard/GetServiceDatesQueueReport"
        );
        return formatServiceResponse(response);
    }
}
