//? Import axios library
//?
import { axiosInstance as axios } from "../../axiosInstance";
import { loginApiRequest } from "../../authconfig";
import { formatServiceResponse } from "../../../utilities/serviceUtility";

/**
 * Function to swap the current active tenant
 * @function swapTenant
 * @async
 * @param {any} tenantId
 * @param {any} userId
 * @returns {Promise<ServiceResponse>}
 */
export const swapTenant = async (tenantId, userId) => {
    try {
        const response = await axios.post("/Authentication/Tenant/Swap", {
            tenantId,
            userId,
        });
        return formatServiceResponse(response);
    } catch (error) {
        return formatServiceResponse(error);
    }
};

/**
 * Function to reload the tenant
 * @function reloadMsal
 * @async
 * @param {any} instance
 * @returns {Promise<ServiceResponse>}
 */
export const reloadMsal = async (instance) => {
    try {
        return await instance
            .acquireTokenRedirect({
                ...loginApiRequest,
            })
            .catch((error) => {
                console.error("Authentication error:", error);
            });
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/**
 * @async
 * @function getUser
 * @description Function to get user for user id
 * @param {String} userId
 * @returns  {Promise<ServiceResponse>}
 */
export const getUser = async (userId) => {
    try {
        const response = await axios.get(
            `/Authentication/User/Get?id=${userId}`
        );
        return formatServiceResponse(response);
    } catch (error) {
        formatServiceResponse(error);
    }
};

/**
 * @async
 * @function getUserByEmployeeId
 * @description Function to get user for employee id
 * @param {String} employeeId
 * @returns {Promise<ServiceResponse>}
 */
export const getUserByEmployeeId = async (employeeId) => {
    //? Intentionally no try-catch, error is handled on view layer because if
    //? this route returns a 404, we don't necessarily need to show an error to the user
    const response = await axios.get(
        `/Authentication/User/GetByEmployee?employeeId=${employeeId}`,
        { validateStatus: () => true }
    );
    return formatServiceResponse(response);
};

/**
 * Sends PUT request to API to change users role
 * @function changeUserRole
 * @async
 * @param {object} userAndRoleId
 * @returns {Promise<ServiceResponse>}
 */
export const changeUserRole = async (userAndRoleId) => {
    const response = await axios.post(
        "/Authentication/User/ChangeRole",
        userAndRoleId
    );
    formatServiceResponse(response);
};

//? Please don't use this if you don/t definitely need to
//?
export function wait(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
