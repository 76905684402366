import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import ResponsiveDialog from "../../../../../components/Deprecated/DialogWrapper";
import { VendorDocumentModalHelper } from "./Helpers/VendorDocumentModalHelper.js";
import { BusinessInformationDisplay } from "../../../../../components/Pages/Documents/BusinessInformationDisplay.jsx";
import { DocumentInformationDisplay } from "../../../../../components/Pages/Documents/DocumentInformationDisplay.jsx";
import { Card, CardBody } from "@progress/kendo-react-layout";
import styles from "./Styles/VendorDocumentModal.module.scss";
import { EmployeeDetailsPortlet } from "./Components/EmployeeDetailsPortlet.jsx";
import { ShippingInformation } from "./Components/ShippingInformation.jsx";
import { DocumentDetailsPortlet } from "./Components/DocumentDetailsPortlet.jsx";
import VendorDocumentModalProvider, {
    VendorDocumentModalContext,
} from "./Providers/VendorDocumentModalProvider.jsx";
import { VendorDocumentLineItems } from "./Components/VendorDocumentLineItems.jsx";
import {
    DeliveryModal,
    ExpectedDeliveryDate,
} from "./Components/ExpectedDeliveryDate.jsx";
import { AuthContext } from "../../../../../providers/Deprecated/Authentication/User/AuthProvider.jsx";
import { VendorDocumentType } from "../../../../../resources/Enums/VendorDocumentType.js";
import { DetailsHamburger } from "../../../../../components/Buttons/DetailsHamburger.jsx";
import { PDFExport } from "@progress/kendo-react-pdf";
import { exportPDF } from "../../../../../resources/Deprecated/helpers.js";
import { useModal } from "../../../../../hooks/useModal.js";

// Wraps the VendorDocumentModal component with the VendorDocumentProvider
export const VendorDocumentModalWrapper = (props) => {
    return (
        <VendorDocumentModalProvider>
            <VendorDocumentModal {...props} />
        </VendorDocumentModalProvider>
    );
};

/**
 * @function VendorDocumentModal
 * @param props.id {string} - id of the record
 * @param props.close {function} - close function
 * @param props.visible {boolean} - visible state
 * @param props.record {object} - record object
 * @return {JSX.Element}
 * @constructor
 */
const VendorDocumentModal = (props) => {
    const {
        formData,
        reloadDocumentKey,
        setModalState,
        onModalClose,
        modalState,
        onModalSubmit,
        onDelete,
        canDelete,
        canEdit,
        canReceive,
        onChangeStatus,
        pdfContainerRef,
        closeAfterSubmit,
        setFormData,
        reloadDocumentInformation,
        setConfirmationDialog,
    } = useContext(VendorDocumentModalContext);
    const [options, setOptions] = useState([]);
    const { user } = useContext(AuthContext);
    const deliveryModal = useModal();

    useEffect(() => {
        setModalState(props);
        setOptions([]);
    }, [props.visible]);

    useEffect(() => {
        if (!modalState.id) return;

        const optionsArray = [
            {
                icon: "print",
                actionName: "Export PDF",
                action: () => {
                    exportPDF(
                        `${
                            formData?.vendor?.name
                        }-${new Date().toLocaleDateString()}`,
                        pdfContainerRef
                    );
                },
            },
        ];

        if (
            formData?.type &&
            formData.type !== VendorDocumentType.Enum.PurchaseOrder
        ) {
            // If the document is not a Purchase Order, add the ability to view the previous document
            let actionName = `View Previous Document`;
            const nextDocumentType = formData.type - 1;
            if (
                nextDocumentType === VendorDocumentType.Enum.PurchaseOrder ||
                nextDocumentType === VendorDocumentType.Enum.ItemReceipt
            ) {
                actionName = `View ${VendorDocumentModalHelper.formatVendorDocumentType(
                    nextDocumentType
                )}`;
            }
            optionsArray.push({
                icon: "undo",
                actionName,
                action: () => {
                    if (formData.parentVendorDocument?.id) {
                        setModalState({
                            ...modalState,
                            id: formData.parentVendorDocument?.id,
                        });
                    } else {
                        console.error("No parentVendorDocument found");
                    }
                },
            });
        }

        if (
            formData?.childVendorDocuments &&
            formData.childVendorDocuments.length > 0
        ) {
            let actionName = `View Next Document`;
            const nextDocumentType = (formData?.type ?? 0) + 1;
            if (
                nextDocumentType === VendorDocumentType.Enum.ItemReceipt ||
                nextDocumentType === VendorDocumentType.Enum.VendorBill
            ) {
                actionName = `View ${VendorDocumentModalHelper.formatVendorDocumentType(
                    nextDocumentType
                )}`;
            }
            optionsArray.push({
                icon: "redo",
                actionName,
                action: () => {
                    if (formData.childVendorDocuments[0]?.id) {
                        setModalState({
                            ...modalState,
                            id: formData.childVendorDocuments[0]?.id,
                        });
                    } else {
                        console.error("No childVendorDocuments found");
                    }
                },
            });
        }

        if (
            modalState?.id &&
            formData?.type === VendorDocumentType.Enum.PurchaseOrder &&
            formData?.confirmedBy &&
            !formData.childVendorDocuments?.length > 0
        ) {
            optionsArray.push({
                icon: "redo",
                actionName: "Receive Order",
                action: () => {
                    onChangeStatus(VendorDocumentType.Enum.ItemReceipt);
                },
            });
        }

        if (
            canReceive &&
            modalState?.id &&
            formData?.type === VendorDocumentType.Enum.ItemReceipt
        ) {
            optionsArray.push({
                icon: "redo",
                actionName: "Bill Receipt",
                action: () => {
                    onChangeStatus(VendorDocumentType.Enum.VendorBill);
                },
            });
        }

        if (
            canEdit &&
            !formData?.confirmedBy &&
            formData?.type === VendorDocumentType.Enum.PurchaseOrder
        ) {
            optionsArray.push({
                icon: "checkmark",
                actionName: "Confirm Order",
                action: () => {
                    const confirmOrder = (deliveryInfo) => {
                        closeAfterSubmit.current = false;
                        const data = {
                            ...formData,
                            ...deliveryInfo,
                            confirmedBy: user.activeEmployee,
                        };
                        setFormData(data);
                        onModalSubmit({
                            values: data,
                        });
                        reloadDocumentInformation();
                    };

                    if (!formData?.expectedDeliveryDate) {
                        setConfirmationDialog({
                            visible: true,
                            msg: "Is there a delivery date for this order?",
                            action: () => {
                                deliveryModal.open(
                                    undefined,
                                    undefined,
                                    confirmOrder
                                );
                            },
                            actionOnCancel: () => {
                                confirmOrder({});
                            },
                        });
                    } else {
                        confirmOrder({});
                    }
                },
            });
        }

        if (
            formData?.type === VendorDocumentType.Enum.PurchaseOrder &&
            formData?.confirmedBy &&
            !formData.childVendorDocuments?.length > 0
        ) {
            optionsArray.push({
                icon: "x",
                actionName: "Un-Confirm Order",
                action: () => {
                    closeAfterSubmit.current = false;
                    const data = {
                        ...formData,
                        confirmedBy: undefined,
                    };
                    setFormData(data);
                    onModalSubmit({
                        values: data,
                    });
                    reloadDocumentInformation();
                },
            });
        }

        if (canDelete) {
            optionsArray.push({
                icon: "delete",
                actionName: "Delete",
                action: () => onDelete(),
            });
        }

        setOptions(optionsArray);
    }, [formData, canDelete, canEdit]);

    return (
        modalState?.visible &&
        formData && (
            <Form
                key={reloadDocumentKey}
                onSubmitClick={onModalSubmit}
                initialValues={formData}
                render={(formRenderProps) => (
                    <PDFExport>
                        <ResponsiveDialog
                            title={VendorDocumentModalHelper.generateModalTitle(
                                formData
                            )}
                            onClose={onModalClose}
                            size={"extraLarge"}
                        >
                            <FormElement>
                                <DeliveryModal {...deliveryModal} />
                                <span
                                    className={
                                        styles.VendorDocumentModalContainer
                                    }
                                    ref={pdfContainerRef}
                                >
                                    <Card style={{ width: "100%" }}>
                                        <CardBody
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <BusinessInformationDisplay
                                                formData={formData}
                                            />
                                            <span
                                                style={{
                                                    marginRight: "auto",
                                                    marginLeft:
                                                        formData.businessInformation
                                                            ? "auto"
                                                            : 0,
                                                }}
                                            >
                                                <DocumentInformationDisplay
                                                    formData={formData}
                                                    type={VendorDocumentType.ParseToString(
                                                        formData.type
                                                    )}
                                                />
                                                <ExpectedDeliveryDate />
                                                <ShippingInformation />
                                            </span>
                                            {options.length > 0 && (
                                                <span className={"export-hide"}>
                                                    <DetailsHamburger
                                                        options={options}
                                                        text={"Details"}
                                                    />
                                                </span>
                                            )}
                                        </CardBody>
                                    </Card>
                                    <span
                                        className={
                                            "JustifyLeftAlignRight MediumGap"
                                        }
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <span
                                            className={
                                                "JustifyLeftAlignRight MediumGap"
                                            }
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <EmployeeDetailsPortlet />
                                            <DocumentDetailsPortlet
                                                formRenderProps={
                                                    formRenderProps
                                                }
                                            />
                                        </span>
                                    </span>
                                    <VendorDocumentLineItems />
                                </span>
                            </FormElement>
                        </ResponsiveDialog>
                    </PDFExport>
                )}
            />
        )
    );
};
