import * as React from "react";
import { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { BaseCell } from "./BaseCell";
import { EnumCell } from "./EnumCell.jsx";

/**
 * @function EditableEnumCell
 * @description A cell that allows for editing via a drop-down list for enums.
 * @param props.enumerable {Object} - The enumerable object
 * @return {Element}
 * @constructor
 */
export const EditableEnumCell = (props) => {
    const {
        field = "",
        editField = "IN_LINE_EDIT",
        dataItem,
        enumerable,
        disabled,
        onChange,
        ...others
    } = props;
    const [dropDownValue, setDropDownValue] = useState(undefined);

    useEffect(() => {
        setDropDownValue({
            key: enumerable.ParseToString(dataItem[field]),
            value: dataItem[field],
        });
    }, [dataItem[field]]);

    const handleChange = (e) => {
        if (onChange) {
            onChange({
                dataIndex: 0,
                dataItem: dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.value.value,
            });
            setDropDownValue(e.value);
        }
    };

    return (
        <BaseCell {...others}>
            {dataItem[editField] ? (
                <DropDownList
                    onChange={handleChange}
                    value={dropDownValue}
                    data={enumerable
                        .AsKeyValue()
                        .filter((item) => item.value !== dataItem[field])}
                    textField={"key"}
                    disabled={disabled}
                    dataItemKey={"value"}
                />
            ) : (
                <EnumCell enumerable={enumerable} {...others} />
            )}
        </BaseCell>
    );
};
