import { Field } from "@progress/kendo-react-form";
import * as React from "react";
import { useState } from "react";
import { FormRemoteDropDown } from "../../../../../../../../../components/Deprecated/FormComponents.jsx";
import { ContentHeader } from "../../../../../../../../../components/ContentHeader.jsx";
import { DataGrid } from "../../../../../../../../../components/Grids/DataGrid.jsx";
import { InventoryType } from "../../../../../../../../../resources/Enums/InventoryType.js";
import { GridColumn } from "@progress/kendo-react-grid";
import { BaseCell } from "../../../../../../../../../components/GridColumnComponents/BaseCell.jsx";
import { InventoryModalHelper } from "../../../../../../../../Inventory/Inventory/Helpers/InventoryModalHelper.js";
import { Checkbox } from "@progress/kendo-react-inputs";
import NumberProcessor from "../../../../../../../../../resources/Processors/NumberProcessor.js";
import { requiredValidator } from "../../../../../../../../../resources/Deprecated/formValidators.js";

/**
 * @function SqftUse
 * @param props.formRenderProps {object}
 * @param props.record {object}
 * @return {Element}
 * @constructor
 */
export const SqftUse = (props) => {
    const { formRenderProps, record } = props;
    const [runningTotalSqft, setRunningTotalSqft] = useState(0);

    const onCheckChange = (checkBoxProps, columnProps) => {
        if (checkBoxProps.value) {
            const sqft = NumberProcessor.round(
                (columnProps.dataItem.width * columnProps.dataItem.length) / 144
            );
            setRunningTotalSqft((prev) => prev + sqft);
            formRenderProps.onChange(`sqftItems[${columnProps.dataIndex}]`, {
                value: {
                    identifier: columnProps.dataItem.identifier,
                    storageLocationId: columnProps.dataItem.storageLocation.id,
                    isChecked: true,
                },
            });
        } else {
            const sqft = NumberProcessor.round(
                (columnProps.dataItem.width * columnProps.dataItem.length) / 144
            );
            setRunningTotalSqft((prev) => prev - sqft);
            formRenderProps.onChange(`sqftItems[${columnProps.dataIndex}]`, {
                value: undefined,
            });
        }
    };

    return (
        <span>
            <ContentHeader
                title={`Select Inventory to ${
                    record.isUnUsed ? "un-use" : "use"
                }`}
                style={{
                    fontSize: "18px",
                    textAlign: "left",
                }}
            />
            <p
                style={{
                    textAlign: "left",
                }}
            >
                Total {record.isUnUsed ? "un-using" : "using"} Square Footage:{" "}
                <strong>{runningTotalSqft}</strong>
            </p>
            {record.isUnUsed && (
                <>
                    <Field
                        url={"/Inventory/StorageLocation/GetAll?"}
                        name={"returningLocation"}
                        component={FormRemoteDropDown}
                        label={"Location To Return Item(s):"}
                        validator={requiredValidator}
                    />
                    <br />
                </>
            )}
            <DataGrid
                style={{
                    maxWidth: "99.99%",
                }}
                useInlineEdit={false}
                uri={"/Inventory/Inventory/GetAll?"}
                resizable={true}
                initialSort={[
                    {
                        field: "identifier",
                        dir: "asc",
                    },
                ]}
                showColumnsMultiSelect={false}
                initialFilter={{
                    logic: "and",
                    filters: [
                        {
                            field: "item.id",
                            operator: "eq",
                            value: record.itemId,
                        },
                        {
                            field: "type",
                            operator: "eq",
                            value: record.isUnUsed
                                ? InventoryType.Enum.Used
                                : InventoryType.Enum.Allocated,
                        },
                        {
                            field: "jobId",
                            operator: "eq",
                            value: record.jobId,
                        },
                    ],
                }}
            >
                <GridColumn
                    field="measurements"
                    title="Measurements:"
                    width={"auto"}
                    minResizableWidth={50}
                    cell={(props) => (
                        <BaseCell {...props}>
                            {InventoryModalHelper.displayMeasurement(
                                props.dataItem
                            )}
                        </BaseCell>
                    )}
                />
                <GridColumn
                    field="identifier"
                    title="Identifier:"
                    width={100}
                    minResizableWidth={50}
                />
                <GridColumn
                    className={"k-table-td"}
                    field={"storageLocation.name"}
                    title={"Current Location:"}
                    width={250}
                    minResizableWidth={100}
                    locked={true}
                />
                <GridColumn
                    width={115}
                    resizable={false}
                    cell={(props) => (
                        <BaseCell
                            {...props}
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            <Checkbox
                                label={record.isUnUsed ? "Un-Use?" : "Use?"}
                                value={formRenderProps.valueGetter(
                                    `sqftItems[${props.dataIndex}].isChecked`
                                )}
                                onChange={(checkBoxProps) =>
                                    onCheckChange(checkBoxProps, props)
                                }
                            />
                        </BaseCell>
                    )}
                />
            </DataGrid>
        </span>
    );
};
