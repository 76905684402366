import BaseService from "./BaseService";
import { axiosInstance as axios } from "./axiosInstance";
import { formatServiceResponse } from "../utilities/serviceUtility";

/**
 * @class EmployeeService
 * @extends BaseService
 * @description Service class that handles all Employee related requests
 * @constructor
 * @param uri {String} - The base URI for the service
 */
export default class EmployeeService extends BaseService {
    constructor(uri) {
        super(uri ?? "HumanResources/Employee");
    }

    /**
     * @method ssnExists
     * @description Checks if an employee with the provided SSN exists
     * @param ssn {String} - The SSN to check
     * @return {Promise<ServiceResponse>}
     */
    async ssnExists(ssn) {
        const res = await axios.post(
            `${this.uri}/SSNExists?`,
            {
                ssn,
            },
            {
                validateStatus: (status) => {
                    return (status >= 200 && status < 300) || status === 404;
                },
            }
        );
        return formatServiceResponse(res);
    }

    /**
     * @method activate
     * @description Activates an employee
     * @param id {String} - The ID of the employee to activate
     * @return {Promise<ServiceResponse>}
     */
    async activate(id) {
        const res = await axios.put(`${this.uri}/Activate?`, {
            id,
        });
        return formatServiceResponse(res);
    }
}
