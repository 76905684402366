import React from "react";
import { BaseCell } from "./BaseCell";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

/**
 * @function DateCell
 * @description
 * A cell component that renders a date value as a string based on the provided format.
 * @param {Object} props
 * @param {Object} props.dataItem - The data item
 * @param {string} props.field - The field to render
 * @param {Object} props.tdProps - The props to pass to the td element
 * @param {string} props.format - The format to render the date as
 * @param {string} props.errText - The text to render if the date is not valid
 * @return {React.JSX.Element}
 * @constructor
 */
export const DateCell = (props) => {
    const { dataItem, field, tdProps, format, errText } = props;

    const displayField = field.split(".").reduce((acc, curr) => {
        return acc && typeof acc === "object" ? acc[curr] : undefined;
    }, dataItem);

    return (
        <BaseCell {...tdProps}>
            {displayField ? dayjs(displayField).format(format) : errText}
        </BaseCell>
    );
};
