import React from "react";
import FormField from "../../../../../../components/Deprecated/StylingField.jsx";

/**
 * @function PayChexPayrollDetails
 * @param props.dataItem {Object}
 * @return {Element}
 * @constructor
 */
export const PayChexPayrollDetails = (props) => {
    const { dataItem } = props;

    return (
        <span>
            <FormField label="Company ID" value={dataItem?.companyId} />
        </span>
    );
};
