import React from "react";
import { FileManager } from "../../../../components/FileManager/FileManager.jsx";

const fileRestrictions = {
    allowedExtensions: [".jpeg", ".pdf", ".jpg", ".png", ".mov", ".mp4", ".xlsx", ".xls"],
};

export const VendorFiles = ({ vendorId }) => {
    return (
        <div>
            <div
                style={{
                    textAlign: 'left',
                    marginBottom: 10,
                    fontWeight: 'bold'
                }}
            >
                Allowed file types are: {fileRestrictions.allowedExtensions.join(", ")}
            </div>
            <FileManager
                disabled={false}
                allowedExtensions={fileRestrictions}
                saveUri={"/General/File/UploadFile"}
                removeUri={"/General/File/DeleteFile"}
                readUri={"/General/File/GetDirectoryContents"}
                location={`VendorFiles-${vendorId}`}
            />
        </div>
    );
};
