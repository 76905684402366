import { Card, CardBody } from "@progress/kendo-react-layout";
import React, { useState, useEffect } from "react";
import { MultiSelect } from '@progress/kendo-react-dropdowns';

/**
 * @component AutoGenerationSettingsForm
 * @description This component houses a multiselect box to select fields
 * that the autogeneration uses in the multi item creation modal.
 * @param {string} field The field name this multiselect is responsible for
 * @param {string} label The lable of this select box
 * @param {array} items The items that are listed in the select box
 * @param {CallableFunction} onChange when the value changes of the select box  
 * @returns 
 */
const AutoGenerationSettingsForm = ({ field, label, items, onChange }) => {
    const [value, setValue] = useState([]);

    /**
     * The select box change handler
     * @param {event} event 
     */
    const handleChange = (event) => {
        setValue([...event.value]);
    };

    /**
     * When the item list changes, this will sync if an item
     * is removed and it is in the value state.
     */
    const cleanup = () => {
        for (let i = 0; i < value.length; i++) {
            const item = value[i];
            const index = items.findIndex(itm => itm.id === item.id);
            if (index !== -1) continue;
            const newValues = value;
            newValues.splice(i, 1);
            setValue(newValues);
        }
    }

    /**
     * When the value changes, call the passed onChange
     * function for the parent component.
     */
    useEffect(() => {
        onChange(field, value);
    }, [value]);

    // Check for any descrepencies in the items list
    // versus the values set.
    cleanup();

    return (
        <Card className="card-container" style={{ margin: 0, width: '100%' }}>
            <CardBody
                style={{
                    padding: "0px",
                    margin: 0,
                    textAlign: 'left',
                    width: '100%'
                }}
            >
                <div style={{ marginBottom: 5 }}><strong>{label}</strong> Auto Generation Fields</div>
                <div className="multiselect">
                    <MultiSelect
                        data={items}
                        dataItemKey="id"
                        textField="name"
                        onChange={handleChange}
                        value={value}
                        width={"100%"}
                        placeholder="Please select..."
                        size="small"
                    />
                </div>
            </CardBody>
        </Card>
    );
};

export default AutoGenerationSettingsForm;
