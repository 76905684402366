import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import EmployeeTimeClockService from "../../../../services/Deprecated/humanResources/EmployeeTimeClockService";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { getAllDatesBetween } from "../../../../resources/Deprecated/dateHelper";
import "../TimeCard.module.scss";
import duration from "dayjs/plugin/duration";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { deleteTimeClockEntry } from "../../../../services/Deprecated/humanResources/timeClockServices";
import { TimeClockEntryModal } from "./TimeClockEntryModal";
import { useModal } from "../../../../hooks/useModal";
import PayrollService from "../../../../services/Deprecated/humanResources/PayrollService";
import { ToggleButton } from "../../../../components/Buttons/ToggleButton/ToggleButton";

dayjs.extend(duration);

/**
 * @function TimeCard
 * @description A card that displays the time clock entries for a specific employee and date range
 * @param {string} props.employeeId - The employee ID to fetch time clock entries for
 * @param {Object} props.dateRange - The date range to fetch time clock entries for
 * @param {Object} [props.kendoFilter] - The Kendo filter object to apply to the data
 * @param {boolean} [props.showBreaks] - Whether or not to show break entries
 * @return {Element}
 * @constructor
 */
export const TimeCard = (props) => {
    const { employeeId, dateRange, kendoFilter, showBreaks } = props;
    const { reloadData } = useContext(ReloadDataContext);
    const [dates, setDates] = useState([]);
    const [data, setData] = useState(new Map());
    const [minBreakTime, setMinBreakTime] = useState(0);
    const [showSubMinuteEntries, setShowSubMinuteEntries] = useState(false);
    const tcModal = useModal();

    useEffect(() => {
        // We need an employee ID to fetch data
        if (!employeeId) {
            return;
        }

        // We need both a start and end date to fetch data
        if (!dateRange?.start || !dateRange?.end) return;

        EmployeeTimeClockService.getAll({
            employeeId: employeeId,
            startDate: dayjs(dateRange.start).format("YYYY-MM-DD"),
            endDate: dayjs(dateRange.end).format("YYYY-MM-DD"),
            kendoFilter: kendoFilter,
        }).then((res) => {
            setData(formatTimeClockData(res.data, showSubMinuteEntries));
            setDates(
                getAllDatesBetween(dateRange.start, dateRange.end, "MM/DD/YYYY")
            );
        });
    }, [employeeId, dateRange, reloadData, kendoFilter, showSubMinuteEntries]);

    // Fetch the minimum break time if we are showing breaks
    useEffect(() => {
        if (showBreaks) {
            PayrollService.getPayrollInterval().then((res) => {
                if (res.originalResponse.status === 404) {
                    setMinBreakTime(20);
                } else {
                    setMinBreakTime(res.data.minimumBreakDuration);
                }
            });
        }
    }, [showBreaks]);

    /**
     * @function formatTimeClockData
     * @description Formats the time clock data into a more readable format
     * Also calculates the total clocked time for each day, then groups the data by date
     * @param {Array} data - The time clock data to format
     * @param {Boolean} showSubMinuteEntries - Whether or not to show entries with a time worked of less than 1 minute
     * @return {Map}
     */
    const formatTimeClockData = (data, showSubMinuteEntries) => {
        if (!data || data.length === 0) return new Map();

        if (!showSubMinuteEntries) {
            // Filter out entries that have a time worked of less than 1 minute
            data = data.filter((entry) => {
                if (!entry.endTime) return false;

                const start = dayjs(entry.endTime);
                const end = dayjs(entry.startTime);

                return start.diff(end, "minutes") > 1;
            });
        }
        // Sort the data by start time
        data = data.sort((a, b) =>
            dayjs(a.startTime).isBefore(dayjs(b.startTime)) ? -1 : 1
        );
        // Group the data by date
        const groupedData = Object.groupBy(data, (i) =>
            dayjs(i.startTime).format("MM/DD/YYYY")
        );

        const dataMap = new Map();
        Object.entries(groupedData).forEach((value) => {
            // Calculate the total clocked time and timespan by iterating over the time clock entries, value[1]
            let totalClockedTime = 0;

            const formattedEntries = [];
            value[1].forEach((entry, i) => {
                // Clear out milliseconds and seconds for display purposes
                const dayjsStartTime = dayjs
                    .utc(entry.startTime)
                    .millisecond(0)
                    .second(0)
                    .local();
                let dayjsEndTime;
                let timeWorked = 0;

                if (entry.endTime) {
                    dayjsEndTime = dayjs
                        .utc(entry.endTime)
                        .millisecond(0)
                        .second(0)
                        .local();
                }

                if (dayjsEndTime) {
                    timeWorked = dayjsEndTime.diff(dayjsStartTime, "minute");
                }

                entry.startTimeDisplay = dayjsStartTime.format("HH:mm");

                if (entry.isError) {
                    entry.endTimeDisplay = "Missing";
                } else if (entry.endTime) {
                    entry.endTimeDisplay = dayjsEndTime.format("HH:mm");
                } else {
                    entry.endTimeDisplay = "Clocked In";
                }

                entry.timeWorkedDisplay = dayjs
                    .duration(timeWorked, "minutes")
                    .format("HH[h] mm[m]");

                formattedEntries.push(entry);
                totalClockedTime += timeWorked;

                // Employee Break Logic
                const nextEntry = value[1][i + 1];

                if (!nextEntry || !showBreaks) return;

                // If the next entry is more than 1 minute after the current entry, add a break entry
                const timeDiff = dayjs
                    .utc(nextEntry?.startTime)
                    .local()
                    .diff(dayjsEndTime, "minute");
                if (timeDiff > 1) {
                    formattedEntries.push({
                        timeWorkedDisplay: timeDiff + "m",
                        isOnBreak: true,
                        isError: timeDiff < minBreakTime,
                    });
                }
            });

            const totalClockedTimeDisplay = dayjs
                .duration(totalClockedTime, "minutes")
                .format("HH[h] mm[m]");

            dataMap.set(value[0], {
                entries: formattedEntries,
                totalClockedTime: totalClockedTimeDisplay,
            });
        });

        return dataMap;
    };

    return (
        <>
            <TimeClockEntryModal {...tcModal} />
            <ToggleButton
                label={"Show Sub-Minute Entries?"}
                onToggleOn={() => setShowSubMinuteEntries(true)}
                onToggleOff={() => setShowSubMinuteEntries(false)}
            />
            <table id={"TimeCardTable"}>
                <tbody className={"AlignTextLeft"}>
                    <tr>
                        <th>Date</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Hours</th>
                        <th className={"wideTh"}>Job/Task</th>
                        <th>Action</th>
                    </tr>
                    {dates.map((date, i) => (
                        <React.Fragment key={i}>
                            <TimeCardDateRow
                                date={date}
                                tcModal={tcModal}
                                employeeId={employeeId}
                            />
                            <TimeCardTableBodyRow
                                data={data}
                                date={date}
                                tcModal={tcModal}
                            />
                            {data.has(date) && (
                                <TimeCardTableFooterRow
                                    totalClockedTime={
                                        data.get(date).totalClockedTime
                                    }
                                />
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </>
    );
};

/**
 * @function TimeCardDateRow
 * @description The empty date row that displays the date and an "Add" button
 * @param {string} props.date - The date to display
 * @param {Object} props.tcModal - The time clock entry modal
 * @param {string} props.employeeId - The employee ID to add a time clock entry for
 * @return {Element}
 * @constructor
 */
const TimeCardDateRow = (props) => {
    const { date, tcModal, employeeId } = props;

    return (
        <tr className={"topBorderSeparator"}>
            <td>{date}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className={"JustifyLeftAlignLeft"}>
                <ActionButton
                    text={"Add"}
                    onClick={() =>
                        tcModal.open(undefined, {
                            start: dayjs(date),
                            end: dayjs(date),
                            employeeId: employeeId,
                        })
                    }
                    className={"actionButton"}
                />
            </td>
        </tr>
    );
};

/**
 * @function TimeCardTableBodyRow
 * @description The table body row that displays the time clock entries for a specific date
 * @param {Map} props.data - The time clock data to display
 * @param {string} props.date - The date to display
 * @param {Object} props.tcModal - The time clock entry modal
 * @return {*}
 * @constructor
 */
const TimeCardTableBodyRow = (props) => {
    const { data, date, tcModal } = props;
    const { triggerReload } = useContext(ReloadDataContext);

    const formatJobTaskDisplay = (entry) => {
        if (entry.task) {
            return entry.job.name + "/" + entry.task.name;
        }

        if (entry.job) {
            return entry.job.name;
        }

        return "No Job/Task";
    };

    const handleHighlightRow = (entry) => {
        if (entry.isError) {
            return "inError";
        }

        if (!entry.job && !entry.isOnBreak) {
            return "inWarning";
        }

        return "";
    };

    return (
        data.has(date) &&
        data.get(date).entries.map((entry, j) => {
            if (entry.isOnBreak) return;

            // If the next entry is a break and an error, mark this entry as an error
            // This is due to the break being on the same line as the entry
            const nextEntry = data.get(date).entries[j + 1];
            if (nextEntry?.isOnBreak && nextEntry.isError) {
                entry.isError = true;
            }

            return (
                <tr key={j} className={handleHighlightRow(entry)}>
                    {nextEntry?.isOnBreak ? (
                        <td style={{ fontWeight: "bold" }}>
                            Break: {nextEntry.timeWorkedDisplay}
                        </td>
                    ) : (
                        <td></td>
                    )}
                    <td>{entry.startTimeDisplay}</td>
                    <td>{entry.endTimeDisplay}</td>
                    <td>{entry.timeWorkedDisplay}</td>
                    <td>{formatJobTaskDisplay(entry)}</td>
                    <td className={"JustifyLeftAlignLeft"}>
                        <ActionButton
                            text={"Edit"}
                            onClick={() => tcModal.open(entry.id)}
                            className={"actionButton"}
                        />
                        {(entry.endTime || entry.isError) && (
                            <ActionButton
                                text={"Delete"}
                                onClick={() => {
                                    deleteTimeClockEntry(entry.id).then(() => {
                                        triggerReload();
                                    });
                                }}
                                confirmationState={{
                                    needsConfirmation: true,
                                    confirmationText:
                                        "Are you sure you want to delete this entry?",
                                }}
                                className={"actionButton"}
                            />
                        )}
                    </td>
                </tr>
            );
        })
    );
};

/**
 * @function TimeCardTableFooterRow
 * @description The table footer row that displays the total clocked time for a specific date
 * @param {string} props.totalClockedTime - The total clocked time to display
 * @return {Element}
 * @constructor
 */
const TimeCardTableFooterRow = (props) => {
    const { totalClockedTime } = props;

    return (
        <tr>
            <td className={"bold"}>Daily Total:</td>
            <td>{totalClockedTime}</td>
        </tr>
    );
};
