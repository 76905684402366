export class UseModalHelper {
    /**
     * @function formatDataForNetwork
     * @description Format data for network
     * @param {Object} dataItem - The data item
     * @param {Boolean} isUnUsed - Is isUnUsed
     * @return {Object}
     */
    static formatDataForNetwork(dataItem, isUnUsed) {
        if (isUnUsed) {
            return this.formatUnUsedData(dataItem);
        } else {
            return this.formatUsedData(dataItem);
        }
    }

    static formatUnUsedData(dataItem) {
        const data = {
            returningLocationId: dataItem.returningLocation.id,
            itemId: dataItem.itemId,
            jobId: dataItem.jobId,
            quantity: dataItem.quantity,
        };

        if (dataItem.sqftItems) {
            const usingItems = Object.values(dataItem.sqftItems).filter(
                (i) => i !== undefined
            );
            if (usingItems.length > 0) {
                data.sqftDetailList = usingItems.map((item) => {
                    return {
                        identifier: item.identifier,
                    };
                });
                data.quantity = data.sqftDetailList.length;
            }
        }

        return data;
    }

    static formatUsedData(dataItem) {
        const data = {
            itemId: dataItem.itemId,
            jobId: dataItem.jobId,
            quantity: dataItem.quantity,
        };

        if (dataItem.sqftItems) {
            const usingItems = Object.values(dataItem.sqftItems).filter(
                (i) => i !== undefined
            );
            if (usingItems.length > 0) {
                data.sqftDetailList = usingItems.map((item) => {
                    return {
                        identifier: item.identifier,
                        storageLocationId: item.storageLocationId,
                    };
                });
                data.quantity = data.sqftDetailList.length;
            }
        } else {
            data.storageLocationId = dataItem.storageLocation.id;
        }

        return data;
    }
}
