import { BaseCell } from "./BaseCell";
import React, { useContext, useMemo } from "react";
import { ActionButton } from "../Buttons/ActionButton";
import { BreadcrumbContext } from "../../providers/BreadCrumbProvider";
import { DropDownButton } from "@progress/kendo-react-buttons";

/**
 * @function ActionCell
 * @description
 * A cell component that renders action buttons.
 * Handles permissions, and confirmation dialogs.
 * @param {Object} props
 * @param {CallableFunction} props.onDelete - Function to call when delete button is clicked
 * @param {CallableFunction} props.onEdit - Function to call when edit button is clicked
 * @param {Object} props.viewState - {
 *     uri: string,
 *     breadcrumbInfo: Array<{Object}>
 * }
 * @param {Object} props.permissions - {
 *     view: [],
 *     edit: [],
 *     delete: [],
 * } - Permissions for view, edit, and delete
 * @param {[string]} props.deleteBtnText - Text for delete button
 * @param {[string]} props.viewBtnText - Text for view button
 * @param {[string]} props.editBtnText - Text for edit button
 * @param {Object[]} props.additionalActions - Additional action buttons
 * @param {Boolean} props.useHamburgerMenu - Use hamburger menu for actions
 * @return {React.JSX.Element}
 * @constructor
 */
export const ActionCell = (props) => {
    const {
        onDelete,
        onEdit,
        viewState,
        permissions,
        additionalActions,
        tdProps,
        deleteBtnText = "Delete",
        viewBtnText = "View",
        editBtnText = "Edit",
        useHamburgerMenu = false,
    } = props;
    const { handleRouteChange } = useContext(BreadcrumbContext);

    const generateButtonList = () =>
        useMemo(() => {
            const actionBtnList = [];
            const hamburgerProps = (props) => {
                return {
                    themeColor: useHamburgerMenu ? null : props.themeColor,
                    fillMode: useHamburgerMenu ? "flat" : props.fillMode,
                    style: useHamburgerMenu
                        ? {
                              fontWeight: "bold",
                              width: 200,
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                          }
                        : props.style,
                };
            };

            if (viewState) {
                actionBtnList.push(
                    <ActionButton
                        key={Math.random()}
                        onClick={() => handleRouteChange(viewState)}
                        requiredPermissions={permissions.view}
                        text={viewBtnText}
                        icon={useHamburgerMenu ? "k-icon k-i-redo" : null}
                        {...hamburgerProps({})}
                    />
                );
            }

            if (onEdit) {
                actionBtnList.push(
                    <ActionButton
                        key={Math.random()}
                        onClick={onEdit}
                        requiredPermissions={permissions.edit}
                        text={editBtnText}
                        icon={useHamburgerMenu ? "k-icon k-i-pencil" : null}
                        {...hamburgerProps({})}
                    />
                );
            }

            if (onDelete) {
                actionBtnList.push(
                    <ActionButton
                        key={Math.random()}
                        onClick={onDelete}
                        requiredPermissions={permissions.delete}
                        confirmationState={{
                            needsConfirmation: true,
                        }}
                        text={deleteBtnText}
                        icon={useHamburgerMenu ? "k-icon k-i-trash" : null}
                        {...hamburgerProps({})}
                    />
                );
            }

            if (additionalActions) {
                additionalActions.forEach((action) => {
                    actionBtnList.push(
                        <ActionButton
                            {...action}
                            key={Math.random()}
                            {...hamburgerProps(action)}
                        />
                    );
                });
            }

            return actionBtnList;
        }, [permissions]);

    return (
        <BaseCell
            {...tdProps}
            style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
            }}
        >
            {!useHamburgerMenu ? (
                generateButtonList()
            ) : (
                <DropDownButton
                    itemRender={(props) => {
                        return (
                            <div onClick={(e) => e.stopPropagation()}>
                                {props.item}
                            </div>
                        );
                    }}
                    text="Actions"
                    themeColor="primary"
                    iconClass="k-icon k-i-more-vertical"
                    items={generateButtonList()}
                />
            )}
        </BaseCell>
    );
};
