import React, { useContext } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import { JobContext } from "../../../../providers/Deprecated/Customer/JobProvider";
import { ReloadDataContext } from "../../../../providers/ReloadDataProvider";
import { useModal } from "../../../../hooks/useModal";
import { EventModal } from "../../CalendarEvents/Components/EventModal/EventModal";
import { PermissionsEnum } from "../../../../resources/Enums/PermissionsEnum";
import { DataGrid } from "../../../../components/Grids/DataGrid";
import { LinkCell } from "../../../../components/GridColumnComponents/LinkCell";
import { DateCell } from "../../../../components/GridColumnComponents/DateCell";
import { CustomAppointmentTimeCell } from "../../../../components/Deprecated/CustomGridCells";
import { ActionCell } from "../../../../components/GridColumnComponents/ActionCell";
import { deleteEvent } from "../../../../services/Deprecated/customer/eventServices";
import { CenterLoader } from "../../../../components/Deprecated/CenterLoader";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { CalendarEventStatus } from "../../../../resources/Enums/CalendarEventStatus.js";
import { EditableEnumCell } from "../../../../components/GridColumnComponents/EditableEnumCell.jsx";
import BaseService from "../../../../services/BaseService.js";
import { formatDateOnlyForNetwork } from "../../../../resources/Deprecated/dateHelper.js";
import dayjs from "dayjs";

const InitialSort = [
    {
        field: "eventType.name",
        dir: "asc",
    },
    {
        field: "eventDate",
        dir: "asc",
    },
];
const NameStringListCell = (props) => {
    return (
        <td {...props.tdProps} colSpan={props.colSpan ?? 1}>
            {props.dataItem.laborers.map((l) => l.name).join(", ")}
        </td>
    );
};

const JobEvents = () => {
    const jobContext = useContext(JobContext);
    const { triggerReload } = useContext(ReloadDataContext);
    const modal = useModal();

    const onSaveChanges = async (data) => {
        for (const item of data) {
            const service = new BaseService("/Customer/CalendarEvent");
            await service.update({
                id: item.id,
                eventDateTitle: item.eventDateTitle,
                eventDate:
                    item.eventDate &&
                    formatDateOnlyForNetwork(dayjs(item.eventDate).toDate()),
                specification: item.specification,
                jobId: item.jobId,
                customerId: item.customerId,
                eventTypeId: item.eventType.id,
                jobDocumentId: item.jobDocument?.id,
                status: item.status,
            });
        }

        triggerReload();
    };

    return jobContext?.job?.id ? (
        <div className={"JustifyLeftAlignLeft FlexColumn SmallGap"}>
            <EventModal {...modal} />
            <ActionButton
                onClick={() =>
                    modal.open(undefined, {
                        eventDate: new Date(),
                        job: jobContext.job,
                        customer: jobContext.job.customer,
                    })
                }
                text={"Create Event"}
                icon={"k-icon k-i-plus"}
                requiredPermissions={[PermissionsEnum.CreateCalendarEvent]}
            />
            <DataGrid
                uri={`/Customer/Job/CalendarEvent/GetAll?jobId=${jobContext.job.id}&`}
                sortable={{
                    mode: "multiple",
                }}
                sort={InitialSort}
                searchFields={["eventDateTitle", "eventType.name"]}
                useInlineEdit={true}
                onSaveChanges={onSaveChanges}
                resizable={true}
            >
                <GridColumn
                    field="eventType.name"
                    title="Type:"
                    width={400}
                    editable={false}
                    cell={(props) => (
                        <LinkCell
                            {...props}
                            field={"eventType.name"}
                            uri={`/event/${props.dataItem.id}`}
                        />
                    )}
                />
                <GridColumn
                    field="eventDateTitle"
                    title="Title:"
                    width={400}
                    editable={false}
                />
                <GridColumn
                    field="eventDate"
                    title="Date:"
                    width={150}
                    editable={false}
                    cell={(props) => (
                        <DateCell
                            {...props}
                            format={"MM/DD/YYYY"}
                            errText={"Date Not Set"}
                        />
                    )}
                />
                <GridColumn
                    field="status"
                    title="Status:"
                    width={150}
                    cell={(props) => (
                        <EditableEnumCell
                            {...props}
                            enumerable={CalendarEventStatus}
                        />
                    )}
                />
                <GridColumn
                    field="appointmentTime"
                    title="Appointment Time:"
                    width={175}
                    editable={false}
                    cell={(props) => (
                        <CustomAppointmentTimeCell
                            {...props}
                            data={props.dataItem}
                        />
                    )}
                />
                <GridColumn
                    field="jobDocument.name"
                    title="Job Document:"
                    width={200}
                    editable={false}
                />
                <GridColumn
                    field={"laborers"}
                    title={"Laborers:"}
                    width={400}
                    editable={false}
                    cell={(props) => <NameStringListCell {...props} />}
                />
                <GridColumn
                    width={180}
                    filterable={false}
                    sortable={false}
                    editable={false}
                    cell={(props) => (
                        <ActionCell
                            onEdit={() => modal.open(props.dataItem.id)}
                            onDelete={() =>
                                deleteEvent(props.dataItem.id).then(() => {
                                    triggerReload();
                                })
                            }
                            viewState={{
                                uri: `/event/${props.dataItem.id}`,
                                breadcrumbInfo: [
                                    {
                                        id: props.dataItem.id,
                                        text:
                                            props.dataItem.eventDateTitle ??
                                            "Current Event",
                                    },
                                ],
                            }}
                            permissions={{
                                edit: [PermissionsEnum.UpdateCalendarEvent],
                                delete: [PermissionsEnum.DeleteCalendarEvent],
                                view: [PermissionsEnum.ReadCalendarEvent],
                            }}
                        />
                    )}
                />
            </DataGrid>
        </div>
    ) : (
        <CenterLoader />
    );
};

export default JobEvents;
